import {IfLoginInfo} from "models/IfLoginInfo";
import {useSelector} from "react-redux";


export function useLoginInfo(): IfLoginInfo {
  const loginInfo = useSelector((state: any): IfLoginInfo => state.main.loginInfo);
  return loginInfo;
}



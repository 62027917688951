import {resi18nutil} from "language/resi18nutil";


const resi18n_all = {
  I18N_ALL: ["전체", "All", undefined],
  I18N_QNA_Q: ["Q&A", "Q&A", undefined],
  I18N_QNA_A: ["답변", "Answer", undefined],
  I18N_TIP: ["자유", "General", undefined],
  I18N_EVENT: ["이벤트", "Event", undefined],
  I18N_NOTICE: ["공지", "Notice", undefined],
  I18N_JOB_OFFER: ["구인", "Job Offer", undefined],
  I18N_JOB_SEARCH: ["구직", "Job Search", undefined],
  I18N_USER_SAMPLE: ["사용자 샘플", "User Sample", undefined],
  I18N_LS_SAMPLE: ["LS 샘플", "LS Sample ", undefined],
  I18N_TECH_GENERAL: ["일반 포스트", "Post", undefined],
  I18N_MAIN_ALL: ["전체", "All", undefined],
  I18N_MAIN_DOC: ["자료", "Document", undefined],
  I18N_MAIN_QNA: ["Q&A", "Q&A", undefined],
  I18N_MAIN_TECH_BLOG: ["Knowledge Base", "Knowledge Base", undefined],
  I18N_MAIN_INFO_SHARE: ["게시판", "Bulletin Board", undefined],
  I18N_MAIN_SAMPLE_STORE: ["샘플 라이브러리", "Sample Library", undefined],
  I18N_SW_SERVICE: ['SW 솔루션', 'Software Solution', undefined],
  I18N_MAIN_RND: ['알록달록 연구소', "Colorful LS", undefined],
  I18N_TROUBLE_SHOOTING: ['Troubleshooting', 'Troubleshooting', undefined],
  I18N_TALK: ["Colorful Talk", "Colorful Talk", undefined],
  I18N_RND_NOTI: ["공지사항", "Notice", undefined],
  I18N_PARTNER_NOTI: ["Notice", "Notice", undefined],
  I18N_LS_PARTNER: ["LS Partners", "LS Partners", undefined],

  I18N_CMMNTY_TITLE_LIST_EMPTY: [
    `<span class="title">커뮤니티 이용 시 유의 사항</span><br/>
<ol>
<li class="list">1. 개인정보가 검색에 노출될 수 있습니다. 게시물에 개인정보를 입력하지 마세요.</li>
<li class="list">2. 정보통신망법 및 저작권법에 의거하여 게시물이 게시 중단되거나 삭제 될 수 있습니다.</li>
<li class="list">3. 자세한 내용은 <span class="em terms-condition cursor-pointer">이용 약관</span>을 참고하세요.</li>
</ol>
    `,
    `<span class="title">GENERAL NOTICE FOR COMMUNITY</span><br/>
<ol>
<li class="list">1. Your personal information may be exposed through searches. Please do not include personal information in your posts.</li>
<li class="list">2. Posts may be suspended or deleted in accordance with the Information and Communication Network Act and the Copyright Act.</li>
<li class="list">3. SSQ is neither a broker nor a participant in transactions. SSQ does not, and is not intended to, assume any responsibility.</li>
<li class="list">4. Please refer to the <span class="em terms-condition cursor-pointer">Terms and Conditions</span> for further details.</li>
</ol>
    `
    , undefined],
  I18N_YES: ["네", "Yes", undefined],
  I18N_NO: ["아니오", "No", undefined],
  I18N_LS_RELATED_DOC: ["LS ELECTRIC 제품 관련 글인가요?", "Related to LS ELECTRIC Products?", undefined],
  I18N_LS_RELATED_DOC_INFO: ["LS ELECTRIC 제품 옵션일 경우, 제품 및 산업 메뉴에 해당 글이 등록됩니다.",
    "For LSELECTRIC products, the article will be registered in the Products and Industry menu.", undefined],

  I18N_TAG_TAG: ["태그", "Tags", undefined],
  I18N_TAG_BTN_ADDTAG: ["태그추가", "Add a Tag", undefined],
  I18N_TAG_BTN_MGRTAG: ["태그관리", "Tag Setting", undefined],
  I18N_TAG_HOLDER_INPUT_TAG: ["태그 입력", "Input a tag", undefined],
  I18N_TAG_ADD_TAG_MSG: ["추가하실 태그를 입력해 주세요", "Apply tags to your resources to organize them ", undefined],
  I18N_TAG_DEL_CONFIRM: ["삭제 확인", "Modify Tag", undefined],
  I18N_TAG_DEL_CONFIRM_MSG1: ["삭제할 태그를 아래 태그 중 하나와 합치거나,", "You can combine the tag with one of the tags below,", undefined],
  I18N_TAG_DEL_CONFIRM_MSG2: ["삭제하실 수 있습니다.", "or you can delete it.", undefined],
  I18N_TAG_TITLE_TAG: ["태그.", "Tag", undefined],
  I18N_TAG_TITLE_INDUSTRY: ["산업", "Industry", undefined],
  I18N_TAG_TITLE_APP: ["적용 부하", "Application", undefined],
  I18N_TAG_TITLE_PRODUCT: ["제품", "Product", undefined],
  I18N_POP_BTN_CANCEL: ["취소", "Cancel", undefined],
  I18N_POP_BTN_SAVE: ["저장", "Save", undefined],
  I18N_POP_BTN_CONFIRM: ["확인", "Confirm", undefined],
  I18N_POP_BTN_DELETE: ["삭제", "Delete", undefined],
  I18N_DOC_TYPE: ["문서 타입", "Document Type", undefined],

  I18N_DOCWRITE_OVERWRITE_MSG: ["기존 글에 덮어 써집니다. 수정하시겠습니까?", "This will overwrite the existing documnet. Do you want to modify it?", undefined],
  I18N_DOCWRITE_BTN_REGIST: ["새 글 등록", "Register", undefined],
  I18N_DOCWRITE_BTN_REGIST_MOD: ["수정 등록", "Register", undefined],
  I18N_DOCWRITE_BTN_COPY: ["복제", "Copy", undefined],
  I18N_URL_COPIED_TO_CLIPBOARD: ["현재 웹 페이지의 주소(URL)가 클립보드에 복사되었습니다.", "URL copied to clipboard.", undefined],
  I18N_URL_COPIED: ["URL을 복사했습니다.", "Link copied to clipboard.", undefined],
  I18N_FEEDBACK_ALERT: ["피드백이 전송되었습니다.", "Feedback has been sent.", undefined],
  I18N_BROWSER_DOES_NOT_SUPPORT: ["복사 기능이 지원되지 않는 브라우저입니다.", "This browser does not support Copy and Paste.", undefined],

  I18N_FILE_UPLOAD_ERROR: ['파일 업로드에 실패하였습니다.', 'File upload error.', undefined],
  I18N_USERPOPMENU_PROFILE: ['프로필', 'Profile', undefined],
  I18N_USERPOPMENU_MAIL: ['문의', 'E-mail', undefined],

};

export type RES_ALL = keyof typeof resi18n_all;

const [ko, en, enus] = resi18nutil.getres(resi18n_all);
export const resi18n_all_en = en;
export const resi18n_all_ko = ko;
export const resi18n_all_enus = enus;


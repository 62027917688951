export const ACTION_TYPES = {
  MAIN: {
    SVR_CONF: 'SSQ_MAIN_SVR_CONF',
    LOGIN_INFO: "SSQ_MAIN_LOGIN_INFO",
    LOADING: "SSQ_MAIN_LOADING",
    IS_LOGIN_POPUP: "SSQ_MAIN_LIS_LOGIN_POPUP",
    SHOW_LOGIN_POPUP: "SSQ_MAIN_SHOW_LOGIN_POPUP",
    COUNTRY_LANG: "SSQ_MAIN_COUNTRY_LANG",
    DISPLAY_POPUP_ITEM: "SSQ_MAIN_DISPLAY_POPUP_ITEM",
    DISPLAY_BOTTOM_POPUP: "SSQ_MAIN_DISPLAY_BOTTOM_POPUP"
  },
  SVCS: {
    SVCS: 'SSQ_SVCS_SVCS'
  },
  TOAST: {
    SHOW_TYPE: 'SSQ_TOAST_INFO'
  },
  WEATHER: {
    POS: 'SSQ_WEATHER_POS',
    WEATHER: 'SSQ_WEATHER_WEATHER'
  },
  ASSET_TOAST: {
    SHOW: 'SSQ_ASSET_TOAST_SHOW',
    REMOVE: 'SSQ_ASSET_TOAST_REMOVE'
  },
  ASSET: {
    SET_PJT: 'SSQ_ASSET_SET_PJT',
    SET_DEV: 'SSQ_ASSET_SET_DEV',
    LAST_UPDATE: 'SSQ_ASSET_LAST_UPDATE',
    LOADING: 'SSQ_ASSET_LOADING'
    //CONFIRM_POPUP: 'SSQ_ASSET_CONFIRM_POPUP',
  },
  TREND: {
    // SET_CONDITION: 'SSQ_TREND_CONDITION',
    // SET_TYPE: 'SSQ_TREND_TYPE',
    // SET_DATE: 'SSQ_TREND_DATE',
    // SET_CUSTOM_DATE: 'SSQ_TREND_CUSTOM_DATE',
    SET_DOWNLOAD_DATE: 'SSQ_TREND_DOWNLOAD_DATE',
    SET_DOWNLOAD_UNIT: 'SSQ_TREND_DOWNLOAD_UNIT',
    SET_DOWNLOAD_PROJECTS_OR_DEVICES: 'SSQ_TREND_DOWNLOAD_PROJECTS_OR_DEVICES'
  },
  JNVPARAM: {
    SET_PARAM: "JNVPARAM_SET_PARAM",
    SET_TITLES: "JNVPARAM_SET_TITLES",
    SET_PARAM_MAP: "JNVPARAM_SET_PARAM_MAP",
  },
};

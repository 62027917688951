import {ACTION_TYPES} from 'store/ActionTypes';
import {SvrConf} from "models/IfSvrConf";
import {LoginInfo} from "models/IfLoginInfo";
import {IfReduxMainState} from "store/reducers/redux-model";
import {DisplayPopupItem} from "models/IfDisplayPopupItem";


const initState: IfReduxMainState = {
  svrConf: new SvrConf(),
  loginInfo: new LoginInfo(),
  loading: false,
  isLoginPopup: false,
  showLoginPopup: false,
  lang: "",
  country: "",
  displayPopupItem: new DisplayPopupItem(),
  displayBottomPopup: false
};

const reducerMain = (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.MAIN.SVR_CONF:
      return {...state, svrConf: {...action.payload},};
    case ACTION_TYPES.MAIN.LOGIN_INFO:
      return {...state, loginInfo: {...action.payload},};
    case ACTION_TYPES.MAIN.LOADING:
      return {...state, loading: action.payload,};
    case ACTION_TYPES.MAIN.IS_LOGIN_POPUP:
      return {...state, isLoginPopup: action.payload,};
    case ACTION_TYPES.MAIN.SHOW_LOGIN_POPUP:
      return {...state, showLoginPopup: action.payload,};
    case ACTION_TYPES.MAIN.COUNTRY_LANG:
      return {...state, lang: action.payload?.lang, country: action.payload?.country,};
    case ACTION_TYPES.MAIN.DISPLAY_POPUP_ITEM:
      return {...state, displayPopupItem: {...action.payload},};
    case ACTION_TYPES.MAIN.DISPLAY_BOTTOM_POPUP:
      return {...state, displayBottomPopup: action.payload,};
    default:
      return state;
  }
};

export default reducerMain;


import {IfLoginInfo} from "models/IfLoginInfo";
import {LoginType} from "definitions/LoginType";
import {SiteLanguageType} from "definitions/SiteLanguageType";
import {RoleType} from "definitions/RoleType";
import {i18ntrans} from "language/i18nkey";

export class LoginUtil {
  info: IfLoginInfo;

  constructor(info: IfLoginInfo) {
    this.info = info;
  }

  // allowTechDocManage(): boolean {
  //   if (!this.info) return false;
  //   return this.info.allowTechDocManage;
  // }

  allowTopRow1(): boolean {
    if (!this.info) return false;
    const info = this.info;
    if (info.allowAdminKoKr || info.allowAdminEnUs || info.allowAdminEn) {
      return true;
    }
    return false;
  }

  employLogined(): boolean {
    if (!this.info) return false;
    //return this.info.loginType === LoginType.LOGIN_TYPE_WELS;
    return loginutil.islse(this.info);
  }

  generalLogined(): boolean {
    if (!this.info) return false;
    const loginType = this.info.loginType;
    if ([LoginType.LOGIN_TYPE_KAKAO,
      LoginType.LOGIN_TYPE_GOOGLE,
      LoginType.LOGIN_TYPE_SSQ,
      LoginType.LOGIN_TYPE_NAVER].includes(loginType)) return true;
    return false;
  }

  snsLogined(): boolean {
    if (!this.info) return false;
    const loginType = this.info.loginType;
    if ([LoginType.LOGIN_TYPE_KAKAO,
      LoginType.LOGIN_TYPE_GOOGLE,
      LoginType.LOGIN_TYPE_NAVER].includes(loginType)) return true;
    return false;
  }

  logined(): boolean {
    if (!this.info) return false;
    const loginType = this.info.loginType;
    if ([LoginType.LOGIN_TYPE_KAKAO,
      LoginType.LOGIN_TYPE_GOOGLE,
      LoginType.LOGIN_TYPE_SSQ,
      LoginType.LOGIN_TYPE_NAVER,
      LoginType.LOGIN_TYPE_WELS].includes(loginType)) return true;
    return false;
  }
}


export const loginutil = {
  firstrole: (info: IfLoginInfo) => {
    if ((info.authorities || []).length === 0) return 'ROLE_ANONYMOUS';
    return info.authorities[0];
  },
  getrole: (info: IfLoginInfo) => {
    if (!info || !info.userId) return 'ROLE_ANONYMOUS';
    if ((info.authorities || []).length === 0) return 'ROLE_ANONYMOUS';
    if (info.authorities.indexOf("ROLE_ADMIN") >= 0) return 'ROLE_ADMIN';
    if (info.authorities.indexOf("ROLE_MEMBER") >= 0) return 'ROLE_MEMBER';
    return info.authorities[0];
  },
  fetched: (info: IfLoginInfo) => {
    if (info?.apires === 'Y') return true;
    return false;
  },
  adminLangs: (info: IfLoginInfo): string[] => {
    let langs = [];
    if (info?.allowAdminEn) langs.push(SiteLanguageType.EN);
    if (info?.allowAdminKoKr) langs.push(SiteLanguageType.KO_KR);
    if (info?.allowAdminEnUs) langs.push(SiteLanguageType.EN_US);
    return langs;
  },
  iswels: (info: IfLoginInfo): boolean => {
    if (info?.loginType === LoginType.LOGIN_TYPE_WELS) {
      return true;
    }
    return false;
  },
  hasDocViewAuth: (info: IfLoginInfo, displayTarget: number): boolean => {
    if (displayTarget === 0) return true;
    const role = loginutil.getrole(info);
    if ((displayTarget === 10) || (displayTarget === 15)) {
      if (["ROLE_ADMIN", "ROLE_MEMBER"].includes(role)) return true;
    } else if (displayTarget === 20) {
      if (["ROLE_ADMIN"].includes(role)) return true;
    }
    return false;
  },
  alertDocView: (info: IfLoginInfo, displayTarget: number) => {
    if (displayTarget === 0) return;
    const role = loginutil.getrole(info);
    if (displayTarget === 10) {
      if (["ROLE_ADMIN", "ROLE_MEMBER"].includes(role)) {
        return;
      }
      const t = i18ntrans.adminstrict;
      alert(t('I18N_REQUIRE_MEMBER_ERROR_TXT'));
    } else if (displayTarget === 20) {
      if (["ROLE_ADMIN"].includes(role)) {
        return;
      }
      const t = i18ntrans.adminstrict;
      alert(t('I18N_REQUIRE_EMPLOYEE_ERROR_TXT'));
    }
  },
  islogin: (info: IfLoginInfo): boolean => {
    if (!info) return false;
    if (!info.userId) return false;
    return true;
  },
  isnotlogin: (info: IfLoginInfo): boolean => {
    if (!info) return true;
    if (!info.userId) return true;
    return false;
  },
  islse: (info: IfLoginInfo): boolean => {
    /* ROLE_ADMIN
            if (userDetails.getUsername().contains("@ls-electric.com")
            || userDetails.getUsername().contains("@lsis.com")
            || userDetails.getUsername().contains("@lselectric.co.kr")
     */
    if (!info) return false;
    if (!info.userId) return false;
    const role = loginutil.getrole(info);
    if (role === RoleType.ROLE_ADMIN) return true;
    return false;
  },
  isRoleMember: (info: IfLoginInfo): boolean => {
    if (!info) return false;
    if (!info.userId) return false;
    const role = loginutil.getrole(info);
    if (role === RoleType.ROLE_MEMBER) return true;
    return false;
  },
  isadmin: (info: IfLoginInfo): boolean => {
    if (info.allowAdminKoKr ||
      info.allowAdminEnUs ||
      info.allowAdminSw ||
      info.allowAdminEn ||
      info.allowAdminManage) return true;
    return false;
  }
};
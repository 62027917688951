export const SET_EXIST_SSQ_ACCOUNT_NOT_SNS_STORE = "SET_EXIST_SSQ_ACCOUNT_NOT_SNS_STORE";

export const setExistSsqAccountNotSnsStore = (isExistSsqAccountNotSnsStore: boolean) => (
  {type: SET_EXIST_SSQ_ACCOUNT_NOT_SNS_STORE, payload: isExistSsqAccountNotSnsStore}
);

export const initialState = {
  isExistSsqAccountNotSnsStore: false
};

const rootReducer = (state = initialState, action: any) => {
  const {type} = action;

  switch (type) {
    case "SET_EXIST_SSQ_ACCOUNT_NOT_SNS_STORE":
      return {...state, isExistSsqAccountNotSnsStore: action.payload};
    default:
      return state;
  }
};

export default rootReducer;

export const LOGIN_TYPE_SSQ = "SSQ";
export const LOGIN_TYPE_WELS = "WELS";
export const LOGIN_TYPE_GOOGLE = "GOOGLE";
export const LOGIN_TYPE_NAVER = "NAVER";
export const LOGIN_TYPE_KAKAO = "KAKAO";

export const LoginType = Object.freeze({
  LOGIN_TYPE_SSQ: "SSQ",
  LOGIN_TYPE_WELS: "WELS",
  LOGIN_TYPE_GOOGLE: "GOOGLE",
  LOGIN_TYPE_NAVER: "NAVER",
  LOGIN_TYPE_KAKAO: "KAKAO",
});

export const getLoginTypeLangKey = (value: string) => {
  switch (value) {
    case LoginType.LOGIN_TYPE_SSQ:
      return 'I18N_USER_LOGIN_TYPE_SSQ';
    case LoginType.LOGIN_TYPE_WELS:
      return 'I18N_USER_LOGIN_TYPE_WELS';
    case LoginType.LOGIN_TYPE_GOOGLE:
      return 'I18N_USER_LOGIN_TYPE_GOOGLE';
    case LoginType.LOGIN_TYPE_NAVER:
      return 'I18N_USER_LOGIN_TYPE_NAVER';
    case LoginType.LOGIN_TYPE_KAKAO:
      return 'I18N_USER_LOGIN_TYPE_KAKAO';
    default:
      return '';
  }
};

export const DocLanguageType = Object.freeze({
  ALL: "ALL",
  KO: "KO",
  EN: "EN",
  ETC: "ETC",
  NO_ATTACHMENT: "NO_ATTACHMENT"
});

export const getDocLanguageTypeLangKey = (value: string) => {
  switch (value) {
    case DocLanguageType.ALL:
      return 'I18N_FILTER_FILE_LANG_ALL';
    case DocLanguageType.KO:
      return 'I18N_FILTER_KOREAN';
    case DocLanguageType.EN:
      return 'I18N_FILTER_ENGLISH';
    case DocLanguageType.ETC:
      return 'I18N_FILTER_ETC';
    default:
      return '';
  }
};


export const getFileLanguageTypeLangKey = (value: string) => {
  switch (value) {
    case DocLanguageType.ALL:
      return 'I18N_FILTER_FILE_LANG_ALL';
    case DocLanguageType.KO:
      return 'I18N_FILTER_FILE_LANG_KO';
    case DocLanguageType.EN:
      return 'I18N_FILTER_FILE_LANG_EN';
    case DocLanguageType.ETC:
      return 'I18N_FILTER_FILE_LANG_ETC';
    default:
      return '';
  }
};
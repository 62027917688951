import {langutil} from "language/langutil";

export const DocType = Object.freeze({
  MANUAL: "MANUAL",
  CATALOG: "CATALOG",
  CERTIFICATION: "CERTIFICATION",
  CAD_DATA: "CAD_DATA",
  DATASHEET: "DATASHEET",
  SOFTWARE: "SOFTWARE",
  OS: "OS",
  APPLICATION_NOTE: "APPLICATION_NOTE",
  TECHNICAL_GUIDE: "TECHNICAL_GUIDE",
  SAMPLE: "SAMPLE",
  EDUCATION_NOTE: "EDUCATION_NOTE",
  EDUCATION_VIDEO: "EDUCATION_VIDEO",
  TIP: "TIP",
  JOB_OFFER: "JOB_OFFER",
  JOB_SEARCH: "JOB_SEARCH",
  DOC_EVT: "DOC_EVT",
  DOC_NOTI: "DOC_NOTI",
  USER_SAMPLE: "USER_SAMPLE",
  TECH_GENERAL: "TECH_GENERAL",
  QNA_Q: "QNA_Q",
  QNA_A: "QNA_A",
  ALL: 'ALL',
  SW_SERVICE: 'SW_SERVICE',
  TROUBLE_SHOOTING: "TROUBLE_SHOOTING",
  RND_QNA_Q: "RND_QNA_Q",
  RND_QNA_A: "RND_QNA_A",
  RND_TALK: "RND_TALK",
  RND_NOTI: "RND_NOTI",
  PARTNER_NOTI: "PARTNER_NOTI",
  PARTNER_QNA_Q: "PARTNER_QNA_Q",
  PARTNER_QNA_A: "PARTNER_QNA_A",
});

export const myDocFilterForDocType = Object.freeze({
  MANUAL: "MANUAL",
  CATALOG: "CATALOG",
  CERTIFICATION: "CERTIFICATION",
  CAD_DATA: "CAD_DATA",
  DATASHEET: "DATASHEET",
  SOFTWARE: "SOFTWARE",
  OS: "OS",
  APPLICATION_NOTE: "APPLICATION_NOTE",
  TECHNICAL_GUIDE: "TECHNICAL_GUIDE",
  SAMPLE: "SAMPLE",
  EDUCATION_NOTE: "EDUCATION_NOTE",
  EDUCATION_VIDEO: "EDUCATION_VIDEO",
  USER_SAMPLE: "USER_SAMPLE",
  TECH_GENERAL: "TECH_GENERAL",
  QNA_Q: "QNA_Q",
  QNA_A: "QNA_A",
  SW_SERVICE: 'SW_SERVICE',
  BOARD: 'BOARD',
  TROUBLE_SHOOTING: 'TROUBLE_SHOOTING',
  RND_QNA_Q: "RND_QNA_Q",
  RND_QNA_A: "RND_QNA_A",
  RND_TALK: "RND_TALK",
  RND_NOTI: "RND_NOTI",
  PARTNER_NOTI: "PARTNER_NOTI",
  PARTNER_QNA_Q: "PARTNER_QNA_Q",
  PARTNER_QNA_A: "PARTNER_QNA_A",
});


export const partnerFilterDocType = Object.freeze({
  MANUAL: "MANUAL",
  CATALOG: "CATALOG",
  CERTIFICATION: "CERTIFICATION",
  CAD_DATA: "CAD_DATA",
  DATASHEET: "DATASHEET",
  SOFTWARE: "SOFTWARE",
  OS: "OS",
  APPLICATION_NOTE: "APPLICATION_NOTE",
  TECHNICAL_GUIDE: "TECHNICAL_GUIDE",
  SAMPLE: "SAMPLE",
  EDUCATION_NOTE: "EDUCATION_NOTE",
  EDUCATION_VIDEO: "EDUCATION_VIDEO",
  SW_SERVICE: 'SW_SERVICE',
  TROUBLE_SHOOTING: 'TROUBLE_SHOOTING',
});


// export type DOC_TYPE = "MANUAL" |
//   "CATALOG" |
//   "CERTIFICATION" |
//   "CAD_DATA" |
//   "DATASHEET" |
//   "SOFTWARE" |
//   "OS" |
//   "APPLICATION_NOTE" |
//   "TECHNICAL_GUIDE" |
//   "SAMPLE" |
//   "EDUCATION_NOTE" |
//   "EDUCATION_VIDEO" |
//   "TIP" |
//   "JOB_OFFER" |
//   "JOB_SEARCH" |
//   "DOC_EVT" |
//   "DOC_NOTI" |
//   "USER_SAMPLE" |
//   "TECH_GENERAL" |
//   "QNA_Q" |
//   "QNA_A" |
//   "ALL" |
//   "SW_SERVICE" |
//   "TROUBLE_SHOOTING";

export type DOC_TYPE = keyof typeof DocType;

export type INQUIRYCATE_TYPE = "TECH" | "SERVICE" | "SALES" | "ETC"

export const getDocTypeLangKey = (value: string) => {

  if (value === DocType.MANUAL) return 'I18N_TAB_MANUAL';
  if (value === DocType.CATALOG) return 'I18N_TAB_CATALOG';
  if (value === DocType.SOFTWARE) return 'I18N_TAB_SOFTWARE';
  if (value === DocType.CERTIFICATION) return 'I18N_TAB_CERTIFICATION';
  if (value === DocType.CAD_DATA) return 'I18N_TAB_CAD_DATA';
  if (value === DocType.DATASHEET) return 'I18N_TAB_DATASHEET';
  if (value === DocType.OS) return 'I18N_TAB_OS';
  if (value === DocType.APPLICATION_NOTE) return 'I18N_TAB_APPLICATION_NOTE';
  if (value === DocType.TECHNICAL_GUIDE) return 'I18N_TAB_TECHNICAL_GUIDE';
  if (value === DocType.SAMPLE) return 'I18N_TAB_SAMPLE';
  if (value === DocType.EDUCATION_NOTE) return 'I18N_TAB_EDUCATION_NOTE';
  if (value === DocType.EDUCATION_VIDEO) return 'I18N_TAB_EDUCATION_VIDEO';

  if (value === DocType.TIP) return 'I18N_TIP';
  if (value === DocType.JOB_OFFER) return 'I18N_JOB_OFFER';
  if (value === DocType.JOB_SEARCH) return 'I18N_JOB_SEARCH';
  if (value === DocType.DOC_EVT) return 'I18N_EVENT'; // 이벤트. ( 이전 구매 - BUY )
  if (value === DocType.DOC_NOTI) return 'I18N_NOTICE'; // 공지 ( 이전 판매 - SELL )
  if (value === DocType.USER_SAMPLE) return 'I18N_USER_SAMPLE';
  if (value === DocType.TECH_GENERAL) return 'I18N_TECH_GENERAL';
  if (value === DocType.QNA_Q) return 'I18N_QNA_Q';
  if (value === DocType.QNA_A) return 'I18N_QNA_A';
  if (value === DocType.ALL) return 'I18N_ALL';
  if (value === DocType.SW_SERVICE) return "I18N_SW_SERVICE";
  if (value === DocType.TROUBLE_SHOOTING) return "I18N_TROUBLE_SHOOTING";
  if (value === DocType.RND_QNA_Q) return "I18N_QNA_Q";
  if (value === DocType.RND_QNA_A) return "I18N_QNA_A";
  if (value === DocType.RND_TALK) return "I18N_TALK";
  if (value === DocType.RND_NOTI) return "I18N_RND_NOTI";
  if (value === "RND_QNA") return "I18N_QNA_Q";
  if (value === DocType.PARTNER_NOTI) return "I18N_PARTNER_NOTI";
  if (value === DocType.PARTNER_QNA_Q) return "I18N_QNA_Q";
  if (value === DocType.PARTNER_QNA_A) return "I18N_QNA_A";
  return '';

};
export const getDocTypeLangKey2 = (value: string) => {
  if (value === DocType.SAMPLE) return 'I18N_LS_SAMPLE';
  if (['blogall', 'sampleall', 'infoall', 'rndall'].includes(value)) return 'I18N_MAIN_ALL';
  return getDocTypeLangKey(value);
};


export const getDocViewUrl = (siteLang: string, docType: string, docId: number, questionId?: number): string => {
  //const docType: DOC_TYPE = _docType as DOC_TYPE;
  const {country, lang} = langutil.splitLangCode(siteLang);
  if (["SAMPLE", "USER_SAMPLE"].includes(docType)) {
    return `/${country}/${lang}/service/sample/document/${docId}`;
  } else if (["TECHNICAL_GUIDE", "APPLICATION_NOTE", "TECH_GENERAL"].includes(docType)) {
    return `/${country}/${lang}/community/blog/document/${docId}`;
  } else if (["MANUAL", "CATALOG", "CERTIFICATION", "CAD_DATA", "DATASHEET", "SOFTWARE", "OS", "EDUCATION_VIDEO", "EDUCATION_NOTE", "TROUBLE_SHOOTING"].includes(docType)) {
    return `/${country}/${lang}/product/document/${docId}`;
  } else if (["QNA_Q"].includes(docType)) {
    return `/${country}/${lang}/community/qna/document/` + docId;
  } else if (["QNA_A"].includes(docType)) {
    return `/${country}/${lang}/community/qna/document/` + questionId;
  } else if (["SW_SERVICE"].includes(docType)) {
    return `/${country}/${lang}/service/software/document/${docId}`;
  } else if (["DOC_EVT", "DOC_NOTI", "TIP", "JOB_OFFER", "JOB_SEARCH"].includes(docType)) {
    return `/${country}/${lang}/community/board/document/` + docId;
  } else if (["RND_QNA_Q"].includes(docType)) {
    return `/${country}/${lang}/community/rnd/qna/document` + docId;
  } else if (["RND_QNA_A"].includes(docType)) {
    return `/${country}/${lang}/community/rnd/qna/document` + questionId;
  } else if (["RND_TALK"].includes(docType)) {
    return `/${country}/${lang}/community/rnd/talk/document` + docId;
  } else if (["RND_NOTI"].includes(docType)) {
    return `/${country}/${lang}/community/rnd/notice/document` + docId;
  } else if (["PARTNER_NOTI"].includes(docType)) {
    return `/${country}/${lang}/community/partner/notice/document` + docId;
  } else if (["PARTNER_QNA_Q"].includes(docType)) {
    return `/${country}/${lang}/community/partner/qna/document` + docId;
  } else if (["PARTNER_QNA_A"].includes(docType)) {
    return `/${country}/${lang}/community/partner/qna/document` + questionId;
  }
  return `/${country}/${lang}/product/document/${docId}`;
};
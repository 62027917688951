import {useDispatch, useSelector} from "react-redux";
import {ACTION_TYPES} from "store/ActionTypes";


export function useToast(): [string, number, string] {
  const state = useSelector((state: any): any => state.toast);
  return [state.type, state.seq, state.msg];
}


export type FnShowToast = (type: 'unknown' | 'bookmark' | 'bookmark-clear' | 'share' | 'selectorShare'
  | 'selectorAdd' | 'selectorAdd-clear' | 'selectorCompare' | 'msg', msg?: string) => void;

export function useShowToast(): FnShowToast {
  const dispatch = useDispatch();
  const showToast = (type: string, msg?: string) => {
    dispatch({type: ACTION_TYPES.TOAST.SHOW_TYPE, payload: {type, msg}});
  };
  return showToast;
}
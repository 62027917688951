import {ACTION_TYPES} from 'store/ActionTypes';


const initState = {
  type: "",
  seq: 1,
  msg: "",
};

const reducerToast = (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.TOAST.SHOW_TYPE:
      return {seq: state.seq + 1, ...action.payload};
    default:
      return state;
  }
};

export default reducerToast;

// @flow
import * as React from 'react';
import ToastPopup from "productpage/ToastPopup";
import svcs from "services";
import {useLoginInfo} from "hooks/useLoginInfo";
import {useToast} from "hooks/useToast";

type Props = {};
export const ToastWrapper = (props: Props) => {
  const loginInfo = useLoginInfo();
  const [type, seq, msg] = useToast();
  return (
    <ToastPopup type={type} languageService={svcs.svcLang} loginInfo={loginInfo} seq={seq} msg={msg}/>
  );
};
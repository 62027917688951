export const CategoryType = Object.freeze({
  PRODUCT: "PRODUCT",
  INDUSTRY: "INDUSTRY",
  APPLICATION_LOAD: "APPLICATION_LOAD",
  COMMUNITY: "COMMUNITY",
  SERVICE: "SERVICE"
});

export const getCategoryTypeLangKey = (value: string) => {
  switch (value) {
    case CategoryType.PRODUCT:
      return 'I18N_TAB_PRODUCT';
    case CategoryType.INDUSTRY:
      return 'I18N_TAB_INDUSTRY';
    case CategoryType.APPLICATION_LOAD:
      return 'I18N_TAB_APPLICATION_LOAD';
    case CategoryType.COMMUNITY:
      return 'I18N_TAB_COMMUNITY';
    case CategoryType.SERVICE:
      return 'I18N_TAB_SERVICE';
    default:
      return '';
  }
};

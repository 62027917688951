import {Http} from "../../fetch/Http";


export interface ICategory {
  id: number;
  lang: string;
  parent: number | null;
  level: number;
  sequence: number;
  imageBlobKey: string;
  name: string;
  cardDescription: string;
  description: string;
  relatedModels: string | null;
  typeName: string;
  homepageUrl: string;

  discontinue: boolean;
}


export const emptyCategory: ICategory = {
  id: 0,
  lang: "",
  parent: null,
  level: 0,
  sequence: 0,
  imageBlobKey: "",
  name: "",
  cardDescription: "",
  description: "",
  relatedModels: "",
  typeName: "",
  homepageUrl: "",
  discontinue: false,
};

export interface ICategoryDetail {
  id: number;
  lang: string;
  expose: boolean;
  parent: number | null;
  level: number;
  sequence: number;
  imageBlobKey: string;
  name: string;
  cardDescription: string;
  description: string;
  relatedModels: string;
  homepageUrl: string;

  discontinue: boolean;
}

export interface IPostNewCategoryRequest {
  expose: boolean;
  lang: string;
  parent: number | null;
  level: number;
  sequence: number;
  imageBlobKey: string;
  name: string;
  cardDescription: string;
  description: string;
  relatedModels: string;
  homepageUrl: string;
  typeName: string;
  discontinue: boolean;
}

export interface IPostNewCategoryResponse {
  categoryId: number;
}

export const emptyPostNewCategoryResponse: IPostNewCategoryResponse = {
  categoryId: 0
};

export const emptyCategoryDetail: ICategoryDetail = {
  id: 0,
  lang: "",
  expose: false,
  parent: 0,
  level: 0,
  sequence: 0,
  imageBlobKey: "",
  name: "",
  cardDescription: "",
  description: "",
  relatedModels: "",
  homepageUrl: "",
  discontinue: false,
};

export const emptyPostNewCategoryRequest: IPostNewCategoryRequest = {
  lang: "",
  expose: true,
  parent: 0,
  level: 0,
  sequence: 0,
  imageBlobKey: "",
  name: "",
  cardDescription: "",
  description: "",
  relatedModels: "",
  homepageUrl: "",
  typeName: "",
  discontinue: false,
};

export interface IUpdateCategoryRequest {
  categoryId: number;
  lang: string;
  expose: boolean;
  parent: number | null;
  level: number;
  imageBlobKey: string;
  name: string;
  cardDescription: string;
  description: string;
  relatedModels: string;
  homepageUrl: string;
  typeName: string;
  discontinue: boolean;
}

export const emptyUpdateCategoryRequest: IUpdateCategoryRequest = {
  categoryId: 0,
  lang: "",
  expose: true,
  parent: 0,
  level: 0,
  imageBlobKey: "",
  name: "",
  cardDescription: "",
  description: "",
  relatedModels: "",
  homepageUrl: "",
  typeName: "",
  discontinue: false,
};

export interface ISuggestSearchResultItem {
  suggestName: string;
}

export interface IAutoCompleteCategory {
  categoryId: number;
  categoryName: string;
  categoryParents: string;
}

export interface IAutoCompleteResult {
  suggests: ISuggestSearchResultItem[];
  categories: IAutoCompleteCategory[];
  models: IAutoCompleteCategory[];
}

export interface ITreeNode {
  key: string;
  title: any;
  expose?: boolean;
  children?: ITreeNode[];
}

export interface ISearchedCategory {
  id: number | string
  highlightedResultElement: any,
}

export interface ISearchedSuggest {
  suggestName: string,
  highlightedResultElement: any,
}

export interface AttachedFileBlob {
  fileName: string;
  fileKey: string;
  blobUrlForLargeFile?: string;
}

export interface ICategorySimpleItem {
  id: number;
  parent: number;
  level: number;
  sequence: number;
  name: string;
}

export interface ICategoryMap {
  [key: number]: ICategorySimpleItem;
}

export interface ICategoryMapResponse {
  categoryMap: ICategoryMap;
}

export interface CategoryRepository {
  getExposedCategoryMap(language: string): Promise<ICategoryMapResponse>;

  getExposedCategories(language: string): Promise<ICategory[]>;

  getProductCategoryTreeData(language: string, typeName: string): Promise<ITreeNode[]>;

  getProductCategoryDetail(categoryId: number): Promise<ICategoryDetail>;

  postProductCategoryDetail(postNewCategoryRequest: IPostNewCategoryRequest): Promise<IPostNewCategoryResponse>;

  updateProductCategoryDetail(updateCategoryRequest: IUpdateCategoryRequest): Promise<number>;

  deleteProductCategoryDetail(categoryId: number): Promise<number>;

  postAttachedFiles(key: string, files: any[]): Promise<AttachedFileBlob[]>;

  postAttachedLocalUpload(key: string, files: any[]): Promise<AttachedFileBlob[]>;

  deleteAttachedFile(key: string): Promise<number>;

  getSuggestSearch(searchKeyword: string, lang: string): Promise<ISuggestSearchResultItem[]>;

  getCategoryAutoComplete(searchKeyword: string, lang: string): Promise<IAutoCompleteCategory[]>;

  getModelAutoComplete(searchKeyword: string, lang: string): Promise<IAutoCompleteCategory[]>;

  changeCategoryOrdering(categoryId: number, direction: string): Promise<number>;

  //-------- solution api > 추후 category와 통합 리팩토링 필요
  getExposedCategoryMapByType(language: string, categoryType: string): Promise<ICategoryMapResponse>;

  getExposedCategoriesByType(language: string, categoryType: string): Promise<ICategory[]>;

  //--------
}

export class NetworkCategoryRepository implements CategoryRepository {
  http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  async getExposedCategoryMap(language: string): Promise<ICategoryMapResponse> {
    const response = await this.http.get(`/api/guest/ssqData/category/products/map?lang=${language}`);
    return response.json();
  }

  async getExposedCategories(language: string): Promise<ICategory[]> {
    const response = await this.http.get(`/api/guest/ssqData/category/products/cards?lang=${language}`);
    return response.json();
  }

  async getProductCategoryTreeData(language: string, typeName: string): Promise<ITreeNode[]> {
    const response = await this.http.get(`/api/boris/ssqData/category/products/tree?lang=${language}&typeName=${typeName}`);
    return response.json();
  }

  async getProductCategoryDetail(categoryId: number): Promise<ICategoryDetail> {
    const response = await this.http.get(`/api/guest/ssqData/category/products/${categoryId}`);
    return response.json();
  }

  async postProductCategoryDetail(postNewCategoryRequest: IPostNewCategoryRequest): Promise<IPostNewCategoryResponse> {
    const response = await this.http.post(`/api/boris/ssqData/category/products`, postNewCategoryRequest);
    return response.json();
  }

  async updateProductCategoryDetail(updateCategoryRequest: IUpdateCategoryRequest): Promise<number> {
    const response = await this.http.put(`/api/boris/ssqData/category/products`, updateCategoryRequest);
    return response.status;
  }

  async deleteProductCategoryDetail(categoryId: number): Promise<number> {
    const response = await this.http.delete(`/api/boris/ssqData/category/products/${categoryId}`);
    return response.status;
  }

  async postAttachedFiles(key: string, files: any[]): Promise<AttachedFileBlob[]> {
    const formData = new FormData();
    files.forEach(file => formData.append("uploadFiles", file));

    return (await this.http.postFormData("/api/member/uploads/" + key, formData)).json();
  }

  async deleteAttachedFile(key: string): Promise<number> {
    return (await this.http.delete("/api/boris/uploads/" + key)).status;
  }

  async postAttachedLocalUpload(key: string, files: any[]): Promise<AttachedFileBlob[]> {
    const formData = new FormData();
    files.forEach(file => formData.append("uploadFiles", file));

    return (await this.http.postFormData("/api/member/localupload/" + key, formData)).json();
  }

  async getSuggestSearch(searchKeyword: string, lang: string): Promise<ISuggestSearchResultItem[]> {
    const response = await this.http.get(
      `/api/guest/search/suggest?searchKeyword=${searchKeyword}&suggestType=suggest&lang=${lang}`
    );
    return response.json();
  }

  async getCategoryAutoComplete(searchKeyword: string, lang: string): Promise<IAutoCompleteCategory[]> {
    const response = await this.http.get(
      `/api/guest/category/autocomplete?searchKeyword=${searchKeyword}&autocomType=category&lang=${lang}`
    );
    return response.json();
  }

  async getModelAutoComplete(searchKeyword: string, lang: string): Promise<IAutoCompleteCategory[]> {
    const response = await this.http.get(
      `/api/guest/category/autocomplete?searchKeyword=${searchKeyword}&autocomType=model&lang=${lang}`
    );
    return response.json();
  }

  async changeCategoryOrdering(categoryId: number, direction: string): Promise<number> {
    const response = await this.http.patch(
      `/api/boris/ssqData/category/changeOrdering?categoryId=${categoryId}&direction=${direction}`
    );
    return response.status;
  }

  //-------- solution api > 추후 category와 통합 리팩토링 필요
  async getExposedCategoryMapByType(language: string, categoryType: string): Promise<ICategoryMapResponse> {
    const response = await this.http.get(`/api/guest/ssqData/category/solution/map?lang=${language}&categoryType=${categoryType}`);
    return response.json();
  }

  async getExposedCategoriesByType(language: string, categoryType: string): Promise<ICategory[]> {
    const response = await this.http.get(`/api/guest/ssqData/category/solution/cards?lang=${language}&categoryType=${categoryType}`);
    return response.json();
  }

  //--------
}
// @flow
import * as React from 'react';
import {Component} from 'react';
import {Modal} from "ctls/modal/Modal";
import {LoadingArea} from "style/CommonStyledComponents";
import {SsqImages} from "images/SsqImages";

type Props = {
  visible: boolean,
};

type State = {}

/**
 * 신고하기 팝업.
 */
export class LoadingLayer extends Component<Props, State> {


  render() {
    return (<>
        {this.props.visible ? (<Modal bg={'rgba(0,0,0,0.1)'}>
          <LoadingArea> <img src={SsqImages.common.loading()}/> </LoadingArea>
        </Modal>) : null}
      </>
    );
  };
}

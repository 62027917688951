export const InterestFieldType = Object.freeze({
  SELECT: "SELECT",
  RECHARGEABLE_BATTERY: "RECHARGEABLE_BATTERY",
  PROCESSING_MACHINE: "PROCESSING_MACHINE",
  CONSTRUCTION: "CONSTRUCTION",
  RUBBER: "RUBBER",
  AIRPORT_AIR: "AIRPORT_AIR",
  METAL: "METAL",
  DISPLAY: "DISPLAY",
  ROBOT: "ROBOT",
  MOBILE: "MOBILE",
  CARPENTRY: "CARPENTRY",
  SEMICONDUCTOR: "SEMICONDUCTOR",
  DEVELOPMENT: "DEVELOPMENT",
  FIBER: "FIBER",
  WATER_TREATMENT: "WATER_TREATMENT",
  SMART_FACTORY: "SMART_FACTORY",
  FOOD: "FOOD",
  ELEVATOR: "ELEVATOR",
  GLASS_CEMENT: "GLASS_CEMENT",
  MEDICAL_TREATMENT: "MEDICAL_TREATMENT",
  CAR: "CAR",
  ELECTRIC: "ELECTRIC",
  RESTRAINT: "RESTRAINT",
  STEEL: "STEEL",
  CONVEYOR: "CONVEYOR",
  PACKING_MACHINE: "PACKING_MACHINE",
  PORT_SHIPBUILDING: "PORT_SHIPBUILDING",
  CHEMISTRY: "CHEMISTRY",
  ENVIRONMENT: "ENVIRONMENT",
  ETC: "ETC"
});

export const getInterestFieldTypeLangKey = (value: string) => {
  switch (value) {
    case InterestFieldType.SELECT:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_SELECT';
    case InterestFieldType.RECHARGEABLE_BATTERY:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_RECHARGEABLE_BATTERY';
    case InterestFieldType.PROCESSING_MACHINE:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_PROCESSING_MACHINE';
    case InterestFieldType.CONSTRUCTION:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_CONSTRUCTION';
    case InterestFieldType.RUBBER:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_RUBBER';
    case InterestFieldType.AIRPORT_AIR:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_AIRPORT_AIR';
    case InterestFieldType.METAL:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_METAL';
    case InterestFieldType.DISPLAY:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_DISPLAY';
    case InterestFieldType.ROBOT:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_ROBOT';
    case InterestFieldType.MOBILE:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_MOBILE';
    case InterestFieldType.CARPENTRY:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_CARPENTRY';
    case InterestFieldType.SEMICONDUCTOR:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_SEMICONDUCTOR';
    case InterestFieldType.DEVELOPMENT:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_DEVELOPMENT';
    case InterestFieldType.FIBER:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_FIBER';
    case InterestFieldType.WATER_TREATMENT:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_WATER_TREATMENT';
    case InterestFieldType.SMART_FACTORY:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_SMART_FACTORY';
    case InterestFieldType.FOOD:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_FOOD';
    case InterestFieldType.ELEVATOR:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_ELEVATOR';
    case InterestFieldType.GLASS_CEMENT:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_GLASS_CEMENT';
    case InterestFieldType.MEDICAL_TREATMENT:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_MEDICAL_TREATMENT';
    case InterestFieldType.CAR:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_CAR';
    case InterestFieldType.ELECTRIC:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_ELECTRIC';
    case InterestFieldType.RESTRAINT:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_RESTRAINT';
    case InterestFieldType.STEEL:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_STEEL';
    case InterestFieldType.CONVEYOR:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_CONVEYOR';
    case InterestFieldType.PACKING_MACHINE:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_PACKING_MACHINE';
    case InterestFieldType.PORT_SHIPBUILDING:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_PORT_SHIPBUILDING';
    case InterestFieldType.CHEMISTRY:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_CHEMISTRY';
    case InterestFieldType.ENVIRONMENT:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_ENVIRONMENT';
    case InterestFieldType.ETC:
      return 'I18N_SIGNUP_INTEREST_FIELD_TYPE_ETC';
    default:
      return '';
  }
};

export const getInterestFieldTypeLangKeyInUserManagement = (value: string) => {
  switch (value) {
    case InterestFieldType.SELECT:
      return 'I18N_USER_INTEREST_FIELD_TYPE_SELECT';
    case InterestFieldType.RECHARGEABLE_BATTERY:
      return 'I18N_USER_INTEREST_FIELD_TYPE_RECHARGEABLE_BATTERY';
    case InterestFieldType.PROCESSING_MACHINE:
      return 'I18N_USER_INTEREST_FIELD_TYPE_PROCESSING_MACHINE';
    case InterestFieldType.CONSTRUCTION:
      return 'I18N_USER_INTEREST_FIELD_TYPE_CONSTRUCTION';
    case InterestFieldType.RUBBER:
      return 'I18N_USER_INTEREST_FIELD_TYPE_RUBBER';
    case InterestFieldType.AIRPORT_AIR:
      return 'I18N_USER_INTEREST_FIELD_TYPE_AIRPORT_AIR';
    case InterestFieldType.METAL:
      return 'I18N_USER_INTEREST_FIELD_TYPE_METAL';
    case InterestFieldType.DISPLAY:
      return 'I18N_USER_INTEREST_FIELD_TYPE_DISPLAY';
    case InterestFieldType.ROBOT:
      return 'I18N_USER_INTEREST_FIELD_TYPE_ROBOT';
    case InterestFieldType.MOBILE:
      return 'I18N_USER_INTEREST_FIELD_TYPE_MOBILE';
    case InterestFieldType.CARPENTRY:
      return 'I18N_USER_INTEREST_FIELD_TYPE_CARPENTRY';
    case InterestFieldType.SEMICONDUCTOR:
      return 'I18N_USER_INTEREST_FIELD_TYPE_SEMICONDUCTOR';
    case InterestFieldType.DEVELOPMENT:
      return 'I18N_USER_INTEREST_FIELD_TYPE_DEVELOPMENT';
    case InterestFieldType.FIBER:
      return 'I18N_USER_INTEREST_FIELD_TYPE_FIBER';
    case InterestFieldType.WATER_TREATMENT:
      return 'I18N_USER_INTEREST_FIELD_TYPE_WATER_TREATMENT';
    case InterestFieldType.SMART_FACTORY:
      return 'I18N_USER_INTEREST_FIELD_TYPE_SMART_FACTORY';
    case InterestFieldType.FOOD:
      return 'I18N_USER_INTEREST_FIELD_TYPE_FOOD';
    case InterestFieldType.ELEVATOR:
      return 'I18N_USER_INTEREST_FIELD_TYPE_ELEVATOR';
    case InterestFieldType.GLASS_CEMENT:
      return 'I18N_USER_INTEREST_FIELD_TYPE_GLASS_CEMENT';
    case InterestFieldType.MEDICAL_TREATMENT:
      return 'I18N_USER_INTEREST_FIELD_TYPE_MEDICAL_TREATMENT';
    case InterestFieldType.CAR:
      return 'I18N_USER_INTEREST_FIELD_TYPE_CAR';
    case InterestFieldType.ELECTRIC:
      return 'I18N_USER_INTEREST_FIELD_TYPE_ELECTRIC';
    case InterestFieldType.RESTRAINT:
      return 'I18N_USER_INTEREST_FIELD_TYPE_RESTRAINT';
    case InterestFieldType.STEEL:
      return 'I18N_USER_INTEREST_FIELD_TYPE_STEEL';
    case InterestFieldType.CONVEYOR:
      return 'I18N_USER_INTEREST_FIELD_TYPE_CONVEYOR';
    case InterestFieldType.PACKING_MACHINE:
      return 'I18N_USER_INTEREST_FIELD_TYPE_PACKING_MACHINE';
    case InterestFieldType.PORT_SHIPBUILDING:
      return 'I18N_USER_INTEREST_FIELD_TYPE_PORT_SHIPBUILDING';
    case InterestFieldType.CHEMISTRY:
      return 'I18N_USER_INTEREST_FIELD_TYPE_CHEMISTRY';
    case InterestFieldType.ENVIRONMENT:
      return 'I18N_USER_INTEREST_FIELD_TYPE_ENVIRONMENT';
    case InterestFieldType.ETC:
      return 'I18N_USER_INTEREST_FIELD_TYPE_ETC';
    default:
      return '';
  }
};

let promises = {};

/**
 * start, end, API중복 호출을 방지하기 위한 api utility.
 */
export const apiutil = {
  start: (id: string): Promise<null> => {
    if (!promises[id]) {
      promises[id] = [];
      return Promise.resolve(null);
    }
    return new Promise((resolve) => {
      promises[id].push(resolve);
    });
  },
  end: (id: string) => {
    if (!promises[id]) return;
    promises[id].forEach(resolve => resolve(null));
    delete promises[id];
  }
};
import {NetworkFetchWrapper} from "fetch/FetchWrapper";
import {NetworkHttp} from "fetch/Http";
import {SystemLanguageService} from "language/LanguageService";
import {NetworkCategoryRepository} from "repositories/category/CategoryRepository";
import {NetworkSsqDataRepository} from "repositories/SsqDataRepository";
import {NetworkAccountService} from "repositories/AccountService";
import {NetworkSearchRepository2} from "repositories/SearchRepository2";
import CategoryStore from "repositories/category/CategoryStore";
import {NetworkLoginService} from "login/LoginService";
import {BrowserLocalStorageWrapper} from "util/LocalStorageWrapper";
import {NetworkNotificationService} from "repositories/NotificationService";
import {LANG_EN_US} from "language/langutil";


const redirectHandler = () => {

};

class Svcs {
  fetchWrapper: NetworkFetchWrapper;
  http: NetworkHttp;
  svcLang: SystemLanguageService;

  repoCategory: NetworkCategoryRepository;
  repoSsqData: NetworkSsqDataRepository;
  repoAccount: NetworkAccountService;

  repoSrch2: NetworkSearchRepository2;

  storeCategory: CategoryStore;

  networkFetchWrapper: NetworkFetchWrapper;
  networkHttp: NetworkHttp;
  svcLogin: NetworkLoginService;
  localStorage: BrowserLocalStorageWrapper;
  svcNotification: NetworkNotificationService;

}

const svcs: Svcs = new Svcs();

svcs.fetchWrapper = new NetworkFetchWrapper();
svcs.http = new NetworkHttp(svcs.fetchWrapper, redirectHandler);

svcs.svcLang = new SystemLanguageService();
svcs.repoCategory = new NetworkCategoryRepository(svcs.http);
svcs.repoSsqData = new NetworkSsqDataRepository(svcs.http);
svcs.repoAccount = new NetworkAccountService(svcs.http);
svcs.repoSrch2 = new NetworkSearchRepository2(svcs.http);

svcs.storeCategory = new CategoryStore();

svcs.networkFetchWrapper = new NetworkFetchWrapper();
svcs.networkHttp = new NetworkHttp(svcs.networkFetchWrapper, redirectHandler);
svcs.svcLogin = new NetworkLoginService(svcs.networkHttp);
svcs.localStorage = new BrowserLocalStorageWrapper();
svcs.svcNotification = new NetworkNotificationService(svcs.networkHttp);


export const isLangEnUs = () => {
  const lang = svcs.svcLang.getLanguage();
  if (lang === LANG_EN_US) return true;
  return false;
}
export default svcs;
export const isExistSessionLanguage = (key: string, lang: string): boolean => {
  let displaySession;
  try {
    const sessionData = sessionStorage.getItem(key);
    displaySession = sessionData ? JSON.parse(sessionData) : [];
  } catch (e) {
    return false;
  }

  if (!displaySession.includes(lang)) return false;
  return true;
};
export const appendSessionLanguage = (key: string, lang: string) => {
  let displaySession;
  try {
    const sessionData = sessionStorage.getItem(key);
    displaySession = sessionData ? JSON.parse(sessionData) : [];
  } catch (e) {
    displaySession = [];
  }

  if (!displaySession.includes(lang)) {
    displaySession.push(lang);
    sessionStorage.setItem(key, JSON.stringify(displaySession));
  }
};

export const removePopupSession = () => {
  sessionStorage.removeItem('DISPLAY_BOTTOM_SESSION');
  sessionStorage.removeItem('DISPLAY_CENTER_SESSION');
  sessionStorage.removeItem('DISPLAY_ASSET_SESSION');

};
import React, {Component} from 'react';

class LoginCallbackPage extends Component {

  componentDidMount() {
    window.close();
  }

  render() {
    return (
      <div>
        Closing....
      </div>
    );
  }
}

export default LoginCallbackPage;

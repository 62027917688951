export type ALARM_CLSS = 'DEV' | 'SYS'; // DEV : device alarm, SYS : system알람.

export type DEV_STAT = "DISCON" | "FAULT" | "WARN" | "NORMAL";


export enum STATCD_COM {
  CONNECTED = 101,
  DISCONNECTED = 102
}

export enum STATCD_DEV {
  NORMAL = 201,
  WARN = 202,
  FAULT = 203
}

export enum STATCD_SPEED {
  Speed_Searching = 301,
  Accelerating = 302,
  Operating_at_constant_rate = 303,
  Decelerating = 304,
  Decelerating_to_stop = 305,
  HW_OCS = 306,
  SW_OCS = 307,
  Dwell_operating = 308,
}

export enum STACD_DIR {
  Stopped = 401,
  Operating_in_forward_direction = 402,
  Operating_in_reverse_direction = 403,
  DC_operating = 404

}

export enum STATCD_CONTROL {
  SMART_OPER = 501,
  통신 = 502,
  내장485 = 503,
  IO = 504
}


export const getDevStatStr = (stat: DEV_STAT) => {
  if (stat === 'DISCON') return 'Disconnected';
  if (stat === 'NORMAL') return 'Normal';
  if (stat === 'FAULT') return 'Fault';
  if (stat === 'WARN') return 'Warnning';
  return "Unknown(" + stat + ")";
};

export const getDevStatClass = (stat: DEV_STAT) => {
  if (stat === 'DISCON') return 'disconnected';
  if (stat === 'NORMAL') return 'normal';
  if (stat === 'FAULT') return 'fault';
  if (stat === 'WARN') return 'warning';
  return "fault";
};

import 'react-app-polyfill/ie11';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import {Provider} from "react-redux";
import axios from "axios";
import {gconf} from "conf/gconf";
import {cmder} from "conf/cmder";
import {configureStore} from '@reduxjs/toolkit';
import reducers from "store/reducers";
import {setstore} from "store/ssqstore";
import {initsvrconf} from "conf/svrconf";
import svcs from "services";
import "index.css";
import {ssqutil} from "util/ssqutil";

import "util/axiosutil";

// const enhancer =
//     process.env.NODE_ENV === "production"
//         ? compose(applyMiddleware())
//         : composeWithDevTools(applyMiddleware(logger));

// const store = createStore(rootReducer, enhancer)
//const store = createStore(rootReducer, composeWithDevTools());

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
  devTools: !ssqutil.isProduction()
});

setstore(store);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App
        languageService={svcs.svcLang}
        categoryRepository={svcs.repoCategory}
        categoryStore={svcs.storeCategory}
        ssqDataRepository={svcs.repoSsqData}
        accountService={svcs.repoAccount}
        searchRepository2={svcs.repoSrch2}
        loginService={svcs.svcLogin}
        localStorageWrapper={svcs.localStorage}
        notificationService={svcs.svcNotification}
      />
    </React.StrictMode>
  </Provider>,
  document.getElementById('app')
);

(window as any).axios = axios;
(window as any).gconf = gconf;
(window as any).cmder = cmder;
(window as any).svcs = svcs;

initsvrconf();

export default App;
import svcs from "services";
import {RES_COMMON} from "language/resi18n_common";
import {RES_ADMIN} from "language/resi18n_admin_page";
import {RES_HEADER} from "language/resi18n_header";
import {RES_ASSET} from "language/resi18n_asset";


/**
 * i18n 자동완성을 위한, 키파일.
 * 네임스페이스와, 각 네임스페이스별로 필요한 키를 정의한다.
 * 일괄 적용하지 않으며, 필요에 따라 추가하는 것으로 한다.
 * 각 키는 간락하게, PREFIX를 빼고 정의한다.
 * I18N_의 PREFIX 는, 생량해도 알수 있으므로 생략한다.
 * 실제 사용하는 키와 달리 이름이기 때문에 축약하여 사용해도 무방하다.
 * 소문자를 사용한다.
 */
export const i18ns = {
  header: 'I18N_NAMESPACE_HEADER',
  footer: 'I18N_NAMESPACE_FOOTER',
  main: 'I18N_NAMESPACE_MAIN_PAGE',
  prod: 'I18N_NAMESPACE_PRODUCT_PAGE',
  solution: 'I18N_NAMESPACE_SOLUTION_PAGE',
  cmmnty: 'I18N_NAMESPACE_COMMUNITY_PAGE',
  svc: 'I18N_NAMESPACE_SERVICE_PAGE',
  admin: 'I18N_NAMESPACE_ADMIN_PAGE',
  srch: 'I18N_NAMESPACE_ALL_SEARCH_PAGE',
  mypage: 'I18N_NAMESPACE_MY_PAGE',
  common: 'I18N_NAMESPACE_COMMON',
  asset: 'I18N_NAMESPACE_ASSET',
};
export const i18ntrans = {
  admin(key: RES_ADMIN | string, p?): string {
    svcs.svcLang.setNamespace(i18ns.admin);
    return svcs.svcLang.getTranslationByKey(key, p) || "";
  },
  adminstrict(key: RES_ADMIN, p?): string {
    svcs.svcLang.setNamespace(i18ns.admin);
    return svcs.svcLang.getTranslationByKey(key, p) || "";
  },
  community(key: string, p?): string {
    svcs.svcLang.setNamespace(i18ns.cmmnty);
    return svcs.svcLang.getTranslationByKey(key, p) || "";
  },
  main(key: string, p?): string {
    svcs.svcLang.setNamespace(i18ns.main);
    return svcs.svcLang.getTranslationByKey(key, p) || "";
  },
  header(key: RES_HEADER, p?): string {
    svcs.svcLang.setNamespace(i18ns.header);
    return svcs.svcLang.getTranslationByKey(key, p) || "";
  },
  footer(key: string, p?): string {
    svcs.svcLang.setNamespace(i18ns.footer);
    return svcs.svcLang.getTranslationByKey(key, p) || "";
  },
  prod(key: string, p?): string {
    svcs.svcLang.setNamespace(i18ns.prod);
    return svcs.svcLang.getTranslationByKey(key, p) || "";
  },
  solution(key: string, p?): string {
    svcs.svcLang.setNamespace(i18ns.solution);
    return svcs.svcLang.getTranslationByKey(key, p) || "";
  },
  service(key: string, p?): string {
    svcs.svcLang.setNamespace(i18ns.svc);
    return svcs.svcLang.getTranslationByKey(key, p) || "";
  },
  srch(key: string, p?): string {
    svcs.svcLang.setNamespace(i18ns.srch);
    return svcs.svcLang.getTranslationByKey(key, p) || "";
  },
  mypage(key: string, p?): string {
    svcs.svcLang.setNamespace(i18ns.mypage);
    return svcs.svcLang.getTranslationByKey(key, p) || "";
  },
  common(key: RES_COMMON, p?): string {
    svcs.svcLang.setNamespace(i18ns.common);
    return svcs.svcLang.getTranslationByKey(key, p) || "";
  },
  asset(key: string, p?): string {
    svcs.svcLang.setNamespace(i18ns.asset);
    return svcs.svcLang.getTranslationByKey(key, p) || "";
  },
  tasset(key: RES_ASSET, p?): string {
    svcs.svcLang.setNamespace(i18ns.asset);
    return svcs.svcLang.getTranslationByKey(key, p) || "";
  },
};

//export const tcommon = i18ntrans.common;
export const tcommona = (key: RES_COMMON, a?: string[], b?: string[]) => {
  if (!a) a = [];
  if (!b) b = [];
  return a.join("") + i18ntrans.common(key) + b.join("");
};

export const helmetFormat = (a: string[], key: RES_COMMON, b?: string[]) => {
  if (!a) a = [];
  if (!b) b = [];
  return a.join("") + i18ntrans.common(key) + b.join("");
};
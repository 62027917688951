export const TargetType = Object.freeze({
  GUEST: 0,
  LOGIN: 10,
  PARTNER: 15,
  EMPLOYEE: 20
});

export const getTargetTypeLangKey = (value: number) => {
  switch (value) {
    case TargetType.EMPLOYEE:
      return 'I18N_FILTER_EMPLOYEE';
    case TargetType.LOGIN:
      return 'I18N_FILTER_LOGIN';
    case TargetType.PARTNER:
      return 'I18N_FILTER_PARTNER';
    case TargetType.GUEST:
      return 'I18N_FILTER_GUEST';
    default:
      return '';
  }
};

import {resi18n_admin_page_en, resi18n_admin_page_enus, resi18n_admin_page_ko} from "language/resi18n_admin_page";
import {resi18n_header_en, resi18n_header_enus, resi18n_header_ko} from "language/resi18n_header";
import {resi18n_footer_en, resi18n_footer_enus, resi18n_footer_ko} from "language/resi18n_footer";
import {resi18n_main_page_en, resi18n_main_page_enus, resi18n_main_page_ko} from "language/resi18n_main_page";
import {resi18n_product_page_en, resi18n_product_page_enus, resi18n_product_page_ko} from "language/resi18n_product_page";
import {resi18n_solution_page_en, resi18n_solution_page_enus, resi18n_solution_page_ko} from "language/resi18n_solution_page";
import {resi18n_community_page_en, resi18n_community_page_enus, resi18n_community_page_ko} from "language/resi18n_community_page";
import {resi18n_service_page_en, resi18n_service_page_enus, resi18n_service_page_ko} from "language/resi18n_service_page";
import {resi18n_all_search_page_en, resi18n_all_search_page_enus, resi18n_all_search_page_ko} from "language/resi18n_all_search_page";
import {resi18n_my_page_en, resi18n_my_page_enus, resi18n_my_page_ko} from "language/resi18n_my_page";
import {LANG_EN, LANG_EN_US, LANG_KO, LANG_KO_KR} from "language/langutil";
import {resi18n_common_en, resi18n_common_enus, resi18n_common_ko} from "language/resi18n_common";
import {resi18n_asset_en, resi18n_asset_enus, resi18n_asset_ko} from "language/resi18n_asset";


export const resources = {
  en: {
    I18N_NAMESPACE_ADMIN_PAGE: resi18n_admin_page_en,
    I18N_NAMESPACE_HEADER: resi18n_header_en,
    I18N_NAMESPACE_FOOTER: resi18n_footer_en,
    I18N_NAMESPACE_MAIN_PAGE: resi18n_main_page_en,
    I18N_NAMESPACE_PRODUCT_PAGE: resi18n_product_page_en,
    I18N_NAMESPACE_SOLUTION_PAGE: resi18n_solution_page_en,
    I18N_NAMESPACE_COMMUNITY_PAGE: resi18n_community_page_en,
    I18N_NAMESPACE_SERVICE_PAGE: resi18n_service_page_en,
    I18N_NAMESPACE_ALL_SEARCH_PAGE: resi18n_all_search_page_en,
    I18N_NAMESPACE_MY_PAGE: resi18n_my_page_en,
    I18N_NAMESPACE_SERVICE: resi18n_service_page_en,
    I18N_NAMESPACE_COMMON: resi18n_common_en,
    I18N_NAMESPACE_ASSET: resi18n_asset_en,

  },
  ko: {
    I18N_NAMESPACE_ADMIN_PAGE: resi18n_admin_page_ko,
    I18N_NAMESPACE_HEADER: resi18n_header_ko,
    I18N_NAMESPACE_FOOTER: resi18n_footer_ko,
    I18N_NAMESPACE_MAIN_PAGE: resi18n_main_page_ko,
    I18N_NAMESPACE_PRODUCT_PAGE: resi18n_product_page_ko,
    I18N_NAMESPACE_SOLUTION_PAGE: resi18n_solution_page_ko,
    I18N_NAMESPACE_COMMUNITY_PAGE: resi18n_community_page_ko,
    I18N_NAMESPACE_SERVICE_PAGE: resi18n_service_page_ko,
    I18N_NAMESPACE_ALL_SEARCH_PAGE: resi18n_all_search_page_ko,
    I18N_NAMESPACE_MY_PAGE: resi18n_my_page_ko,
    I18N_NAMESPACE_SERVICE: resi18n_service_page_ko,
    I18N_NAMESPACE_COMMON: resi18n_common_ko,
    I18N_NAMESPACE_ASSET: resi18n_asset_ko,
  },
  'en-US': {
    I18N_NAMESPACE_ADMIN_PAGE: resi18n_admin_page_enus,
    I18N_NAMESPACE_HEADER: resi18n_header_enus,
    I18N_NAMESPACE_FOOTER: resi18n_footer_enus,
    I18N_NAMESPACE_MAIN_PAGE: resi18n_main_page_enus,
    I18N_NAMESPACE_PRODUCT_PAGE: resi18n_product_page_enus,
    I18N_NAMESPACE_SOLUTION_PAGE: resi18n_solution_page_enus,
    I18N_NAMESPACE_COMMUNITY_PAGE: resi18n_community_page_enus,
    I18N_NAMESPACE_SERVICE_PAGE: resi18n_service_page_enus,
    I18N_NAMESPACE_ALL_SEARCH_PAGE: resi18n_all_search_page_enus,
    I18N_NAMESPACE_MY_PAGE: resi18n_my_page_enus,
    I18N_NAMESPACE_SERVICE: resi18n_service_page_enus,
    I18N_NAMESPACE_COMMON: resi18n_common_enus,
    I18N_NAMESPACE_ASSET: resi18n_asset_enus,
  }
};

const res_category_en = {
  HOMEPAGE_BUTTON: "Go to the homepage",
  MODEL: "Model",
  TREE_ADD_LEVEL_1_CATEGORY: "Add 1st category",
  TREE_ADD_LEVEL_2_CATEGORY: "Add 2nd category",
  TREE_ADD_LEVEL_3_CATEGORY: "Add 3rd category",
  TREE_ADD_LEVEL_4_CATEGORY: "Add 4st category",
};

const res_category_ko = {
  HOMEPAGE_BUTTON: "홈페이지 바로가기",
  MODEL: "모델",
  TREE_ADD_LEVEL_1_CATEGORY: "1단계 추가",
  TREE_ADD_LEVEL_2_CATEGORY: "2단계 추가",
  TREE_ADD_LEVEL_3_CATEGORY: "3단계 추가",
  TREE_ADD_LEVEL_4_CATEGORY: "4단계 추가",
};
export const TreeCardLangRes = {
  [LANG_EN]: res_category_en,
  [LANG_EN_US]: res_category_en,
  [LANG_KO]: res_category_ko,
  [LANG_KO_KR]: res_category_ko
};
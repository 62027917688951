export class LoginInfo {

  apires: "" | "Y" = "";
  userId: string = "";
  email: string = "";
  name: string = "";
  docCommentCnt: number = 0;
  allowExpert: boolean = false;
  techPoint: number = 0;
  loginFailNumber: number = 0;
  loginType: string | 'WELS' = "";
  nickname: string = "";
  writeDocCnt: number = 0;
  authorities: Array<string> = [];
  qrtrTechPoint: number = 0;
  allowAdminManage: boolean = false;


  allowAdminKoKr: boolean = false;
  allowAdminEn: boolean = false;
  allowAdminEnUs: boolean = false;
  allowAdminSw: boolean = false;

  userRoles: string = '';

  profilePhotoFileKey: string = "";

  department: string = "";

  verifyEmailChecked;

  phoneNumber: string = "";
  countryCode: string = "";
  familyName: string = "";
}

export interface IfLoginInfo extends LoginInfo {
}

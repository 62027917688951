/**
 * 국가 언어코드.
 * i18n에서는,
 * 1. 언어(소)-국가(대) 의 구조로 사용한다.
 * 2. 실제 lang 필드에 저장하는 경우에도, i18n의 저장방식을 따른다.
 * 3. en 은 global en으로 처리한다.
 * 4. global 의 country 코드는 WW로 처리한다.
 * 5. 국가 언어 구분이 필요한 URL은 /국가코드(소)/언어코드(소)/기존URL 의 방식을 따르나.
 *
 */
export const LANG_KO: string = "ko";
export const LANG_EN = "en";
export const LANG_KO_KR = "ko-KR";
export const LANG_EN_US = "en-US";

export type LANG_TYPE = "en" | "ko-KR" | "en-US"

export const AllLangs = [LANG_KO, LANG_EN, LANG_EN_US, LANG_KO_KR];
export const SupportLangs = [LANG_EN, LANG_EN_US, LANG_KO_KR];

export const langutil = {
  getLangCode: (country: string, lang: string) => {
    if (!country || country === "ww") {
      return (lang || "").toLowerCase();
    }

    return (lang || "").toLowerCase() + "-" + (country || "").toUpperCase();
  },

  splitLangCode: (langCode: string) => {
    let [lang, country] = langCode.split("-");

    if (!country) {
      country = "ww";
    }
    country = country.toLowerCase();

    return {country, lang};
  },
  urlPrefix: (langCode: string) => {
    const {country, lang} = langutil.splitLangCode(langCode);
    return "/" + country + "/" + lang;
  },
  locationSlicer: () => {
    const pathSegments = location.pathname.split('/');
    if (pathSegments.length < 3) return "";
    return pathSegments.slice(1, 3).join('/') + "/";
  },
  countryLangPrefix: (pathname: string) => {
    if ((pathname || "").startsWith('/us/en')) return '/us/en';
    if ((pathname || "").startsWith('/kr/ko')) return '/kr/ko';
    if ((pathname || "").startsWith('/ww/en')) return '/ww/en';
    return "";
  },
  isCountryLangUrl: () => {
    let pattern = /^https?:\/\/[^/]+\/(\w\w)\/(\w\w)\//;
    let match = location.href.match(pattern);

    if ((match || []).length == 3) {
      let lang = langutil.getLangCode(match[1], match[2]);
      if (SupportLangs.includes(lang)) {
        return true; // url을 가장 먼저 적용함.
      }
    }
    return false;

  },
  getCountryLangUrl: (code: string) => {
    const {country, lang} = langutil.splitLangCode(code);
    let pattern = /^(https?:\/\/[^/]+)(.+)$/;
    let match = location.href.match(pattern);
    if ((match || []).length === 3) {
      const url = match[1] + "/" + country + "/" + lang + match[2];
      console.log("URL", url);
      return url;
    }
    return location.href;
  }
};






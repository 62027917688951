import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';


import {AllLangs, LANG_EN, LANG_KO, LANG_KO_KR, langutil, SupportLangs} from "language/langutil";
import {resources} from "language/LanguageResources";


export interface LanguageService {
  getTranslationByKey(key: string): string | undefined;

  getLanguage(): string;

  setLanguage(locale: string): void;

  setNamespace(namespace: string): void;

  trans(key: string): string | undefined;

  transns(ns: string, key: string): string | undefined;

  lang(): string;

  setlang(locale: string): void;

  setns(namespace: string): void;
}

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: 'ssqLangDetector',
  lookup(options) {
    let pattern = /^https?:\/\/[^/]+\/(\w\w)\/(\w\w)\//;
    let match = location.href.match(pattern);

    if ((match || []).length == 3) {
      let lang = langutil.getLangCode(match[1], match[2]);
      if (lang == LANG_KO) lang = LANG_KO_KR;
      if (SupportLangs.includes(lang)) {
        return lang; // url을 가장 먼저 적용함.
      }
    }

    let lang = localStorage.getItem('ssq-language');
    if (lang == LANG_KO) lang = LANG_KO_KR;
    if (lang && SupportLangs.includes(lang)) {
      return lang;
    }

    lang = window.navigator.language;
    //if (lang === LANG_EN_US) return LANG_EN; // 임시제한.
    if (lang == LANG_KO) lang = LANG_KO_KR;
    if (SupportLangs.includes(lang)) {
      return lang;
    }
    return LANG_EN;
  },
});

export class SystemLanguageService implements LanguageService {
  constructor() {
    i18n
      .use(languageDetector)
      .init({
        resources,
        detection: {
          order: ['ssqLangDetector'],
          caches: [],
        },
        fallbackLng: 'en',
      });
  }

  getTranslationByKey(key: string, p?): string | undefined {
    if (p) {
      return i18n.t(key, p);
    }
    return i18n.t(key);
  }


  getLanguage(): string {
    if (AllLangs.includes(i18n.language)) {
      return i18n.language;
    }
    return LANG_EN;
  }

  setLanguage(locale: string): Promise<undefined> {
    return i18n
      .changeLanguage(locale)
      .then();
  }

  setNamespace(namespace: string): void {
    i18n.setDefaultNamespace(namespace);
  }


  trans(key: string): string | undefined {
    return i18n.t(key);
  }

  transns(ns: string, key: string): string | undefined {
    i18n.setDefaultNamespace(ns);
    return i18n.t(key);
  }

  lang(): string {
    if (AllLangs.includes(i18n.language)) {
      return i18n.language;
    }
    return LANG_EN;
  }

  setlang(locale: string): Promise<undefined> {
    return i18n
      .changeLanguage(locale)
      .then();
  }

  setns(namespace: string): void {
    i18n.setDefaultNamespace(namespace);
  }
}
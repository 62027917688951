export const AdmissionType = Object.freeze({
  APPROVED: "APPROVED",
  NOT_APPROVED: "NOT_APPROVED",
  REJECTED: "REJECTED",
  REPLIED: "REPLIED",
  ANSWERED: "ANSWERED",
  DEL_REQUESTED: "DEL_REQUESTED",
  DELETED: "DELETED",
  PARTNER_QNA_CLOSED: "PARTNER_QNA_CLOSED",
  PARTNER_QNA_OPEN: "PARTNER_QNA_OPEN"
});

export type ADMISSION_TYPE = keyof typeof AdmissionType;

export const AdmissionTypeForFilter = Object.freeze({
  ALL: "ALL",
  ...AdmissionType
});

export const getAdmissionTypeLangKey = (value: string) => {
  switch (value) {
    case AdmissionType.APPROVED:
      return 'I18N_FILTER_APPROVED';
    case AdmissionType.NOT_APPROVED:
      return 'I18N_FILTER_NOT_APPROVED';
    case AdmissionType.REJECTED:
      return 'I18N_FILTER_REJECT';
    case AdmissionType.REPLIED:
      return 'I18N_FILTER_REPLIED';
    case AdmissionType.ANSWERED:
      return 'I18N_FILTER_ANSWERED';
    case AdmissionType.DEL_REQUESTED:
      return 'I18N_FILTER_DEL_REQUESTED';
    case AdmissionType.DELETED:
      return 'I18N_FILTER_DELETED';
    case AdmissionType.PARTNER_QNA_CLOSED:
      return 'I18N_FILTER_PARTNER_QNA_CLOSED';
    case AdmissionType.PARTNER_QNA_OPEN:
      return 'I18N_FILTER_PARTNER_QNA_OPEN';
    default:
      return '';
  }
};

export const getUserStateLangKey = (value: string) => {
  switch (value) {
    case AdmissionType.APPROVED:
      return 'I18N_USER_STATE_VERIFIED';
    case AdmissionType.NOT_APPROVED:
      return 'I18N_USER_STATE_NOT_VERIFIED';
    case AdmissionType.REJECTED:
      return 'I18N_USER_STATE_BLOCKED';
    default:
      return '';
  }
};



import {FetchResponse, FetchWrapper} from "./FetchWrapper";
import HttpStatus from "../definitions/HttpStatus";

export interface Http {
  postFormData(url: string, formData: FormData): Promise<FetchResponse>;

  post(url: string, body?: any): Promise<FetchResponse>;

  get(url: string): Promise<FetchResponse>;

  patch(url: string, body?: any): Promise<FetchResponse>;

  put(url: string, body?: any): Promise<FetchResponse>;

  delete(url: string, body?: any): Promise<FetchResponse>;

  head(url: string): Promise<FetchResponse>;
}

export class NetworkHttp implements Http {
  private fetchWrapper: FetchWrapper;
  private readonly redirectHandler: Function;

  constructor(fetchWrapper: FetchWrapper, redirectHandler: Function) {
    this.fetchWrapper = fetchWrapper;
    this.redirectHandler = redirectHandler;
  }

  async postFormData(url: string, formData: FormData): Promise<FetchResponse> {
    return await this.fetchWrapper.fetch(url, {
      body: formData,
      method: "POST"
    });
  }

  async post(url: string, body?: any): Promise<FetchResponse> {
    const response = await this.fetchWrapper.fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      method: "POST"
    });

    if (response.status === HttpStatus.UNAUTHORIZED) {
      this.redirectHandler();
    }

    return response;
  }

  async get(url: string): Promise<FetchResponse> {
    const init = {
      method: "GET"
    };
    const response = await this.fetchWrapper.fetch(url, init);

    if (response.status === HttpStatus.UNAUTHORIZED) {
      this.redirectHandler();
    }

    return response;
  }

  async delete(url: string, body?: any): Promise<FetchResponse> {
    const response = await this.fetchWrapper.fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body)
    });

    return response;
  }

  async patch(url: string, body?: any): Promise<FetchResponse> {
    const response = await this.fetchWrapper.fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body)
    });

    if (response.status === HttpStatus.UNAUTHORIZED) {
      this.redirectHandler();
    }

    return response;
  }

  async put(url: string, body?: any): Promise<FetchResponse> {
    const response = await this.fetchWrapper.fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body)
    });

    if (response.status === HttpStatus.UNAUTHORIZED) {
      this.redirectHandler();
    }

    return response;
  }

  async head(url: string): Promise<FetchResponse> {
    const response = await this.fetchWrapper.fetch(url, {
      method: "HEAD",
    });

    if (response.status === HttpStatus.UNAUTHORIZED) {
      this.redirectHandler();
    }

    return response;
  }
}


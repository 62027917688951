import React from 'react';
import ModalPortal from "./ModalPortal";
import styled from "styled-components";
import {AssetStyle} from "asset/style/AssetStyled";
import {GlobalStyle} from "App";

interface Props {
  children;
  onClick?;
  bg?;
  index?;
  onScroll?: boolean;
}


export const Modal = (props: Props) => {
  return (
    <ModalPortal>
      {props.onScroll ? <AssetStyle/> : <GlobalStyle/>}
      <div style={{
        position: 'fixed', width: '100%', height: '100%', top: 0, left: 0, background: props.bg ? props.bg : 'rgba(0,0,0,0.5)',
        zIndex: props.index ? props.index : 10001
      }}
        // onClick={(e) => {
        //   e.preventDefault();
        //   e.stopPropagation();
        //   props.onClick;
        // }}
        //onClick={props.onClick}
        onClick={(e) => props.onClick && props.onClick(e)}
      >
        {props.children}
      </div>
    </ModalPortal>
  );
};

export const ModalWrap = styled.div`
  background: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  padding: 0;
  margin: 0;
  color: #2a2c2f;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.2);
`;


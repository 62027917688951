let store;

export function setstore(s) {
  store = s;
  (window as any).store = s;
  //console.log("set store", store, store.getState());
}

export function getstore() {
  return store;
}


export const RECENT_SEARCH_VALUE_STORAGE_KEY = "RECENT_SEARCH_VALUE";
export const RECENT_FILTER_SEARCH_VALUE_STORAGE_KEY = "RECENT_FILTER_SEARCH_VALUE";
export const RECENT_EXCEPT_VALUE_STORAGE_KEY = "RECENT_EXCEPT_LECTURE_VALUE";
export const RECENT_EXCEPT_LECTURE_DATE_KEY = "RECENT_EXCEPT_LECTURE_DATE_VALUE";
export const RECENT_PRODUCT_SELECT_VALUE_STORAGE_KEY = "RECENT_PRORDUCT_SELECT_VALUE";

export interface LocalStorageWrapper {
  getItem(name: string): string | null;

  setItem(name: string, value: string): void;

  removeItem(name: string): void;
}

export class BrowserLocalStorageWrapper implements LocalStorageWrapper {
  getItem(name: string): string | null {
    return window.localStorage.getItem(name);
  }

  setItem(name: string, value: string): void {
    return window.localStorage.setItem(name, value);
  }

  removeItem(name: string): void {
    return window.localStorage.removeItem(name);
  }
}

import {LocalStorageWrapper, RECENT_SEARCH_VALUE_STORAGE_KEY} from "./LocalStorageWrapper";
import * as DOMPurify from 'dompurify';
import parse from "html-react-parser";

export const getTextFromHtml = (htmlCode: string) => {
  if (htmlCode) {
    let result = htmlCode?.replace(/<\/?[^>]+(>|$)/g, "");
    result = result.replace(/&nbsp;/g, "");
    return result;
  } else {
    return "";
  }
};


const purifyConfig = {
  ADD_TAGS: ["iframe"], //or ALLOWED_TAGS
  ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"]
};
export const getHighlightedTextFromHtml = (htmlCode: string) => {
  if (htmlCode) {
    let result = htmlCode?.replace(/<(?!\/b)(?!b)([^>]*)(>|$)/gi, "");
    result = result.replace(/<blockquote>|<\/blockquote>/gi, '');
    result = result.replace(/&nbsp;/g, "");
    result = DOMPurify.sanitize(result, purifyConfig);
    return result;
  } else {
    return "";
  }
};

DOMPurify.addHook("uponSanitizeElement", (node: any, data) => {
  if (data.tagName === "iframe") {
    const src = node.getAttribute("src") || "";
    // console.log("src==================================", src);

    if (!["https://www.youtube.com/embed/", "//if-cdn.com/"]
      .reduce((acc, cur) => {
        if (acc) return acc;
        return src.startsWith(cur) ? true : false;
      }, false)) {
      //console.log("remove....iframe src", src);
      return node.parentNode.removeChild(node);
    }
  }
});
export const parseSanitize = (html: string) => {
  return parse(DOMPurify.sanitize(html, purifyConfig));
};

export const sanitize = (html: string) => {
  return DOMPurify.sanitize(html, purifyConfig);
  //return html;
};

export const getEncodedStringRemovedUnValidUrl = (fileName: string) => {
  let restrictChars = /[\{\}\[\]\/?,;:|\)*`!^+<>@\#$%&\\\=\(\'\"]/gi;

  let result = fileName.replace(restrictChars, "_");

  return encodeURIComponent(result);
};

export const saveAndLoadRecentSearch = (
  localStorageWrapper: LocalStorageWrapper, searchInput: string
): string[] | undefined => {
  let lengthOfRecentSearchInputs: any;
  let getLocalStorageValue: string[] | undefined;
  let recentSearchInputs: string[] | undefined;

  if (!localStorageWrapper.getItem(RECENT_SEARCH_VALUE_STORAGE_KEY)) {
    localStorageWrapper.setItem(RECENT_SEARCH_VALUE_STORAGE_KEY, JSON.stringify([]));
    recentSearchInputs = [];
  } else {
    getLocalStorageValue = localStorageWrapper.getItem(RECENT_SEARCH_VALUE_STORAGE_KEY)?.split(",");
    recentSearchInputs = getLocalStorageValue;
  }

  if (searchInput) {
    if (!localStorageWrapper.getItem(RECENT_SEARCH_VALUE_STORAGE_KEY)?.includes(searchInput)) {
      if (recentSearchInputs) {
        recentSearchInputs.push(searchInput);
        localStorageWrapper.setItem(RECENT_SEARCH_VALUE_STORAGE_KEY, recentSearchInputs.toString());
      }
      lengthOfRecentSearchInputs = recentSearchInputs?.toString().split(",").length;
      if (lengthOfRecentSearchInputs > 5) {
        recentSearchInputs = recentSearchInputs?.slice(1, 6);
        if (recentSearchInputs) {
          localStorageWrapper.setItem(RECENT_SEARCH_VALUE_STORAGE_KEY, recentSearchInputs.toString());
        }
      }
    } else {
      if (recentSearchInputs) {
        recentSearchInputs.splice(recentSearchInputs?.indexOf(searchInput), 1);
        recentSearchInputs.push(searchInput);
        localStorageWrapper.setItem(RECENT_SEARCH_VALUE_STORAGE_KEY, recentSearchInputs.toString());
      }
    }
  }
  recentSearchInputs = recentSearchInputs?.reverse();

  return recentSearchInputs;
};
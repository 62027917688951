import {ACTION_TYPES} from "store/ActionTypes";
import {useDispatch, useSelector} from "react-redux";
import {useCallback} from "react";

export class ReduxTrendState {
  // condition: (number | string)[] = [];
  // type: { value: string } = {value: '1h'};
  // date: { start: Date, end: Date };
  // customDate: { start: Date, end: Date };
  downloadDate: { start: Date, end: Date } = {start: new Date(), end: new Date()};
  downloadUnit: string = 'hour';
  downloadPjtsOrDevs: (number | string)[] = [];
}

export interface IfReduxTrendState extends ReduxTrendState {

}

const initState: IfReduxTrendState = new ReduxTrendState();

const reducerTrend = (state = initState, action) => {
  switch (action.type) {
    // case ACTION_TYPES.TREND.SET_CONDITION:
    //   return {...state, condition: action.payload};
    // case ACTION_TYPES.TREND.SET_TYPE:
    //   return {...state, type: action.payload};
    // case ACTION_TYPES.TREND.SET_DATE:
    //   return {...state, date: action.payload};
    // case ACTION_TYPES.TREND.SET_CUSTOM_DATE:
    //   return {...state, customDate: action.payload};
    case ACTION_TYPES.TREND.SET_DOWNLOAD_DATE:
      return {...state, downloadDate: action.payload};
    case ACTION_TYPES.TREND.SET_DOWNLOAD_UNIT:
      return {...state, downloadUnit: action.payload};
    case ACTION_TYPES.TREND.SET_DOWNLOAD_PROJECTS_OR_DEVICES:
      return {...state, downloadPjtsOrDevs: action.payload};
    default:
      return state;
  }
};

export default reducerTrend;

// export const useTrendSetCondition = (): [(number | string)[], (update: (prevConditions: (number | string)[]) => (number | string)[]) => void] => {
//   const dispatch = useDispatch();
//   const condition = useSelector((state: any): (number | string)[] => state.trend.condition);
//   const setCondition = useCallback((update: ((prevConditions: (number | string)[]) => (number | string)[])) => {
//     dispatch({
//       type: ACTION_TYPES.TREND.SET_CONDITION,
//       payload: update(condition)
//     });
//   }, [dispatch, condition]);
//   return [condition, setCondition];
// };

// export const useTrendSetType = (): [{ value: string }, (setType: { value: string }) => void] => {
//   const dispatch = useDispatch();
//   const type = useSelector((state: any): { value: string } => state.trend.type);
//   const setType = useCallback((setValue: { value: string }) => {
//     dispatch({type: ACTION_TYPES.TREND.SET_TYPE, payload: setValue});
//   }, [dispatch]);
//   return [type, setType];
// };

// export const useTrendSetDate = (): [{ start: Date, end: Date }, (set: { start: Date; end: Date }) => void] => {
//   const dispatch = useDispatch();
//   const [defaultDate] = useState({
//     start: addDays(new Date(), -1),
//     end: new Date(),
//   });
//   const date = useSelector((state: any): { start: Date, end: Date } => state.trend.date || defaultDate);
//   const setDate = useCallback((set: { start: Date, end: Date }) => {
//     dispatch({type: ACTION_TYPES.TREND.SET_DATE, payload: set});
//   }, [dispatch]);
//   return [date, setDate];
// };

// export const useTrendSetCustomDate = (): [{ start: Date, end: Date }, (set: { start: Date; end: Date }) => void] => {
//   const dispatch = useDispatch();
//   const customDate = useSelector((state: any): { start: Date, end: Date } => state.trend.customDate);
//   const setCustomDate = useCallback((set: { start: Date, end: Date }) => {
//     dispatch({type: ACTION_TYPES.TREND.SET_CUSTOM_DATE, payload: set});
//   }, [dispatch]);
//   return [customDate, setCustomDate];
// };

export const useTrendSetDownloadDate = (): [{ start: Date, end: Date }, (set: {
  start: Date;
  end: Date
}) => void] => {
  const dispatch = useDispatch();
  const downloadDate = useSelector((state: any): { start: Date, end: Date } => state.trend.downloadDate);
  const setDownloadDate = useCallback((set: { start: Date, end: Date }) => {
    dispatch({type: ACTION_TYPES.TREND.SET_DOWNLOAD_DATE, payload: set});
  }, [dispatch]);
  return [downloadDate, setDownloadDate];
};

export const useTrendSetDownloadUnit = (): [string, (downloadUnit: string) => void] => {
  const dispatch = useDispatch();
  const downloadUnit = useSelector((state: any): string => state.trend.downloadUnit);
  const setDownloadUnit = useCallback((value: string) => {
    dispatch({type: ACTION_TYPES.TREND.SET_DOWNLOAD_UNIT, payload: value});
  }, [dispatch]);
  return [downloadUnit, setDownloadUnit];
};

export const useTrendSetDownloadPjtOrDevs = (): [(number | string)[], (update: (prevConditions: (number | string)[]) => (number | string)[]) => void] => {
  const dispatch = useDispatch();
  const downloadPjtsOrDevs = useSelector((state: any): (number | string)[] => state.trend.downloadPjtsOrDevs);
  const setDownloadPjtsOrDevs = useCallback((update: ((prevConditions: (number | string)[]) => (number | string)[])) => {
    dispatch({
      type: ACTION_TYPES.TREND.SET_DOWNLOAD_PROJECTS_OR_DEVICES,
      payload: update(downloadPjtsOrDevs)
    });
  }, [dispatch, downloadPjtsOrDevs]);
  return [downloadPjtsOrDevs, setDownloadPjtsOrDevs];
};



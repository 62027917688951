import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {ACTION_TYPES} from "store/ActionTypes";
import {v4} from "uuid";

class AssetToastInfo {
  uuid?: string; // 나중에 reducer에서 처리됨. 호출쪽에서 설정 필요 없음.
  type?: "" | "info" | "warn" | "error" | "clear";
  msgs: string[] = [];
  action?: string;
  callback?: () => void; // action 클릭시 호출함수.( action 이 있는 경우에만.. )
}

export interface IfAssetToastInfo extends AssetToastInfo {
}

export class ReduxAssetToastState {

  list: IfAssetToastInfo[] = [];

}


export interface IfReduxAssetToastState extends ReduxAssetToastState {
}

const initState: IfReduxAssetToastState = new ReduxAssetToastState();

// 우선은 Toast 는 하나만 처리하는 것으로 ..
const reducerAssetToast = (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.ASSET_TOAST.SHOW:
      const info: IfAssetToastInfo = action.payload;
      info.uuid = v4();
      const list = state.list.slice();
      list.push(info);
      return {...state, list};
    case ACTION_TYPES.ASSET_TOAST.REMOVE:
      return {...state, list: state.list.filter(ele => ele.uuid !== action.payload.uuid)};
    default:
      return state;
  }
};

export default reducerAssetToast;


export const useAssetToast = (): (info: IfAssetToastInfo) => void => {
  const dispatch = useDispatch();
  const toast = useCallback((info: IfAssetToastInfo) => {
    dispatch({type: ACTION_TYPES.ASSET_TOAST.SHOW, payload: info});
  }, [dispatch]);
  return toast;
};
export class DisplayPopupItem {
  cookie?: boolean = false;
  center?: boolean = false;
  centerMain?: boolean = false;
  intro?: boolean = false;
  asset?: boolean = false;
}

export interface IfDisplayPopupItem extends DisplayPopupItem {

}
import {gconf} from "conf/gconf";

export interface FetchResponse {
  status: number;

  json(): Promise<any>;

  blob?(): Promise<any>;

  text?(): Promise<any>;
}

export interface FetchWrapper {
  fetch(url: string, init: RequestInit): Promise<FetchResponse>;
}

export class NetworkFetchWrapper implements FetchWrapper {
  fetch(url: string, init: RequestInit): Promise<FetchResponse> {
    return new Promise((resolve, reject) => {
      fetch(url, init).then(res => {
        if (res.status === 401) {
          // location.href = '/';
          const pathname = document.location.pathname || "";
          const returnurl = pathname + document.location.search;
          //const router = useRouter();
          gconf.error401 = true;
          if (gconf.handleError401) {
            gconf.handleError401();
            gconf.handleError401 = null;
          }
          if (gconf.donehref) return;
          gconf.donehref = true;
          if (pathname.startsWith('/boris')) {
            // 임직원 로그인.
            document.location.href = "/requireEmployeeLogin?returnurl=" + encodeURIComponent(returnurl);
          } else {
            // 일반로그인.
            document.location.href = "/requireMemberLogin?returnurl=" + encodeURIComponent(returnurl);
          }
        } else {
          if (gconf.error401) gconf.error401 = false;
        }
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }
}

export const gconf = {
  error401: false,
  handleError401: null,
  error403: false,
  donehref: false,
  logModbus: true,
  logHex: false,
  logReqRes: false,
  //showPublic: true,
  /*
  true : 개발되는 버전을 공개.
  false : 개발되는 버전을 공개하지 않고, 현재(ASIS)버전으로 동작하도록.
   */
  // ssqrelease1st: false,
  //
  // // 표시 결정함수.
  // show(menu: 'service' | 'community' | 'profile-write' | 'public-wels') {
  //
  //   if (menu === 'community') {
  //     if (gconf.showPublic) {
  //       return true;
  //     }
  //     return false;
  //   } else if (menu === 'service') {
  //     if (gconf.showPublic && !gconf.ssqrelease1st) {
  //       return true;
  //     }
  //     return false;
  //   } else if (menu === 'public-wels') {
  //     if (gconf.showPublic) {
  //       return true;
  //     }
  //     return false;
  //   } else if (menu === 'profile-write') {
  //     if (gconf.showPublic) {
  //       return true;
  //     }
  //     return false;
  //   }
  //   return false;
  // },
};


export const cdn_imag_url_base = "https://ssqcdn.azureedge.net/ssqblob/images/images";
import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ACTION_TYPES} from "store/ActionTypes";
import {nulllist, nullobj} from "asset/utils/asset-util";
import {IfReduxRoot} from "store/reducers/index";
import {JnvParam, JnvParamMap, JnvTitle, JnvTitles} from "asset/pages/jnvparam/invjson/jnv-models";


export interface IfReduxJnvParam {
  jnvtitles: JnvTitles;
  jnvparam: JnvParam;
  jnvparammap: JnvParamMap;
}

const initState: IfReduxJnvParam = {
  jnvtitles: null,
  jnvparam: null,
  jnvparammap: null,
};

const reducerJnvParam = (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.JNVPARAM.SET_PARAM:
      return {...state, jnvparam: {...action.payload}};
    case ACTION_TYPES.JNVPARAM.SET_TITLES:
      return {...state, jnvtitles: {...action.payload}};
    case ACTION_TYPES.JNVPARAM.SET_PARAM_MAP:
      return {...state, jnvparammap: {...action.payload}};
    default:
      return state;
  }
};

export default reducerJnvParam;

export const useJnvParam = (): JnvParam => {
  const param: JnvParam = useSelector<IfReduxRoot, JnvParam>((state) => state.jnvparam.jnvparam);
  return param || nullobj;
};

export const useSetJnvParam = (): ((payload: JnvParam) => void) => {
  const dispatch = useDispatch();

  const callback = useCallback(
    (payload: JnvParam) => {
      dispatch({type: ACTION_TYPES.JNVPARAM.SET_PARAM, payload});
    },
    [dispatch]
  );

  return callback;
};

export const useJnvParamMap = (): JnvParamMap => {
  const parammap: JnvParamMap = useSelector((state: IfReduxRoot) => state.jnvparam.jnvparammap);
  return parammap;
};

export const useSetJnvParamMap = (): ((payload: JnvParamMap) => void) => {
  const dispatch = useDispatch();

  const callback = useCallback(
    (payload: JnvParamMap) => {
      dispatch({type: ACTION_TYPES.JNVPARAM.SET_PARAM_MAP, payload});
    },
    [dispatch]
  );

  return callback;
};

export const useJnvTitleList = (): JnvTitle[] => {
  const titles: JnvTitles = useSelector((state: IfReduxRoot) => state.jnvparam.jnvtitles);
  return titles?.Titles || nulllist;
};

export const useSetJnvTitles = (): ((payload: JnvTitles) => void) => {
  const dispatch = useDispatch();

  const callback = useCallback(
    (payload: JnvTitles) => {
      dispatch({type: ACTION_TYPES.JNVPARAM.SET_TITLES, payload});
    },
    [dispatch]
  );

  return callback;
};

import axios from "axios";
import {getstore} from "store/ssqstore";
import {ACTION_TYPES} from "store/ActionTypes";


export function initsvrconf() {
  const store = getstore();
  if (!store) {
    console.error("store is not initialized");
    return;
  }
  axios.get("/api/config/get").then(res => {
    store.dispatch({type: ACTION_TYPES.MAIN.SVR_CONF, payload: res.data});
  });
}
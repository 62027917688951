import {Http} from "../fetch/Http";
import axios from "axios";
import {emptyCategory, ICategory} from "repositories/category/CategoryRepository";
import {LANG_KO_KR} from "language/langutil";


export interface ICategorySearchResultWithCount {
  items: INewCategorySearchResultItem[];
  totalCount: number;
}

export interface INewCategorySearchResultItem {
  id: number;
  lang: string;
  card_description: string;
  category: string;
  description: string;
  expose: string;
  homepage_url: string;
  image_blob_key: string;
  level: number;
  name: string;
  parent: number;
  related_models: string[];
  //related_models_en: string[];
  search_expose: boolean;
  sequence: number;
  type_name: string;
  update_time: Date;

  discontinue: 'true' | 'false';
}

export interface IPostSearchResultWithCount {
  items: IPostSearchResultItem[];
  totalCount: number;
}

export interface IPostSearchResultItem {
  admission: string;
  answer_cnt: number;
  bad_count: number;
  category: string;
  click_count: number;
  description: string;
  display_target: string;
  doc_point: number;
  doc_sample_smry: string;
  doc_title: string;
  doc_type: string;
  employ: boolean;
  expose: boolean;
  expose_site_language: string;
  finished: boolean;
  good_count: number;
  id: number;
  image_url: string;
  last_modifier_id: string;
  ls_related: boolean;
  parent_id: number;
  product: string;
  qrtr_doc_point: number;
  question_id: number;
  related_products: string;
  search_expose: boolean;
  tag: string;
  update_time: Date;
  user_bad_cnt: number;
  user_good_cnt: number;
  writer_id: string;
  writer_nickname: string;
}

export interface IFileSearchResultWithCount {
  items: IFileSearchResultItem[];
  totalCount: number;
}

export interface IFileSearchResultItem {
  blob_url_for_large_file: string;
  category: string;
  display_target: string;
  doc_type: string;
  download_count: number;
  file_content: string;
  file_download_url: string;
  file_key: string;
  file_lang: string;
  file_name: string;
  file_size: number;
  file_type: string;
  id: number;
  image_url: string;
  owner_only: boolean;
  search_expose: boolean;
  tag: string;
  target_software_type: string;
  update_time: Date;
  version_id: number;
  writer_only: boolean;
  docid: number;
}

export interface ICountAllSearchResult {
  countOfSearchedCategoryItem: number;
  countOfSearchedPostItem: number;
  countOfSearchedFileItem: number;
}

export interface ISearchTabIndices {
  categoryTabIdx: number;
  postTabIdx: number;
  fileTabIdx: number;
}

export interface ISearchFilterRequest {
  resultType: string;
  srchType: string;
  searchKeyword: string;
  lang: string;
  page: number;
  loginType: string;
  size?: number;
  daterange?: string;
  category?: ICategory;
  orderby?: string;
  doctype?: string;
  rangetype?: string;
  tags?: string[];
  isPartner?: boolean;
  isRnd?: boolean;
}

export const emptyISearchFilterRequest: ISearchFilterRequest = {
  resultType: '',
  srchType: '',
  searchKeyword: '',
  lang: '',
  page: 1,
  size: 20,
  loginType: '',
  daterange: 'DEFAULT',
  category: emptyCategory,
  orderby: 'ACCURACY',
  doctype: 'DEFAULT',
  rangetype: 'DEFAULT',
  tags: [],
  isPartner: false,
  isRnd: false,
};

export const emptyCountAllSearchResult: ICountAllSearchResult = {
  countOfSearchedCategoryItem: 0,
  countOfSearchedPostItem: 0,
  countOfSearchedFileItem: 0
};

export const ALL_SEARCH_CATEGORY_GET_SIZE = 2;
export const ALL_SEARCH_GET_SIZE = 5;
export const TAB_SEARCH_GET_SIZE = 20;

//TODO: Not Tested
export interface SearchRepository2 {
  getSuggest(searchKeyword: string, lang: string);

  getNewCategorySearchData(srchRequest: ISearchFilterRequest): Promise<ICategorySearchResultWithCount>;

  getPostSearchData(srchRequest: ISearchFilterRequest): Promise<IPostSearchResultWithCount>;

  getFileSearchData(srchRequest: ISearchFilterRequest): Promise<IFileSearchResultWithCount>;

  getRelatedSearchData(searchKeyword: string): Promise<string[]>;
}

const getSrchLang = (lang: string) => {
  if (lang === LANG_KO_KR) return 'ko';
  return lang.toLowerCase();
};

export class NetworkSearchRepository2 implements SearchRepository2 {
  http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  async getNewCategorySearchData(srchRequest: ISearchFilterRequest): Promise<ICategorySearchResultWithCount> {
    if (srchRequest.category !== emptyCategory || srchRequest.doctype !== "DEFAULT" || srchRequest.orderby !== "ACCURACY" || srchRequest.rangetype !== "DEFAULT" || srchRequest.tags.length !== 0) return {
      totalCount: 0,
      items: []
    };

    if (srchRequest.resultType === "VERBATIM") {
      srchRequest.searchKeyword = srchRequest.searchKeyword.replace(" ", "%2b");
    }

    const reqlang = getSrchLang(srchRequest.lang);
    const response = await axios.get(
      `/api/search?query=${encodeURIComponent(`index=category_${reqlang}&indexsize=${srchRequest.size}&query=${encodeURIComponent(srchRequest.searchKeyword)}&page=${srchRequest.page}${getDateRange(srchRequest.daterange)}&filter=category@expose:true`)}`);

    //console.log("result=================", response.data);
    response.data.result[0].items.map(data => {
      if (data.related_models) data.related_models = data.related_models.split(';');
      //if (data.related_models_en) data.related_models_en = data.related_models_en.split(';');
    });

    return {totalCount: response.data.totalCount, items: response.data.result[0].items};
  }

  async getPostSearchData(srchRequest: ISearchFilterRequest): Promise<IPostSearchResultWithCount> {
    if (srchRequest.resultType === "VERBATIM") {
      srchRequest.searchKeyword = srchRequest.searchKeyword.replace(" ", "%2b");
    }

    const reqlang = getSrchLang(srchRequest.lang);
    const response = await axios.get(
      `/api/search?query=${encodeURIComponent(`index=post_${reqlang}&indexsize=${srchRequest.size}&query=${encodeURIComponent(srchRequest.searchKeyword)}&page=${srchRequest.page}${getDateRange(srchRequest.daterange)}${orderByParam(srchRequest.orderby, "post")}&filter=post_${reqlang}@(display_target:0${srchRequest.loginType === 'WELS' ? ' OR display_target:10 OR display_target:15 OR display_target:20' : srchRequest.isPartner ? ' OR display_target:10 OR display_target:15' : srchRequest.loginType === '' ? '' : ' OR display_target:10'}) AND expose:true ${srchRequest.isRnd == false ? 'AND NOT(doc_type:RND_NOTI OR doc_type: RND_QNA_Q OR doc_type:RND_QNA_A OR doc_type:RND_TALK)' : ''} `)}${getDocType(srchRequest.doctype)}${getLsRelated(srchRequest.rangetype)}${getTags(srchRequest.tags)}`);
    return {totalCount: response.data.totalCount, items: response.data.result[0].items};
  }

  async getFileSearchData(srchRequest: ISearchFilterRequest): Promise<IFileSearchResultWithCount> {
    if (srchRequest.resultType === "VERBATIM") {
      srchRequest.searchKeyword = srchRequest.searchKeyword.replace(" ", "%2b");
    }

    const reqlang = getSrchLang(srchRequest.lang);
    const response = await axios.get(
      `/api/search?query=${encodeURIComponent(`index=file_${reqlang}&indexsize=${srchRequest.size}&query=${encodeURIComponent(srchRequest.searchKeyword)}&page=${srchRequest.page}${getDateRange(srchRequest.daterange)}${orderByParam(srchRequest.orderby, "file")}&filter=file_${reqlang}@(display_target:0${srchRequest.loginType === 'WELS' ? ' OR display_target:10 OR display_target:20' : srchRequest.isPartner ? ' OR display_target:10 OR display_target:15' : srchRequest.loginType === '' ? '' : ' OR display_target:10'})${srchRequest.isRnd == false ? 'AND NOT(doc_type:RND_NOTI OR doc_type: RND_QNA_Q OR doc_type:RND_QNA_A OR doc_type:RND_TALK)' : ''} ${getCategory(srchRequest.category)}${getDocType(srchRequest.doctype)}${getLsRelated(srchRequest.rangetype)}${getTags(srchRequest.tags)}`)}`);
    return {totalCount: response.data.totalCount, items: response.data.result[0].items};
  }

  async getSuggest(searchKeyword: string, lang: string) {
    const reqlang = getSrchLang(lang);
    const response = await axios.get(`/api/suggest?query=${encodeURIComponent(`query=${encodeURIComponent(searchKeyword)}&service=index_${reqlang}`)}`);
    return response.data.result;
  }

  async getRelatedSearchData(searchKeyword: string): Promise<string[]> {
    const response = await axios.get(`/api/related?query=${encodeURIComponent(`query=${encodeURIComponent(searchKeyword)}&service=tot`)}`);
    return response.data?.result || [];
  }
}

function getLsRelated(rangetype: string): string {
  if (rangetype === "LS_RELATED") return ` AND ls_related:true`;
  if (rangetype === "LS_OFFICIAL") return ` AND NOT doc_type: TIP AND NOT doc_type: BUY AND NOT doc_type: SELL AND NOT doc_type: JOB_OFFER AND NOT doc_type: JOB_SEARCH AND NOT doc_type: TECH_GENERAL AND NOT doc_type: QNA_A AND NOT doc_type: QNA_Q`;
  else return '';
}

function getDocType(doctype: string): string {
  if (doctype === "DEFAULT") return '';
  else if (doctype === "QNA") return ` AND (doc_type:QNA_A OR doc_type:QNA_Q)`;
  else if (doctype === "RND_QNA") return ` AND (doc_type:RND_QNA_A OR doc_type:RND_QNA_Q)`;
  else return ` AND doc_type:${doctype}`;
}

function orderByParam(orderby: string, dataType: string): string {
  if (orderby === "ACCURACY") return '';
  else if (orderby === "UPDATE") return '&sort=update_time@DESC';
  else if (orderby === "POPULAR") return `&sort=${dataType === "post" ? 'doc_point' : 'download_count'}@DESC`;
  return '';
}

function getCategory(category: ICategory): string {
  if (category.name === "") return '';
  else return ` AND category:${category.name}`;
}

function getTags(tags: string[]): string {
  if (tags.length === 0) return '';
  else {
    let returnVal = '';
    tags.forEach(tag => {
      returnVal += ` AND tag:${tag}`;
    });
    return returnVal;
  }

}

function getDateRange(daterange: string): string {
  const today = new Date();
  let rangedDay = new Date();
  if (daterange === 'DEFAULT') return '';
  else if (daterange === 'WEEK') rangedDay.setDate(rangedDay.getDate() - 7);
  else if (daterange === 'ONEMONTH') rangedDay.setMonth(rangedDay.getMonth() - 1);
  else if (daterange === 'SIXMONTH') rangedDay.setMonth(rangedDay.getMonth() - 6);
  else rangedDay.setFullYear(rangedDay.getFullYear() - 1);
  console.log(`&daterange=${formatSimpleDate(rangedDay)},${formatSimpleDate(today)}`);
  return `&daterange=${formatSimpleDate(rangedDay)},${formatSimpleDate(today)}`;
}

const formatSimpleDate = (date: Date): string => {
  const year = date.getFullYear().toString();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return (year + "-" +
    (month > 9 ? month : "0" + month) + "-" +
    (day > 9 ? day : "0" + day));
};
import {ACTION_TYPES} from 'store/ActionTypes';
import svcs from "services";

const {storeCategory, ...rest} = svcs;
const initState = {
  svcs: rest
};

const reducerSVcs = (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SVCS.SVCS:
      return {
        ...state,
        svcs: action.payload,
      };
    default:
      return state;
  }
};

export default reducerSVcs;

import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ACTION_TYPES} from "store/ActionTypes";


export class ReduxWeatherState {
  pos: { lat: number; lon: number; } = {lat: 37.48, lon: 126.93};
  weather: {
    coord: {
      lon: number;
      lat: number;
    };
    weather: [{
      id: number;
      main: string;
      description: string;
      icon: string
    }];
    base: string;
    main: {
      temp: number;
      feels_like: number;
      temp_min: number;
      temp_max: number;
      pressure: number;
      humidity: number;
      sea_level: number;
      grnd_level: number;
    };
    visibility: number;
    wind: {
      speed: number;
      deg: number;
      gust: number;

    };
    rain: {
      '1h': number;
    };
    clouds: {
      all: number;
    };
    dt: number;
    sys: {
      type: number;
      id: number;
      country: string;
      sunrise: number;
      sunset: number;
    };
    timezone: number;
    id: number;
    name: string;
    cod: number;
  };
}

export interface IfReduxWeatherState extends ReduxWeatherState {
}

const initState: IfReduxWeatherState = new ReduxWeatherState();

const reducerWeather = (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.WEATHER.POS:
      return {
        ...state,
        pos: action.payload,
      };
    case ACTION_TYPES.WEATHER.WEATHER:
      return {
        ...state,
        weather: action.payload,
      };
    default:
      return state;
  }
};

export default reducerWeather;


export const useSetWeatherPos = () => {
  const dispatch = useDispatch();
  const callback = useCallback(({lat, lon}: { lat: number, lon: number }) => {
    dispatch({type: ACTION_TYPES.WEATHER.POS, payload: {lat, lon}});
  }, [dispatch]);
  return callback;
};

export const useWeather = (): IfReduxWeatherState => {
  const weather = useSelector((state: any): IfReduxWeatherState => state.weather);
  return weather;
};
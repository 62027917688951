import {combineReducers} from 'redux';
import reducerMain from './reducerMain';
import rootReducer from "./rootReducer";
import reducerSvcs from "store/reducers/reducerSvcs";
import reducerToast from "store/reducers/reducerToast";
import reducerAsset from "store/reducers/reducerAsset";
import reducerWeather from "store/reducers/reducerWeather";
import reducerAssetToast from "store/reducers/reducerAssetToast";
import reducerTrend from "store/reducers/reducerTrend";
import reducerJnvParam, {IfReduxJnvParam} from "store/reducers/reducerJnvParam";

export interface IfReduxRoot {
  root,
  main,
  svcs,
  toast,
  asset,
  trend,
  weather,
  assettoast,
  jnvparam: IfReduxJnvParam
}

const reducers = combineReducers<IfReduxRoot>({
  root: rootReducer,
  main: reducerMain,
  svcs: reducerSvcs,
  toast: reducerToast,
  asset: reducerAsset,
  trend: reducerTrend,
  weather: reducerWeather,
  assettoast: reducerAssetToast,
  jnvparam: reducerJnvParam,
});

export default reducers;

import axios from "axios";
import {gconf} from "conf/gconf";
//import useRouter from "use-react-router";

axios.interceptors.response.use(
  (res) => {
    // if (!(res.status === 200 || res.status === 201 || res.status === 204))
    //   throw new Error();
    //
    // if (res.data.errors) throw new Error(res.data.errors);
    // console.log("axios res", res.status);
    // if (res.status === 401) {
    //
    //
    // }

    if (gconf.error401) gconf.error401 = false;
    if (gconf.error403) gconf.error403 = false;

    return res;
  }, (error) => {
    console.log("err", error?.response?.status);
    if (401 === error?.response?.status) {
      //location.href = "/";
      const pathname = document.location.pathname || "";
      const returnurl = pathname + document.location.search;
      //const router = useRouter();
      gconf.error401 = true;
      if (gconf.handleError401) {
        gconf.handleError401();
        gconf.handleError401 = null;
      }
      if (gconf.donehref) return;
      gconf.donehref = true;

      if (pathname.startsWith('/boris')) {
        // 임직원 로그인.
        document.location.href = "/requireEmployeeLogin?returnurl=" + encodeURIComponent(returnurl);
      } else {
        const haslang = /\/..\/..\//.test(pathname);
        const npath = haslang ? pathname.substring(6) : pathname;
        const langprefix = haslang ? pathname.substring(0, 6) : "";
        // 일반로그인.
        if (npath.startsWith('/requireEmployeeLogin') ||
          npath.startsWith('/requireMemberLogin') ||
          npath.startsWith('/requirePartnerLogin') ||
          npath.startsWith('/asset/loginrequired')) {
          console.error("already login required page", pathname);
          return Promise.reject(error);
        }
        if (pathname.startsWith('/asset')) {
          document.location.href = "/asset/loginrequired?returnurl=" + encodeURIComponent(returnurl);
        } else {
          document.location.href = langprefix + "/requireMemberLogin?returnurl=" + encodeURIComponent(returnurl);
        }
      }
    } else if (403 === error?.response?.status) {
      // forbidden 에러 .
      const pathname = document.location.pathname || "";
      const haslang = /\/..\/..\//.test(pathname);
      const npath = haslang ? pathname.substring(6) : pathname;
      const langprefix = haslang ? pathname.substring(0, 6) : "";
      //const router = useRouter();
      gconf.error403 = true;
      const emsg = error?.response?.data?.message;
      if (emsg === "asset-403") {
        document.location.href = langprefix + "/asset/404";
      } else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);
// @flow
import * as React from 'react';
import {Route} from "react-router-dom";
import {LoadingLayer} from "ctls/modal/LoadingLayer";
//import {AssetToastMgr} from "asset/layout/toast/AssetToastMgr";

const AssetBlankBody = React.lazy(() => import("./AssetBlankBody").then(module => ({default: module.AssetBlankBody})));

export const AssetBlankLayout = (props: { exact?: boolean, path: string | string[], children }) => {


  return <Route path={props.path}
    exact={!!props.exact}
    render={() => {
      return <React.Suspense fallback={<LoadingLayer visible={true}/>}>
        <AssetBlankBody>
          {React.Children.map(props.children, child => React.cloneElement(child))}
        </AssetBlankBody>
      </React.Suspense>;
    }}/>;
};

import {Http} from "../fetch/Http";
import HttpStatus from "../definitions/HttpStatus";
import {DocLanguageType} from "../definitions/DocLanguageType";
import {SiteLanguageType} from "../definitions/SiteLanguageType";
import {DocType} from "../definitions/DocType";
import {TargetType} from "../definitions/TargetType";
import {AdmissionType} from "../definitions/AdmissionType";
import {getTextFromHtml} from "../util/commonUtils";
import {FeedbackType} from "../definitions/FeedbackType";
import axios, {AxiosResponse} from "axios";
import {MenuType} from "definitions/MenuType";

export const NO_VERSION_TAG = "*";

export const DownloadType = Object.freeze({
  VERSION: "VERSION",
  FILE: "FILE"
});

export interface ISsqLectureList {
  lectureId: number;
  lectureStartDt: string;
  lectureEndDt: string;
  lectureTeacherNm: string;
  lectureRoomUrl: string;
  lectureNm: string;
  privateLecture: boolean;
  lectureCnts: string;
  writerId: string;
  lastModifierId: string;
  updtDt: Date;
  lectureUtcDiff: number;
  lang: string;
}

export interface ISsqdataLecture {
  lectureId: number;
  lectureStartDt: Date;
  lectureEndDt: Date;
  lectureTeacherNm: string;
  lectureRoomUrl: string;
  lectureNm: string;
  privateLecture: boolean;
  lectureCnts: string;
  writerId: string;
  lastModifierId: string;
  updtDt: Date;
  lectureUtcDiff: number;
  lang: string;
}

export const emptySsqDataLecture: ISsqdataLecture = {
  lectureId: null,
  lectureStartDt: new Date,
  lectureEndDt: new Date,
  lectureTeacherNm: "",
  lectureRoomUrl: "",
  lectureNm: "",
  privateLecture: false,
  lectureCnts: "",
  writerId: "",
  lastModifierId: "",
  updtDt: new Date,
  lectureUtcDiff: 90,
  lang: ""
};

export interface ISsqDataDocComment {
  docId: number;
  docTitle: string;
  docType: string;
  exposeSiteLanguage: string;
  commentCnts: string;
  employ: boolean;
  updtDt: Date;
  docCommentId: number;
}

export interface ISsqDataDocMinInfo {
  id: number;
  exposeSiteLanguage: string;
}

export interface ISsqDataDocument {
  id: number;
  docTitle: string;
  description: string;
  docType: string;
  docLanguages: string[];
  writerId: string;
  writerNickname: string;
  updateTime: Date;
  clickCount: number;
  admission: string;
  parentId: number | undefined;
  versions: IVersion[];
  categories: number[];
  productCategoryNames: string[];
  imageUrl: string;
  displayTarget: number;
  qrtrDocPoint: number; // 분기문서점수
  docPoint: number; // 문서점수
  docSampleSmry: string; // 문서샘플개요
  finished: boolean; // 완료됨
  answerCnt: number; // 답변횟수
  employ: boolean; // 임직원
  lsRelated: boolean; // LS 관련 글
  goodCount: number;
  badCount: number;
  userGoodCnt: number;
  userBadCnt: number;
  questionId: number;
  expose: boolean;
  lastModifierId?: string;
  sampleVersion: string;
  linkUrl: string;

  exposeSiteLanguage?: string;
}

export const emptySsqDataDocComment: ISsqDataDocComment = {
  docId: 0,
  docTitle: "",
  docType: "",
  exposeSiteLanguage: "",
  commentCnts: "",
  employ: false,
  updtDt: new Date,
  docCommentId: 0
};

export const emptySsqDataDocument: ISsqDataDocument = {
  id: 0,
  docTitle: "",
  description: "",
  docType: "",
  docLanguages: [],
  writerId: "",
  writerNickname: "",
  updateTime: new Date,
  clickCount: 0,
  admission: "",
  parentId: 0,
  categories: [],
  productCategoryNames: [],
  versions: [],
  imageUrl: "",
  displayTarget: 0,
  qrtrDocPoint: 0,
  docPoint: 0,
  docSampleSmry: '',
  finished: false,
  answerCnt: 0,
  employ: false,
  lsRelated: false,
  goodCount: 0,
  badCount: 0,
  userBadCnt: 0,
  userGoodCnt: 0,
  questionId: 0,
  expose: false,
  sampleVersion: '',
  exposeSiteLanguage: '',
  lastModifierId: '',
  linkUrl: '',
};

export interface IVersion {
  id?: number;
  version: string;
  versionDescription: string;
  files: IFile[];
}

export const emptyVersion: IVersion = {
  version: NO_VERSION_TAG,
  versionDescription: "",
  files: []
};

export function getEmptyVersion(): IVersion {
  return {version: NO_VERSION_TAG, versionDescription: "", files: []};
}

export interface IFile {
  id: number;
  fileName: string;
  fileKey: string;
  blobUrlForLargeFile: string;
  fileLang: string;
  fileSize: number;
  fileType: string;
  targetSoftwareType: string;
  downloadCount: number;
  writerOnly: boolean;
  searchExpose?: boolean;
}

export interface IFileHover extends IFile {
  downloadHover?: boolean;
};

export const emptyFile: IFile = {
  id: 0,
  fileKey: "",
  blobUrlForLargeFile: "",
  fileName: "",
  fileLang: DocLanguageType.ETC,
  fileSize: 0,
  downloadCount: 0,
  fileType: "",
  targetSoftwareType: "",
  writerOnly: false,
};

export interface ICountsOfEachDocType {
  manualCount: number;
  catalogCount: number;
  softwareCount: number;
  certificationCount: number;
  dataSheetCount: number;
  cadDataCount: number;
  osCount: number;
  applicationNoteCount: number;
  technicalGuideCount: number;
  sampleCount: number;
  educationNoteCount: number;
  educationVideoCount: number;
  tipCount: number;
  eventCount: number;
  noticeCount: number;
  jobOfferCount: number;
  jobSearchCount: number;
  qnaCount: number;
  techGeneralCount: number;
  userSampleCount: number;
  swServiceCount: number;
  troubleShootingCount: number;
  rndNotiCount: number;
  rndQnaCount: number;
  rndTalkCount: number;
  partnerNotiCount: number;
  partnerQnaCount: number;
  partnerDocCount: number;
}

export const emptyICountSsqDataByDocType: ICountsOfEachDocType = {
  manualCount: 0,
  catalogCount: 0,
  softwareCount: 0,
  certificationCount: 0,
  dataSheetCount: 0,
  cadDataCount: 0,
  osCount: 0,
  applicationNoteCount: 0,
  technicalGuideCount: 0,
  sampleCount: 0,
  educationNoteCount: 0,
  educationVideoCount: 0,
  tipCount: 0,
  eventCount: 0,
  noticeCount: 0,
  jobOfferCount: 0,
  jobSearchCount: 0,
  qnaCount: 0,
  techGeneralCount: 0,
  userSampleCount: 0,
  swServiceCount: 0,
  troubleShootingCount: 0,
  rndNotiCount: 0,
  rndTalkCount: 0,
  rndQnaCount: 0,
  partnerNotiCount: 0,
  partnerQnaCount: 0,
  partnerDocCount: 0
};

export interface ICountOfEachDocTypeRequest {
  categoryId: number;
  docTypes: string[];
  exposeSiteLanguage: string;
  roleOfLoggedInUser: number;
  categoryTypeName: string;
  sortType: number;
  periodType: number;
  tagType: string[];
  keyword: string;
  userId: string;
  partnerId?: number;
}

export const emptyDownloadCountOfEachDocTypeRequest: ICountOfEachDocTypeRequest = {
  categoryId: 0,
  docTypes: [DocType.MANUAL, DocType.CATALOG, DocType.CERTIFICATION, DocType.CAD_DATA,
    DocType.DATASHEET, DocType.SOFTWARE, DocType.OS],
  exposeSiteLanguage: SiteLanguageType.KO_KR,
  roleOfLoggedInUser: TargetType.GUEST,
  categoryTypeName: "",
  sortType: 0,
  periodType: 0,
  tagType: [],
  keyword: '',
  userId: '',
};

export const emptyQnaCountOfEachDocTypeRequest: ICountOfEachDocTypeRequest = {
  categoryId: 0,
  docTypes: [DocType.QNA_Q, DocType.QNA_A],
  exposeSiteLanguage: SiteLanguageType.KO_KR,
  roleOfLoggedInUser: TargetType.GUEST,
  categoryTypeName: "",
  sortType: 0,
  periodType: 0,
  tagType: [],
  keyword: '',
  userId: '',
};

export const emptyTechnicalCountOfEachDocTypeRequest: ICountOfEachDocTypeRequest = {
  categoryId: 0,
  docTypes: [DocType.APPLICATION_NOTE, DocType.TECHNICAL_GUIDE, DocType.TECH_GENERAL],
  exposeSiteLanguage: SiteLanguageType.KO_KR,
  roleOfLoggedInUser: TargetType.GUEST,
  categoryTypeName: "",
  sortType: 0,
  periodType: 0,
  tagType: [],
  keyword: '',
  userId: '',
};

export const emptyBoardCountOfEachDocTypeRequest: ICountOfEachDocTypeRequest = {
  categoryId: 0,
  docTypes: [DocType.TIP, DocType.DOC_EVT, DocType.DOC_NOTI, DocType.JOB_SEARCH, DocType.JOB_OFFER],
  exposeSiteLanguage: SiteLanguageType.KO_KR,
  roleOfLoggedInUser: TargetType.GUEST,
  categoryTypeName: "",
  sortType: 0,
  periodType: 0,
  tagType: [],
  keyword: '',
  userId: '',
};

export const emptySoftwareCountOfEachDocTypeRequest: ICountOfEachDocTypeRequest = {
  categoryId: 0,
  docTypes: [DocType.SOFTWARE, DocType.OS],
  exposeSiteLanguage: SiteLanguageType.KO_KR,
  roleOfLoggedInUser: TargetType.GUEST,
  categoryTypeName: "",
  sortType: 0,
  periodType: 0,
  tagType: [],
  keyword: '',
  userId: '',
};

export const emptyEducationCountOfEachDocTypeRequest: ICountOfEachDocTypeRequest = {
  categoryId: 0,
  docTypes: [DocType.EDUCATION_VIDEO, DocType.EDUCATION_NOTE],
  exposeSiteLanguage: SiteLanguageType.KO_KR,
  roleOfLoggedInUser: TargetType.GUEST,
  categoryTypeName: "",
  sortType: 0,
  periodType: 0,
  tagType: [],
  keyword: '',
  userId: '',
};

export const emptySquareCountOfEachDocTypeRequest: ICountOfEachDocTypeRequest = {
  categoryId: 0,
  docTypes: [DocType.QNA_Q, DocType.TECH_GENERAL],
  exposeSiteLanguage: SiteLanguageType.KO_KR,
  roleOfLoggedInUser: TargetType.GUEST,
  categoryTypeName: "",
  sortType: 0,
  periodType: 0,
  tagType: [],
  keyword: '',
  userId: '',
};

export const emptySampleCountOfEachDocTypeRequest: ICountOfEachDocTypeRequest = {
  categoryId: 0,
  docTypes: [DocType.SAMPLE, DocType.USER_SAMPLE],
  exposeSiteLanguage: SiteLanguageType.KO_KR,
  roleOfLoggedInUser: TargetType.GUEST,
  categoryTypeName: "",
  sortType: 0,
  periodType: 0,
  tagType: [],
  keyword: '',
  userId: '',
};

export const emptyRndCountOfEachDocTypeRequest: ICountOfEachDocTypeRequest = {
  categoryId: 0,
  docTypes: [DocType.RND_NOTI, DocType.RND_QNA_Q, DocType.RND_TALK],
  exposeSiteLanguage: SiteLanguageType.KO_KR,
  roleOfLoggedInUser: TargetType.GUEST,
  categoryTypeName: "",
  sortType: 0,
  periodType: 0,
  tagType: [],
  keyword: '',
  userId: '',
};

export const emptyPartnerCountOfEachDocTypeRequest: ICountOfEachDocTypeRequest = {
  categoryId: 0,
  docTypes: [DocType.PARTNER_NOTI, DocType.PARTNER_QNA_Q],
  exposeSiteLanguage: SiteLanguageType.KO_KR,
  roleOfLoggedInUser: TargetType.GUEST,
  categoryTypeName: "",
  sortType: 0,
  periodType: 0,
  tagType: [],
  keyword: '',
  userId: '',
  partnerId: 0,
};

export const emptySWSERVICECountOfEachDocTypeRequest: ICountOfEachDocTypeRequest = {
  categoryId: 0,
  docTypes: [DocType.SW_SERVICE],
  exposeSiteLanguage: SiteLanguageType.KO_KR,
  roleOfLoggedInUser: TargetType.GUEST,
  categoryTypeName: "",
  sortType: 0,
  periodType: 0,
  tagType: [],
  keyword: '',
  userId: '',
};

export interface IGetSsqDataByPageRequest {
  pageNum: number;
  categoryId: number;
  docTypes: string[];
  exposeSiteLanguage: string;
  roleOfLoggedInUser: number;
  categoryTypeName: string;
  sortType: number;
  periodType: number;
  tagType: string[];
  keyword: string;
  userId: string;
}

export const emptyDownloadGetSsqDataByPageRequest: IGetSsqDataByPageRequest = {
  pageNum: 0,
  categoryId: 0,
  docTypes: [DocType.MANUAL, DocType.CATALOG, DocType.CERTIFICATION, DocType.CAD_DATA,
    DocType.DATASHEET, DocType.SOFTWARE, DocType.OS],
  exposeSiteLanguage: SiteLanguageType.KO_KR,
  roleOfLoggedInUser: TargetType.GUEST,
  categoryTypeName: "",
  sortType: 0,
  periodType: 0,
  tagType: [],
  keyword: '',
  userId: ''
};

export const emptyTechnicalGetSsqDataByPageRequest: IGetSsqDataByPageRequest = {
  pageNum: 0,
  categoryId: 0,
  docTypes: [DocType.APPLICATION_NOTE, DocType.TECHNICAL_GUIDE, DocType.TROUBLE_SHOOTING],
  exposeSiteLanguage: SiteLanguageType.KO_KR,
  roleOfLoggedInUser: TargetType.GUEST,
  categoryTypeName: "",
  sortType: 0,
  periodType: 0,
  tagType: [],
  keyword: '',
  userId: ''
};

export interface IAddClickCountResponse {
  updatedClickCount: number;
}

export interface IDocumentSearchParameter {
  categoryId: number | null;
  industryId: number | null;
  applicationId: number | null;
  isCategoryUnspecified: boolean;
  docType: string[];
  keyword: string | null;
  isOnlyMyDocument: boolean;
  expose: boolean | null;
  admission: string[] | null;
  displayTarget: number[];
  exposeSiteLanguage: string | null;
  langs: string []; // 문서 언어( 다중국가언어 조회를 위해서.. )
  docLanguages: string[]; // 문서 첨부파일.
  pageNum: number;
  isIncludeHistory: boolean;
  finished: boolean | null;
  titleOrWriter?: string;
  sort?: string | "RECENT" | "RECOMMEND" | "NOT_RECOMMEND" | "HELP" | "NOT_HELP" | "REPORT";

  tags: string[];

  queryAll?: boolean;
  registTime?: string;
}

export const ALL_DOCTYPE_OF_DOCUMENT_TAB = [DocType.MANUAL, DocType.CATALOG, DocType.SOFTWARE,
  DocType.CERTIFICATION, DocType.CAD_DATA, DocType.DATASHEET,
  DocType.OS, DocType.EDUCATION_NOTE, DocType.EDUCATION_VIDEO, DocType.SW_SERVICE, DocType.TROUBLE_SHOOTING];

export const ALL_DOCTYPE_OF_TECHNICAL_DOCUMENT_TAB = [DocType.APPLICATION_NOTE, DocType.TECHNICAL_GUIDE, DocType.SAMPLE];
export const ALL_DOCTYPE_OF_COMMUNITY_DOCUMENT_TAB = [DocType.USER_SAMPLE, DocType.TECH_GENERAL, DocType.TIP, DocType.QNA_Q, DocType.QNA_A, DocType.JOB_SEARCH, DocType.JOB_SEARCH, DocType.DOC_NOTI, DocType.DOC_EVT];
//export const ALL_DOCTYPE_OF_COMMUNITY_DOCUMENT_TAB = [DocType.USER_SAMPLE, DocType.TECH_GENERAL, DocType.TIP, DocType.QNA_Q, DocType.QNA_A, DocType.JOB_SEARCH, DocType.JOB_SEARCH, DocType.DOC_NOTI, DocType.DOC_EVT, DocType.APPLICATION_NOTE, DocType.TECHNICAL_GUIDE, DocType.SAMPLE]; // for test

export const emptyDocumentTabSearchParameter: IDocumentSearchParameter = {
  categoryId: null,
  industryId: null,
  applicationId: null,
  isCategoryUnspecified: false,
  docType: ALL_DOCTYPE_OF_DOCUMENT_TAB,
  keyword: null,
  isOnlyMyDocument: false,
  expose: null,
  admission: [AdmissionType.APPROVED, AdmissionType.REJECTED, AdmissionType.DELETED],
  displayTarget: [TargetType.GUEST, TargetType.LOGIN, TargetType.PARTNER, TargetType.EMPLOYEE],
  exposeSiteLanguage: null,
  langs: [],
  docLanguages: [DocLanguageType.KO, DocLanguageType.EN, DocLanguageType.ETC, DocLanguageType.NO_ATTACHMENT],
  pageNum: 0,
  isIncludeHistory: false,
  finished: null,
  tags: [],
  registTime: ''
};

export const emptyTechnicalTabSearchParameter: IDocumentSearchParameter = {
  categoryId: null,
  industryId: null,
  applicationId: null,
  isCategoryUnspecified: false,
  docType: ALL_DOCTYPE_OF_TECHNICAL_DOCUMENT_TAB,
  keyword: null,
  isOnlyMyDocument: false,
  expose: null,
  admission: [AdmissionType.APPROVED, AdmissionType.REJECTED, AdmissionType.DELETED],
  displayTarget: [TargetType.GUEST, TargetType.LOGIN, TargetType.PARTNER, TargetType.EMPLOYEE],
  exposeSiteLanguage: null,
  langs: [],
  docLanguages: [DocLanguageType.KO, DocLanguageType.EN, DocLanguageType.ETC, DocLanguageType.NO_ATTACHMENT],
  pageNum: 0,
  isIncludeHistory: false,
  finished: null,
  tags: [],
  registTime: '',
};

export const emptyCommTabSearchParameter: IDocumentSearchParameter = {
  categoryId: null,
  industryId: null,
  applicationId: null,
  isCategoryUnspecified: false,
  docType: ALL_DOCTYPE_OF_TECHNICAL_DOCUMENT_TAB,
  keyword: null,
  isOnlyMyDocument: false,
  expose: null,
  admission: null,
  displayTarget: [TargetType.GUEST, TargetType.LOGIN, TargetType.PARTNER, TargetType.EMPLOYEE],
  exposeSiteLanguage: null,
  langs: [],
  docLanguages: [DocLanguageType.KO, DocLanguageType.EN, DocLanguageType.ETC, DocLanguageType.NO_ATTACHMENT],
  pageNum: 0,
  isIncludeHistory: false,
  finished: null,
  tags: [],
};

export interface IHistory {
  type: string;
  nickname: string;
  time: string;
  comment: string;
}

export const emptyHistory: IHistory = {
  type: "",
  nickname: "",
  time: "",
  comment: ""
};

export interface ISsqDataDocumentAdminTableRow {
  id: number;
  expose: boolean;
  admission: string;
  displayTarget: number;
  exposeSiteLanguage: string;
  docType: string;
  docTitle: string;
  docLanguages: string[];
  recentVersion: string;
  updateTime: string;
  lastModifierId: string;
  recentHistory: IHistory;

  writerId: string;
  writerNickname: string;

  searchExpose?: boolean;
  tags: string;
  description: string;
}

export interface ISsqDataDocumentAdminTableRowWithModifierName extends ISsqDataDocumentAdminTableRow {
  lastModifierName?: string;
  lastModifierNickname?: string;

  goodCount?: number;
  badCount?: number;
  qrtrDocPoint?: number;
  docPoint?: number;
  finished?: boolean;
  answerCnt?: number;
  employ?: boolean;

  lsRelated?: boolean;

  questionId?: number;
  userGoodCnt?: number;
  userBadCnt?: number;
  reportedCnt?: number;

  writerName?: string;
  registTime?: string;
  clickCount?: number;
  commentCnt?: number;
  commentList?: string[];
}

export const EmptySsqDataDocumentAdminTableRowWithModifierName: ISsqDataDocumentAdminTableRowWithModifierName = {
  id: 0,
  expose: false,
  admission: AdmissionType.NOT_APPROVED,
  displayTarget: TargetType.GUEST,
  exposeSiteLanguage: SiteLanguageType.KO_KR,
  docType: "",
  docTitle: "",
  docLanguages: [],
  recentVersion: "",
  updateTime: "",
  lastModifierId: "",
  lastModifierName: "",
  lastModifierNickname: "",

  writerId: "",
  writerNickname: "",
  writerName: "",
  recentHistory: emptyHistory,
  registTime: "",
  tags: "",
  description: ""
};

export interface ISsqDataDocumentAdminPopup {
  id: number;
  parentId: number;
  expose: boolean;
  admission: string;
  docType: string;
  exposeSiteLanguage: string;
  displayTarget: number;
  docTitle: string;
  description: string;
  categories: number[];
  versions: IVersion[];
  histories: IHistory[];
  writerId: string;
  temporaryDocumentId: number;
  temporaryId: number;
  inquiryCategory?: string;
  inquiryType?: string;

  // 보여주기용.
  goodCount?: number;
  badCount?: number;
  qrtrDocPoint?: number;
  docPoint?: number;
  finished?: boolean;
  answerCnt?: number;
  employ?: boolean;
  lsRelated: boolean;
  questionId?: number;
  userGoodCnt?: number;
  userBadCnt?: number;
  reportedCnt?: number;

  docSampleSmry?: string; // 문서 샘플 개요.
  imageUrl?: string;
  sampleVersion?: string;

  lastModifierId?: string;
  writerNickname?: string;
  updateTime?: string;
  registTime?: string;// 등록일시.
  linkUrl?: string;
  linkUrl1?: string;
  linkUrl2?: string;
  linkUrl3?: string;
  linkUrl4?: string;
}

export interface ISsqDataCreateDocumentRequest {
  id?: number;
  expose: boolean;
  docType: string;
  writerId?: string;
  writerNickname?: string;
  exposeSiteLanguage: string;
  displayTarget: number;
  docTitle: string;
  description: string;
  categories: number[];
  useVersion: boolean;
  parentId?: number;
  admission: string;

  goodCount?: number;
  badCount?: number;
  qrtrDocPoint?: number;
  docPoint?: number;
  finished?: boolean;
  answerCnt?: number;
  employ?: boolean;
  lsRelated: boolean;
  questionId?: number;
  userGoodCnt?: number;
  userBadCnt?: number;
  reportedCnt?: number;

  docSampleSmry?: string; // 문서 샘플 개요.
  imageUrl?: string;
  sampleVersion?: string;
  linkUrl?: string;
  linkUrl1?: string;
  linkUrl2?: string;
  linkUrl3?: string;
  linkUrl4?: string;
}

export interface ISsqDataCreatePartnerDoc {
  docId: number,
  closeDate: Date,
  dueDate: Date,
  inquiryCategory: string,
  inquiryType: string,
  mngrUserId: string,
  partnerDocStatus: string,
  partnerId: number,
  customerScore: number,
}

export interface IPartnerQnaClosedEmail {
  docTitle: string; //문서 제목
  documentUrl: string; //문서 URL
  lang: string; // 영문, 한글 구분 ( ko, en )
  userId: string; // 알람 사용자 ID
  mngrId: string[];  //매니저 id
}

export interface IPartnerQnaCustomerScoreEmail {
  docTitle: string; //문서 제목
  documentUrl: string; //문서 URL
  lang: string; // 영문, 한글 구분 ( ko, en )
  mngrId: string[];  //매니저 id
  customerScore: number;
}

export interface IPartnerQnaOpenEmail {
  docTitle: string; //문서 제목
  documentUrl: string; //문서 URL
  lang: string; // 영문, 한글 구분 ( ko, en )
  userId: string; // 작성자 id
  userNickName: string; //글 작성자 닉네임
  docType: string; //문서 타입
  partnerName: string; //파트너 명
  mngrId: string[]; //매니저 아이디
  mngrNickName: string; //매니저 닉네임
}

export interface IPartnerQnaModifyWriteEmail {
  docTitle: string; //문서 제목
  documentUrl: string; //문서 URL
  lang: string; // 영문, 한글 구분 ( ko, en )
  userId: string; // 작성자 id
  mngrId: string[]; //매니저 아이디
}

export interface IPartnerQnaModifyEmail {
  docTitle: string; //문서 제목
  documentUrl: string; //문서 URL
  lang: string; // 영문, 한글 구분 ( ko, en )
  userId: string; // 작성자 id
  userNickName: string; //글 작성자 닉네임
  docType: string; //문서 타입
  partnerName: string; //파트너 명
  mngrId: string[]; //매니저 아이디
  mngrNickName: string; //매니저 닉네임
  dueDate: Date;
  message: string[];
}


export interface ISsqDataCreatePartnerDocLog {
  docId: number,
  closeDate: Date,
  dueDate: Date,
  inquiryCategory: string,
  inquiryType: string,
  mngrUserId: string,
  partnerDocStatus: string,
  partnerId: number,
  customerScore: number,
  histories: IPartnerDocLog[]
}

export interface IPartnerDocLog {
  updateTime: Date,
  updateUserId: string,
  partnerLog: string,
}


export interface IVersionCreateRequest {
  id?: number;
  documentId: number;
  version: string;
  versionDescription: string;
  fileExtract: boolean;
  files: IFileCreateRequest[];
}

export interface IFileCreateRequest {
  id?: number;
  fileName: string;
  fileKey: string;
  blobUrlForLargeFile: string;
  fileLang: string;
  fileSize: number;
  fileType?: string;
  targetSoftwareType?: string;
  writerOnly: boolean;
}

export interface IHistoryCreateRequest {
  comment: string;
  documentId: number;
  nickname?: string;
  admissionType: string;
}

export interface IPartnerDocHistoryCreateRequest {
  docId: number;
  partnerLog: string;
  updateUserId: string;
}

export const emptySsqDataDocumentAdminPopup: ISsqDataDocumentAdminPopup = {
  id: 0,
  parentId: 0,
  expose: false,
  admission: AdmissionType.NOT_APPROVED,
  docType: DocType.MANUAL,
  exposeSiteLanguage: SiteLanguageType.KO_KR,
  displayTarget: 0,
  docTitle: "",
  description: "",
  categories: [-1],
  versions: [],
  histories: [],
  writerId: "",
  temporaryDocumentId: 0,
  temporaryId: 0,
  lsRelated: true,
};


export const emptyPartnerDocLogs: ISsqDataCreatePartnerDocLog = {
  partnerDocStatus: '',
  customerScore: 0,
  partnerId: 0,
  mngrUserId: '',
  inquiryType: '',
  inquiryCategory: '',
  dueDate: null,
  closeDate: null,
  docId: 0,
  histories: []
};

export const emptyPartnerDoc: ISsqDataCreatePartnerDoc = {
  partnerDocStatus: '',
  customerScore: 0,
  partnerId: 0,
  mngrUserId: '',
  inquiryType: '',
  inquiryCategory: '',
  dueDate: null,
  closeDate: null,
  docId: 0,
};

export const emptyPartnerDocLog: IPartnerDocLog = {
  updateUserId: '',
  partnerLog: '',
  updateTime: null
};


export const emptyTechnicalSsqDataDocumentAdminPopup: ISsqDataDocumentAdminPopup = {
  ...emptySsqDataDocumentAdminPopup,
  docType: DocType.APPLICATION_NOTE
};

export interface IAdminUser {
  id: string;
  name: string;
  department: string;
  email: string;
}

export interface AttachedFileBlob {
  fileName: string;
  blobUrlForLargeFile: string;
  fileKey: string;
}

export interface ITemporaryDataListResponse {
  list: ITemporaryListData[];
}

export interface ITemporaryListData {
  docTitle: string;
  updateTime: string;
  temporaryDocumentId: number;
  temporaryId: number;
}

export interface ITemporaryData {
  id: number;
  temporaryDocumentId: number;
  temporaryId: number;
  admission: string;
  categories: number[];
  displayTarget: number;
  docTitle: string;
  docType: string;
  expose: boolean;
  exposeSiteLanguage: string;
  histories: any[];
  updateTime: string;
  versions: any[];
  description?: string;
  lsRelated: boolean;
  inquiryType?: string;
  inquiryCategory?: string;
}

export interface ICreatePostFeedback {
  feedbackType: string;
  comment: string;
  feedbackWriterId: string;
  feedbackWriterNickname: string;
  documentId: number;
}

export interface ICreateReportComment {
  feedbackType: string;
  comment: string;
  feedbackWriterId: string;
  feedbackWriterNickname: string;
  documentId: number;
  docCommentId: number;
}

export interface ICreateReportPost {
  feedbackType: string;
  comment: string;
  feedbackWriterId: string;
  feedbackWriterNickname: string;
  documentId: number;
}

export interface IFeedbackCount {
  uncheckedCount: number;
  totalCount: number;
}

export const emptyFeedbackCount: IFeedbackCount = {
  uncheckedCount: 0,
  totalCount: 0
};

export interface IFeedbackWithDocumentInfo {
  feedbackId: number;
  feedbackType: string;
  comment: string;
  feedbackDate: Date;
  feedbackWriterId: string;
  feedbackWriterNickname: string;
  docTitle: string;

  docType?: string;
  questionId?: number;
  documentId: number;
  documentGoodCount: number;
  documentBadCount: number;
  documentWriterId: string;
  documentWriterNickname: string;
  documentUpdateTime: Date;

  docUserGoodCnt?: number;
  docUserBadCnt?: number;
  docReportedCnt?: number;

  docLang?: string;


  checked: boolean;
  checkerId: string;
  checkerNickname: string;
  checkDate: Date;
}

export const emptyFeedbackWithDocumentInfo: IFeedbackWithDocumentInfo = {
  feedbackId: 0,
  feedbackType: FeedbackType.GOOD,
  comment: "",
  docType: "",
  feedbackDate: new Date(),
  feedbackWriterId: "",
  feedbackWriterNickname: "",
  docTitle: "",
  documentId: 0,
  documentGoodCount: 0,
  documentBadCount: 0,
  documentWriterId: "",
  documentWriterNickname: "",
  documentUpdateTime: new Date(),
  checked: false,
  checkerId: "",
  checkerNickname: "",
  checkDate: new Date(),
};

export interface IFeedbackInfo {
  feedbackId: number;
  feedbackType: string;
  comment: string;
  feedbackDate: Date;
  feedbackWriterId: string;
  feedbackWriterNickname: string;
  documentId: number;
  checked: boolean;
  checkerId: string;
  checkerNickname: string;
  checkDate: Date;
}

export const emptyFeedbackInfo: IFeedbackInfo = {
  feedbackId: 0,
  feedbackType: FeedbackType.GOOD,
  comment: "",
  feedbackDate: new Date(),
  feedbackWriterId: "",
  feedbackWriterNickname: "",
  documentId: 0,
  checked: false,
  checkerId: "",
  checkerNickname: "",
  checkDate: new Date()
};

export interface IFeedbackSearchRequest {
  pageNum: number,
  userId: string,
  keyword?: string,
  isOnlyMyDocument?: boolean,
  exposeLanguage: string,
  langs: string [];
  feedbackType: string;
  isEmptyTitle?: boolean;
  docType: string [];
}

export const emptyFeedbackSearchRequest = {
  pageNum: 0,
  userId: "",
  keyword: "",
  isOnlyMyDocument: false,
  exposeLanguage: "",
  langs: [],
  feedbackType: "",
  isEmptyTitle: false,
  docType: [],
};

export interface ILogSearchRequest {
  searchValue: string;
  pageNum: number;
  userId: string,
  logContent: string,
  nickname: string,
  startDate: Date;
  endDate: Date;
  menuType: string[];
  checked: boolean;
  actionType: string[];
}

export const emptyLogSearchRequest = {
  searchValue: "",
  pageNum: 0,
  userId: "",
  nickname: "",
  logContent: "",
  startDate: null,
  endDate: null,
  checked: false,
  menuType: [],
  actionType: [],
};

export interface ILogCount {
  totalCount: number;
}

export const emptyLogCount: ILogCount = {
  totalCount: 0,
};

export interface IPatchFeedbackCheckRequest {
  feedbackIds: number[],
  checkerId: string
  checkerNickname: string
}

export const emptyPatchFeedbackCheckRequest: IPatchFeedbackCheckRequest = {
  feedbackIds: [],
  checkerId: "",
  checkerNickname: ""
};

export interface IDocGoodBadInfo {
  docId: number;
  userId: string;
  docType: string;
  docTitle: string;
  goodBad: string;
  updtDt: Date;
}

export const emptyIDocGoodBadInfo: IDocGoodBadInfo = {
  docId: 0,
  userId: "",
  docType: "",
  docTitle: "",
  goodBad: "",
  updtDt: new Date(0)
};

export interface IMyCheckDocGoodBad {
  docId: number;
  userId: string;
}

export interface IDocGoodBadCount {
  goodCount: number;
  badCount: number;
}

export const emptyIDocGoodBadCount: IDocGoodBadCount = {
  goodCount: 0,
  badCount: 0
};

export interface ICreateUserAlarmRequest {
  userId: string;
  documentId: number;
  parentDocumentId: number | undefined;
  docType: string;
  admission: string;
  docTitle: string;
  updateTime: Date;
}

export interface IFindUserAlarmRequest {
  pageNum: number;
  userId: string;
}

export const emptyFindUserAlarmRequest: IFindUserAlarmRequest = {
  pageNum: 0,
  userId: "set userId in BFF"
};

export interface ICountUserAlarmRequest {
  userId: string;
}

export const emptyCountUserAlarmRequest: ICountUserAlarmRequest = {
  userId: "set userId in BFF"
};

export interface IUserAlarmItem {
  userAlarmId: number;
  userId: string;
  documentId: number;
  parentDocumentId: number;
  docType: string;
  admission: string;
  docTitle: string;
  updateTime: Date;
  checkedAlarm: boolean;
}

export interface ICreateUserShareRequest {
  userId: string;
  documentId: number;
  docType: string;
  docTitle: string;
  updateTime: Date;
}

export interface IFindUserShareRequest {
  pageNum: number;
  userId: string;
}

export const emptyFindUserShareRequest: IFindUserShareRequest = {
  pageNum: 0,
  userId: "set userId in BFF"
};

export interface ICountUserShareRequest {
  userId: string;
}

export const emptyCountUserShareRequest: ICountUserShareRequest = {
  userId: "set userId in BFF"
};

export interface IUserShareItem {
  userShareId: number;
  userId: string;
  documentId: number;
  docType: string;
  docTitle: string;
  updateTime: Date;
}

export interface ICreateUserDownloadRequest {
  userId: string;
  documentId: number;
  docTitle: string;
  docType: string;
  downloadType: string;
  versionName: string;
  fileName: string;
  fileKey: string;
  blobUrlForLargeFile: string;
  updateTime: Date;
}

export const emptyCreateUserDownloadRequest: ICreateUserDownloadRequest = {
  userId: "set userId in BFF",
  documentId: 0,
  docTitle: "",
  docType: "",
  downloadType: DownloadType.FILE,
  versionName: "",
  fileKey: "",
  blobUrlForLargeFile: "",
  fileName: "",
  updateTime: new Date()
};

export interface IFindUserDownloadRequest {
  pageNum: number;
  userId: string;
}

export const emptyFindUserDownloadRequest: IFindUserDownloadRequest = {
  pageNum: 0,
  userId: "set userId in BFF"
};

export interface ICountUserDownloadRequest {
  userId: string;
}

export const emptyCountUserDownloadRequest: ICountUserDownloadRequest = {
  userId: "set userId in BFF"
};

export interface IUserDownloadItem {
  userDownloadId: number;
  userId: string;
  documentId: number;
  docTitle: string;
  docType: string;
  downloadType: string;
  versionName: string;
  fileName: string;
  blobUrlForLargeFile: string;
  fileKey: string;
  updateTime: Date;
}

export const LEVEL_SELECT = -1; // 이전의 ALL_SELECT.
export const ALL_SELECT = -2; // 전체선택을 위한 메뉴.

export interface IDeleteMyPageInfoRequest {
  userId: String;
}

export interface ISaveTemporaryDataResponse {
  temporaryId: number,
  httpStatus: number
}

export const emptySaveTemporaryDataResponse: ISaveTemporaryDataResponse = {
  temporaryId: 0,
  httpStatus: HttpStatus.NOT_FOUND
};

export interface ILogManagementItem {
  id: number,
  userId: string,
  userName: string,
  nickname: string,
  menuType: string,
  actionType: string,
  logContent: string,
  updateTime: Date,
  logContertClick: boolean;
}

export const emptyLogManagementItem: ILogManagementItem = {
  id: 0,
  userId: "",
  userName: "",
  nickname: "",
  menuType: "",
  actionType: "",
  logContent: "",
  updateTime: new Date(),
  logContertClick: false
};

export interface ICreateLogItem {
  userId: string;
  userName: string;
  nickName: string;
  menuType: string;
  actionType: string;
  logContent: string;
  updateTime: Date;
}

export interface IEventDocument {
  id: number
  docTitle: string,
  description: string,
  writerId: string,
  writerName: string,
  writerNickname: string,
  updateTime: Date
}

export interface IEventDocumentCreateRequest {
  id?: number,
  docTitle: string,
  description: string,
  writerId: string,
  writerName: string,
  writerNickname: string
  updateTime: Date
}

export interface IEventDocumentFileCreateRequest {
  id?: number,
  eventDocumentId: number,
  files: IFileCreateRequest[],
}

export interface IEventDocumentDetail {
  id: number
  docTitle: string,
  description: string,
  writerId: string,
  writerName: string,
  writerNickname: string,
  files: IFile[],
  updateTime: Date
}

export interface ICommentFilterItem {
  writerId: string;
  isEmploy: boolean;
  isMyPage: boolean;
  pageNum: number;
  docTypes: Array<String>;
  isPartner: boolean;
}

export const EmptyCommentFilterItemAll: ICommentFilterItem = {
  writerId: "",
  isEmploy: false,
  isMyPage: false,
  pageNum: 0,
  docTypes: [],
  isPartner: false,
};

export interface ILectureFilterItem {
  writerId: string;
  isEmploy: boolean;
  isAdmin: boolean;
  timeToday: Date;
  lang: string;
  exceptId: string;
  timezone: number;
}

export const EmptyLectureFilterItemAll: ILectureFilterItem = {
  writerId: "",
  isEmploy: false,
  isAdmin: false,
  timeToday: new Date(),
  lang: "",
  exceptId: "",
  timezone: 0
};


export interface ICommunityFilterItem {
  sortType: number;
  docTypes: Array<String>;
  categoryId: number,
  finish?: String | null,
  period: number,
  siteLanguage: string,
  pageNum: number,
  tags: String[],
  keyword: string,
  writerId?: string,
  bookmark?: boolean,
  bookmarkUserId?: string, // 북마크 조회사 사용되는 userid
  goodbad?: boolean, //  좋아요 검색.
  goodbadUserId?: string,
  isEmploy?: boolean,
  isMyPage?: boolean,

  fileType?: string,
  softwareType?: string,
  admission?: string,
  lastModifyId?: string,
  isPartner?: boolean
  userId?: string;
}

export const EmptyCommunityFilterItem: ICommunityFilterItem = {
  sortType: 0,
  docTypes: [""],
  categoryId: 0,
  finish: "DEFAULT",
  period: 0,
  siteLanguage: 'ko',
  pageNum: 0,
  tags: [],
  keyword: '',
  writerId: null,
  bookmark: false, // 북마크 검색. 
  bookmarkUserId: null,
  goodbad: false,
  goodbadUserId: null,
  fileType: null,
  softwareType: null,
  admission: '',
  lastModifyId: '',
};

export const EmptyCommunityFilterAll: ICommunityFilterItem = {
  sortType: 0,
  docTypes: [],
  categoryId: 0,
  finish: "DEFAULT",
  period: 0,
  siteLanguage: '',
  pageNum: 0,
  tags: [],
  keyword: '',
  writerId: null,
  bookmark: false, // 북마크 검색.
  bookmarkUserId: null,
  goodbad: false,
  goodbadUserId: null,

  fileType: '',
  softwareType: '',
  admission: '',
  lastModifyId: '',
};

export const EmptyEventDocumentDetail: IEventDocumentDetail = {
  id: 0,
  docTitle: "",
  description: "",
  writerId: "",
  writerName: "",
  writerNickname: "",
  files: [],
  updateTime: new Date(),
};

export interface ICommunityComment {
  docCommentId: number;
  commentCnts: string;
  docId: number;
  employ: boolean;
  updtDt: Date;
  writerId: string;
  writerNcnm: string;
  writerNm: string;
}

export const EmptyCommunityComment: ICommunityComment = {
  docCommentId: 0,
  commentCnts: '',
  docId: 0,
  employ: false,
  updtDt: new Date(),
  writerId: '',
  writerNcnm: '',
  writerNm: '',
};

export interface ISwResponse {
  total: number;
  swList: ISwDetail[];
}

export interface ISwDetail {
  swId: number;
  swType: string;
  swVerNm: string;
  swNm: string;
  lastModifierId: string;
  updtDt: Date;
}

export interface ISwDetailWithVersion {
  swId: number;
  swType: string;
  swNm: string;
  description: string;
  sendEmailList: string;
  swVerList: ISwVersion[];
}

export interface ISwSrchParam {
  title: string;
  swType: string;
}


export interface ISwVersion {
  swVerId?: number;
  versionNm: string;
  file: any;
  meta: any;
  data: any;
  revisionNumber: number;
  pinStat: boolean;
  updtDt?: Date;
  lastModifierId: string;
  description: string;
}

export interface ISwCreateRequest {
  id?: number;
  modifier: string;
  swNm: string;
  swType: string;
  versions: ISwVersion[];
  description: string;
  sendEmailList: string;
}

export interface IApiError {
  timestamp: string;
  status: number;
  error: string;
  trace: string;
  message: string;
  path: string;
}

export interface SsqDataRepository {
  getSsqDataByCategoryIdByPage(categoryId: number, docType: string, siteLanguage: string, pageNum: number): Promise<ISsqDataDocument[]>;

  getCountsOfEachDocType(countOfEachDocTypeRequest: ICountOfEachDocTypeRequest): Promise<ICountsOfEachDocType>;

  getSsqDataByPage(getSsqDataByPageRequest: IGetSsqDataByPageRequest): Promise<ISsqDataDocument[]>;

  getSsqDataByDocumentId(documentId: number): Promise<ISsqDataDocument>;

  downloadZipFile(files: IFile[]): Promise<any>;

  addClickCount(documentId: number): Promise<IAddClickCountResponse>;

  addDownloadCount(fileIds: number[]): Promise<number>;

  getCountOfSsqDataByFilter(communityFilterItem: ICommunityFilterItem): Promise<number>;

  getCountOfSsqDataCommentByFilter(commentFilterItem: ICommentFilterItem): Promise<number>;

  getSsqDataByFilter(communityFilterItem: ICommunityFilterItem): Promise<ISsqDataDocument[]>;

  getLectureByFilter(lectureFilterItem: ILectureFilterItem): Promise<ISsqLectureList[]>;

  getLectrueAlarmMainByFilter(lectureFilterItem: ILectureFilterItem): Promise<ISsqLectureList[]>;

  getSsqDataCommentByFilter(commentFilterItem: ICommentFilterItem): Promise<ISsqDataDocComment[]>;

  getCountOfFilteredDocuments(documentSearchParameter: IDocumentSearchParameter): Promise<number>;

  getFilteredDocumentsByPage(documentSearchParameter: IDocumentSearchParameter): Promise<ISsqDataDocumentAdminTableRow[]>;

  getSsqDataDocumentAdmin(documentId: number): Promise<ISsqDataDocumentAdminPopup>;

  postDocument(request: ISsqDataCreateDocumentRequest): Promise<number>;

  postVersion(request: IVersionCreateRequest): Promise<number>;

  postDocumentHistory(request: IHistoryCreateRequest): Promise<number>;

  putDocumentAdmission(documentIds: number[], admission: string): Promise<number[]>;

  putTechDocumentAdmission(documentIds: number[], admission: string): Promise<number[]>;

  getManageAdminUsers(): Promise<IAdminUser[]>;

  getApprovalAdminUsers(): Promise<IAdminUser[]>;

  getTechApprovalAdminUsers(): Promise<IAdminUser[]>;

  postFileInBlob(key: string, files: any[]): Promise<AttachedFileBlob[]>;

  postFileInBlob1(key: string, files: any[]): Promise<AttachedFileBlob[]>;

  deleteFileInBlob(key: string): Promise<number>;

  deleteDocument(documentId: number): Promise<number>;

  deleteCommunityDocument(documentId: number): Promise<number>;

  deleteCommunityDocumentAxios(documentId: number): Promise<AxiosResponse<any, any>>;

  saveTemporaryData(jsonData: string, documentId: number | null, tempDataId: number | null, viewType: string): Promise<ISaveTemporaryDataResponse>;

  getTemporaryData(tempDataId: number | null, documentId: number | null): Promise<ITemporaryData>;

  getTemporaryDataList(viewType: string): Promise<ITemporaryListData[]>;

  deleteTemporaryData(tempDataId: number, documentId: number): Promise<number>;

  postFeedback(createPostFeedback: ICreatePostFeedback): Promise<number>;

  postReportComment(createReportComment: ICreateReportComment): Promise<number>;

  postReportPost(createReportPost: ICreateReportPost): Promise<number>;

  getCountAllFeedbacks(): Promise<IFeedbackCount>;

  getFeedbacksByPage(pageNum: number): Promise<IFeedbackWithDocumentInfo[]>;

  getCountFeedbacksByDocumentWriterId(feedbackSearchRequest: IFeedbackSearchRequest): Promise<IFeedbackCount>;

  getFeedbacksByDocumentWriterId(feedbackSearchRequest: IFeedbackSearchRequest): Promise<IFeedbackWithDocumentInfo[]>;

  getFeedbacksByDocumentId(documentId: number): Promise<IFeedbackInfo[]>;

  patchFeedbacksCheck(patchFeedbackCheckRequest: IPatchFeedbackCheckRequest): Promise<number>;

  postUserAlarm(createUserAlarmRequest: ICreateUserAlarmRequest): Promise<number>;

  patchUserAlarmCheckedAlarm(userAlarmId: number): Promise<number>;

  postFindUserAlarm(findUserAlarmRequest: IFindUserAlarmRequest): Promise<IUserAlarmItem[]>;

  postCountUserAlarm(countUserAlarmRequest: ICountUserAlarmRequest): Promise<number>;

  postCountNewUserAlarm(countUserAlarmRequest: ICountUserAlarmRequest): Promise<number>;

  deleteUserAlarmByUserAlarmId(userAlarmId: number): Promise<number>;

  postUserShare(createUserShareRequest: ICreateUserShareRequest): Promise<number>;

  postFindUserShare(findUserShareRequest: IFindUserShareRequest): Promise<IUserShareItem[]>;

  postCountUserShare(countUserShareRequest: ICountUserShareRequest): Promise<number>;

  deleteUserShareByUserShareId(userShareId: number): Promise<number>;

  postUserDownload(createUserDownloadRequest: ICreateUserDownloadRequest): Promise<number>;

  postFindUserDownload(findUserDownloadRequest: IFindUserDownloadRequest): Promise<IUserDownloadItem[]>;

  postCountUserDownload(countUserDownloadRequest: ICountUserDownloadRequest): Promise<number>;

  deleteUserDownloadByUserDownloadId(userDownloadId: number): Promise<number>;

  getVersionByDocumentIdAndVersionName(documentId: number, versionName: string): Promise<IVersion>;

  checkExistBlobFile(fileKey: string, fileName: string): Promise<boolean>;

  checkExistBlobLargeFile(blobUrlForLargeFile: string): Promise<boolean>;

  deleteMyPageInfoByUserId(deleteMyPageInfoRequest: IDeleteMyPageInfoRequest): Promise<number>;

  deleteUserInfoByUserId(deleteMyPageInfoRequest: IDeleteMyPageInfoRequest): Promise<number>;

  getLogManagementItemList(pageNum: number): Promise<ILogManagementItem[]>;

  getLogManagementItemSearch(searchValue: ILogSearchRequest): Promise<ILogManagementItem[]>;

  getLogManagementItemCount(logSrchParam: ILogSearchRequest): Promise<ILogCount>;

  getCountLogManagementItem(): Promise<number>;

  postLogItem(postLogManagementItem: ICreateLogItem): Promise<number>;

  getEventDocuments(): Promise<IEventDocument[]>;

  getEventAllDocuments(): Promise<IEventDocument[]>;

  getEventDocumentDetail(documentId: number): Promise<IEventDocumentDetail>;

  postEventDocument(eventDocumentCreateRequest: IEventDocumentCreateRequest): Promise<number>;

  deleteEventDocument(documentId: number): Promise<number>;

  postEventDocumentFiles(eventDocumentFileCreateRequest: IEventDocumentFileCreateRequest): Promise<number>;

  getSoftware(getSwRequest: ISwSrchParam, page: number): Promise<ISwResponse>;

  postSoftware(swCreateRequest: ISwCreateRequest, uploadProgress?: Function): Promise<number>;

  getSoftwareDetail(id: number): Promise<ISwDetailWithVersion>;

  deleteSoftware(swId: number): Promise<number>;

  isDistinctSwNm(swId: number, swNm: string): Promise<boolean>;
}

export class NetworkSsqDataRepository implements SsqDataRepository {
  http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  async getSsqDataByCategoryIdByPage(
    categoryId: number, docType: string, siteLanguage: string, pageNum: number
  ): Promise<ISsqDataDocument[]> {
    const response = await this.http.get(
      `/api/guest/ssqData/page?` +
      `categoryId=${categoryId}&docType=${docType}&siteLanguage=${siteLanguage}&pageNum=${pageNum}`
    );
    return response.json();
  }

  async getCountsOfEachDocType(countOfEachDocTypeRequest: ICountOfEachDocTypeRequest): Promise<ICountsOfEachDocType> {
    console.log(countOfEachDocTypeRequest);
    const response = await this.http.post(
      `/api/guest/ssqData/count`,
      countOfEachDocTypeRequest
    );
    return response.json();
  }

  async getSsqDataByPage(getSsqDataByPageRequest: IGetSsqDataByPageRequest): Promise<ISsqDataDocument[]> {
    const response = await this.http.post(
      `/api/guest/ssqData/page`,
      getSsqDataByPageRequest
    );
    return response.json();
  }

  async downloadZipFile(files: IFile[]): Promise<any> {
    const response = await this.http.post(
      `/uploads/zip`,
      files
    );

    if (response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
      return undefined;
    }

    return response.blob && await response.blob() || undefined;
  }

  async addClickCount(documentId: number): Promise<IAddClickCountResponse> {
    const response = await this.http.patch(`/api/guest/ssqData/document/addClickCount?documentId=${documentId}`);
    return response.json();
  }

  async addDownloadCount(fileIds: number[]): Promise<number> {
    const response = await this.http.patch(`/api/guest/ssqData/document/addDownloadCount?fileIds=${fileIds}`);
    return response.status;
  }

  async getCountOfSsqDataByFilter(communityFilterItem: ICommunityFilterItem) {
    const response = await axios.post('/api/guest/ssqData/document/community/filter/count', communityFilterItem);
    return response.data;
  }

  async getSsqDataByFilter(communityFilterItem: ICommunityFilterItem) {
    const response = await axios.post('/api/guest/ssqData/document/community/filter', communityFilterItem);
    return response.data;
  }

  async getLectureByFilter(lectureFilterItem: ILectureFilterItem) {
    const response = await axios.post('/api/guest/ssqData/lecture/filter', lectureFilterItem, {});
    console.log("LECTURE : ", response.data);
    return response.data;
  }

  async getLectrueAlarmMainByFilter(lectureFilterItem: ILectureFilterItem) {
    const response = await axios.post(`/api/guest/ssqData/lecture/main/filter`, lectureFilterItem, {});
    return response.data;
  }


  async getSsqDataCommentByFilter(commentFilterItem: ICommentFilterItem) {
    const response = await axios.post('/api/guest/ssqData/doccomment/profile/filter', commentFilterItem, {});
    return response.data;
  }

  async getCountOfSsqDataCommentByFilter(commentFilterItem: ICommentFilterItem) {
    const response = await axios.post('/api/guest/ssqData/doccomment/profile/filter/count', commentFilterItem);
    return response.data;
  }

  async getCountOfFilteredDocuments(documentSearchParameter: IDocumentSearchParameter): Promise<number> {
    const response = await this.http.post(
      '/api/boris/ssqData/settings/document/count',
      documentSearchParameter
    );
    if (response.status === HttpStatus.NOT_FOUND) {
      return Promise.resolve(0);
    }

    return response.json();

  }

  async getFilteredDocumentsByPage(documentSearchParameter: IDocumentSearchParameter): Promise<ISsqDataDocumentAdminTableRow[]> {
    const response = await this.http.post(
      '/api/boris/ssqData/settings/document/page',
      {...documentSearchParameter, queryAll: documentSearchParameter.queryAll ?? false}
    );
    if (response.status === HttpStatus.NOT_FOUND) {
      return Promise.resolve([]);
    }

    return response.json();
  }

  async getSsqDataDocumentAdmin(documentId: number): Promise<ISsqDataDocumentAdminPopup> {
    const response = await this.http.get(`/api/boris/ssqData/document/${documentId}`);
    return response.json();
  }

  async getSsqDataDocumentMember(documentId: number): Promise<ISsqDataDocumentAdminPopup> {
    const response = await this.http.get(`/api/member/ssqData/document/${documentId}`);
    return response.json();
  }


  async getManageAdminUsers(): Promise<IAdminUser[]> {
    const response = await this.http.get(`/api/boris/adminManageUsers`);
    return response.json();
  }

  async getApprovalAdminUsers(): Promise<IAdminUser[]> {
    const response = await this.http.get(`/api/boris/approvalUsers`);
    return response.json();
  }

  async getTechApprovalAdminUsers(): Promise<IAdminUser[]> {
    const response = await this.http.get(`/api/boris/techApprovalUsers`);
    return response.json();
  }

  async getGuestTechApprAdminUsers(): Promise<IAdminUser[]> {
    const response = await this.http.get(`/api/guest/techApprovalUsers`);
    return response.json();
  }

  async postFileInBlob(key: string, files: any[]): Promise<AttachedFileBlob[]> {
    const formData = new FormData();
    Array.from(files).forEach(file => formData.append("uploadFiles", file));
    const response = await this.http.postFormData("/api/member/uploads/" + key, formData);

    if (response.status !== HttpStatus.OK) {
      return Promise.resolve([]);
    } else {
      return response.json();
    }
  }

  async postFileInBlob1(key: string, files: any[]): Promise<AttachedFileBlob[]> {
    const formData = new FormData();
    files.forEach(file => formData.append("uploadFiles", file));
    const response = await this.http.postFormData("/api/member/upload1/" + key, formData);

    if (response.status !== HttpStatus.OK) {
      return Promise.resolve([]);
    } else {
      return response.json();
    }
  }

  async deleteFileInBlob(key: string): Promise<number> {
    return (await this.http.delete("/api/boris/uploads/" + key)).status;
  }

  //TODO: change API authority
  async postDocument(request: ISsqDataCreateDocumentRequest): Promise<number> {
    const response = await this.http.post(`/api/boris/ssqData/document/new`, request);
    return response.json(); //return new document Id
  }

  async postGuestDoc(request: ISsqDataCreateDocumentRequest): Promise<number> {
    const response = await this.http.post(`/api/member/ssqData/document/new`, request);
    return response.json(); //return new document Id
  }

  async postVersion(request: IVersionCreateRequest): Promise<number> {
    const response = await this.http.post(`/api/boris/ssqData/document/version/new`, request);
    return response.json();
  }

  async postGuestVersion(request: IVersionCreateRequest): Promise<number> {
    const response = await this.http.post(`/api/guest/ssqData/document/version/new`, request);
    return response.json();
  }

  async putDocumentAdmission(documentIds: number[], admission: string, nohist?: boolean): Promise<number[]> {
    const response = await this.http.put(`/api/boris/ssqData/document/admission?documentIds=${documentIds}&admission=${admission}&nohist=${nohist ?? false}`);
    if (response.status === HttpStatus.NOT_ACCEPTABLE) {
      //TODO: 권한이 없음
    }
    return response.json();
  }

  async putTechDocumentAdmission(documentIds: number[], admission: string, nohist?: boolean): Promise<number[]> {
    const response = await this.http.put(`/api/boris/ssqData/tech/document/admission?documentIds=${documentIds}&admission=${admission}&nohist=${nohist ?? false}`);
    if (response.status === HttpStatus.NOT_ACCEPTABLE) {
      //TODO: 권한이 없음
    }
    return response.json();
  }

  async postDocumentHistory(request: IHistoryCreateRequest): Promise<number> {
    const response = await this.http.post(`/api/boris/ssqData/document/history`, request);
    return response.status;
  }

  async postGeustDocHistory(request: IHistoryCreateRequest): Promise<number> {
    const response = await this.http.post(`/api/guest/ssqData/document/history`, request);
    return response.status;
  }

  async getSsqDataByDocumentId(documentId: number): Promise<ISsqDataDocument> {
    const response = await this.http.get(`/api/guest/ssqData/document/${documentId}`);
    if (response.status !== HttpStatus.OK) {
      return emptySsqDataDocument;
    }
    return response.json();
  }

  async deleteDocument(documentId: number): Promise<number> {
    const response = await this.http.delete(`/api/boris/ssqData/document/delete/${documentId}`);
    return response.status;
  }

  async deleteLectrue(lectureId: number): Promise<number> {
    const response = await this.http.delete(`/api/guest/ssqData/lecture/${lectureId}`);
    return response.status;
  }

  async deleteCommunityDocument(documentId: number): Promise<number> {
    const response = await this.http.delete(`/api/guest/ssqData/document/delete/${documentId}`);
    return response.status;
  }

  async deleteCommunityDocumentAxios(documentId: number): Promise<AxiosResponse<any, any>> {
    // const response = await this.http.delete(`/api/guest/ssqData/document/delete/${documentId}`);
    // return response.status;
    return (await axios.delete(`/api/guest/ssqData/document/delete/${documentId}`)).data;
  }

  async saveTemporaryData(jsonData: string, documentId: number | null, tempDataId: number | null, viewType: string): Promise<ISaveTemporaryDataResponse> {
    let requestUrl = `/api/boris/ssqData/document/temporary?viewType=${viewType}&`;
    if (!!tempDataId && !!documentId) {
      requestUrl += `id=${tempDataId}&documentId=${documentId}`;
    } else {
      requestUrl = requestUrl +
        `${tempDataId && `tempDataId=${tempDataId}` || ""}` +
        `${documentId && `documentId=${documentId}` || ""}`;
    }
    const response = await this.http.post(
      requestUrl,
      {data: jsonData}
    );

    const saveTemporaryDataResponse: ISaveTemporaryDataResponse = {
      httpStatus: response.status,
      temporaryId: await response.json().then(res => res)
    };

    return saveTemporaryDataResponse;
  }

  // 저장시, id가 있는 경우, 새로운 데이터가 저장되지 않도록.
  async saveTemporaryData2(jsonData: string, documentId: number | null, tempDataId: number | null, viewType: string): Promise<ISaveTemporaryDataResponse> {
    let requestUrl = `/api/member/ssqData/document/temporary?viewType=${viewType}&`;
    requestUrl += `id=${tempDataId || ''}&documentId=${documentId}`;
    const res = await this.http.post(
      requestUrl,
      {data: jsonData}
    );

    const saveTempRes: ISaveTemporaryDataResponse = {
      httpStatus: res.status,
      temporaryId: await res.json().then(res => res)
    };

    return saveTempRes;
  }

  async getTemporaryData(tempDataId: number | null, documentId: number | null): Promise<any> {
    let requestUrl = `/api/member/ssqData/document/temporary?`;
    if (!!tempDataId && !!documentId) {
      requestUrl += `id=${tempDataId}&documentId=${documentId}`;
    } else {
      requestUrl = requestUrl +
        `${tempDataId && `id=${tempDataId}` || ""}` +
        `${documentId && `documentId=${documentId}` || ""}`;
    }
    const response = await this.http.get(requestUrl);
    return response.json().then(res => JSON.parse(res.data));
  }

  async getTemporaryDataList(viewType: string): Promise<ITemporaryListData[]> {
    const response = await this.http.get(`/api/boris/ssqData/document/temporary/list?viewType=${viewType}`);
    return response.json().then(res => res.list);
  }

  async getGuestTempDataList(viewType: string): Promise<ITemporaryListData[]> {
    const response = await this.http.get(`/api/guest/ssqData/document/temporary/list?viewType=${viewType}`);
    return response.json().then(res => res.list);
  }

  async deleteTemporaryData(tempDataId: number, documentId: number): Promise<number> {
    const response = await this.http.delete(`/api/member/ssqData/document/temporary/delete/${tempDataId}/${documentId}`);
    return response.status;
  }

  async postFeedback(createPostFeedback: ICreatePostFeedback): Promise<number> {
    const response = await this.http.post(`/api/guest/ssqData/document/feedback`, createPostFeedback);
    return response.status;
  }

  async postReportComment(createReportComment: ICreateReportComment): Promise<number> {
    const response = await this.http.post(`/api/guest/ssqData/document/comment/report`, createReportComment);
    return response.status;
  }

  async postReportPost(createReportPost: ICreateReportPost): Promise<number> {
    const response = await this.http.post(`/api/guest/ssqData/document/report`, createReportPost);
    return response.status;
  }

  async getCountAllFeedbacks(): Promise<IFeedbackCount> {
    const response = await this.http.get(`/api/boris/ssqData/document/feedback/count`);
    return response.json();
  }

  async getFeedbacksByPage(pageNum: number): Promise<IFeedbackWithDocumentInfo[]> {
    const response = await this.http.post(`/api/boris/ssqData/document/feedback/page?pageNum=${pageNum}`);
    return response.json();
  }

  async getCountFeedbacksByDocumentWriterId(feedbackSearchRequest: IFeedbackSearchRequest): Promise<IFeedbackCount> {
    const response = await this.http.post(`/api/boris/ssqData/document/feedback/myDocument/count`, feedbackSearchRequest);
    return response.json();
  }

  async getFeedbacksByDocumentWriterId(feedbackSearchRequest: IFeedbackSearchRequest): Promise<IFeedbackWithDocumentInfo[]> {
    const response = await this.http.post(`/api/boris/ssqData/document/feedback/myDocument/page`, feedbackSearchRequest);
    return response.json();
  }

  async getFeedbacksByDocumentId(documentId: number): Promise<IFeedbackInfo[]> {
    const response = await this.http.get(`/api/boris/ssqData/document/feedback?documentId=${documentId}`);

    if (response.status !== HttpStatus.OK) {
      return Promise.resolve([]);
    } else {
      return response.json();
    }
  }

  async patchFeedbacksCheck(patchFeedbackCheckRequest: IPatchFeedbackCheckRequest): Promise<number> {
    const response = await this.http.patch(`/api/boris/ssqData/document/feedback/check`, patchFeedbackCheckRequest);
    return response.status;
  }

  async postUserAlarm(createUserAlarmRequest: ICreateUserAlarmRequest): Promise<number> {
    const response = await this.http.post(`/api/boris/ssqData/user/alarm/create`, createUserAlarmRequest);
    return response.status;
  }

  async postGuestUserAlarm(createUserAlarmRequest: ICreateUserAlarmRequest): Promise<number> {
    const response = await this.http.post(`/api/guest/ssqData/user/alarm/create`, createUserAlarmRequest);
    return response.status;
  }

  async patchUserAlarmCheckedAlarm(userAlarmId: number): Promise<number> {
    const response = await this.http.patch(`/api/member/ssqData/user/alarm/checkedAlarm/${userAlarmId}`);
    return response.json();
  }

  async postFindUserAlarm(findUserAlarmRequest: IFindUserAlarmRequest): Promise<IUserAlarmItem[]> {
    const response = await this.http.post(`/api/member/ssqData/user/alarm`, findUserAlarmRequest);
    return response.json();
  }

  async postCountUserAlarm(countUserAlarmRequest: ICountUserAlarmRequest): Promise<number> {
    const response = await this.http.post(`/api/member/ssqData/user/alarm/count`, countUserAlarmRequest);
    return response.json();
  }

  async postCountNewUserAlarm(countUserAlarmRequest: ICountUserAlarmRequest): Promise<number> {
    const response = await this.http.post(`/api/member/ssqData/user/alarm/count/new`, countUserAlarmRequest);
    return response.json();
  }

  async deleteUserAlarmByUserAlarmId(userAlarmId: number): Promise<number> {
    const response = await this.http.delete(`/api/member/ssqData/user/alarm/${userAlarmId}`);
    return response.json();
  }

  async postUserShare(createUserShareRequest: ICreateUserShareRequest): Promise<number> {
    const response = await this.http.post(`/api/guest/ssqData/user/share/create`, createUserShareRequest);
    return response.status;
  }

  async postFindUserShare(findUserShareRequest: IFindUserShareRequest): Promise<IUserShareItem[]> {
    const response = await this.http.post(`/api/member/ssqData/user/share`, findUserShareRequest);
    return response.json();
  }

  async postCountUserShare(countUserShareRequest: ICountUserShareRequest): Promise<number> {
    const response = await this.http.post(`/api/member/ssqData/user/share/count`, countUserShareRequest);
    return response.json();
  }

  async deleteUserShareByUserShareId(userShareId: number): Promise<number> {
    const response = await this.http.delete(`/api/member/ssqData/user/share/${userShareId}`);
    return response.json();
  }

  async postUserDownload(createUserDownloadRequest: ICreateUserDownloadRequest): Promise<number> {
    const response = await this.http.post(`/api/guest/ssqData/user/download/create`, createUserDownloadRequest);
    return response.status;
  }

  async postFindUserDownload(findUserDownloadRequest: IFindUserDownloadRequest): Promise<IUserDownloadItem[]> {
    const response = await this.http.post(`/api/member/ssqData/user/download`, findUserDownloadRequest);
    return response.json();
  }

  async postCountUserDownload(countUserDownloadRequest: ICountUserDownloadRequest): Promise<number> {
    const response = await this.http.post(`/api/member/ssqData/user/download/count`, countUserDownloadRequest);
    return response.json();
  }

  async deleteUserDownloadByUserDownloadId(userDownloadId: number): Promise<number> {
    const response = await this.http.delete(`/api/member/ssqData/user/download/${userDownloadId}`);
    return response.json();
  }

  async getVersionByDocumentIdAndVersionName(documentId: number, versionName: string): Promise<IVersion> {
    const response = await this.http.get(`/api/member/ssqData/user/download/version?documentId=${documentId}&versionName=${versionName}`);
    return response.json();
  }

  async checkExistBlobFile(fileKey: string, fileName: string): Promise<boolean> {
    const response = await this.http.get(`/uploads/${fileKey}/${encodeURIComponent(getTextFromHtml(fileName))}`);
    if (response.status === HttpStatus.NOT_FOUND) {
      return false;
    }
    return true;
  }

  async checkExistBlobLargeFile(blobUrlForLargeFile: string): Promise<boolean> {
    let response = await this.http.head(`${blobUrlForLargeFile}`);

    if (response.status === HttpStatus.NOT_FOUND) {
      return false;
    }
    return true;
  }

  async deleteMyPageInfoByUserId(deleteMyPageInfoRequest: IDeleteMyPageInfoRequest): Promise<number> {
    const response = await this.http.delete(`/api/boris/ssqData/user/myPage/delete/${deleteMyPageInfoRequest.userId}`);
    return response.status;
  }


  async deleteUserInfoByUserId(deleteMyPageInfoRequest: IDeleteMyPageInfoRequest): Promise<number> {
    const response = await this.http.delete(`/api/member/ssqData/user/myPage/delete/${deleteMyPageInfoRequest.userId}`);
    return response.status;
  }


  async getLogManagementItemList(pageNum: number): Promise<ILogManagementItem[]> {
    const response = await this.http.get(`/api/boris/ssqData/log?pageNum=${pageNum}`);
    return response.json();
  }

  async getLogManagementItemSearch(searchValue: ILogSearchRequest): Promise<ILogManagementItem[]> {
    const response = await this.http.post(`/api/boris/ssqData/log/search`, searchValue);
    return response.json();
  }

  async getLogManagementItemCount(logSrchParam: ILogSearchRequest): Promise<ILogCount> {
    const response = await this.http.post(`/api/boris/ssqData/log/search/count`, logSrchParam);
    return response.json();
  }

  async getCountLogManagementItem(): Promise<number> {
    const response = await this.http.get(`/api/boris/ssqData/count/log`);
    return response.json();
  }

  async postLogItem(createLogItem: ICreateLogItem): Promise<number> {
    const response = await this.http.post(`/api/boris/ssqData/log`, createLogItem);
    return response.status;
  }

  async postMemberLogItem(createLogItem: ICreateLogItem): Promise<number> {
    const response = await this.http.post(`/api/member/ssqData/log`, createLogItem);
    return response.status;
  }

  async getEventDocuments(): Promise<IEventDocument[]> {
    const response = await this.http.get(`/api/boris/ssqData/eventDocuments`);
    return response.json();
  }

  async getEventAllDocuments(): Promise<IEventDocument[]> {
    const response = await this.http.get(`/api/boris/ssqData/all/eventDocuments`);
    return response.json();
  }

  async getEventDocumentDetail(documentId: number): Promise<IEventDocumentDetail> {
    const response = await this.http.get(`/api/boris/ssqData/eventDocumentDetail/${documentId}`);
    return response.json();
  }

  async postEventDocument(eventDocumentCreateRequest: IEventDocumentCreateRequest): Promise<number> {
    const response = await this.http.post(`/api/boris/ssqData/eventDocument/create`, eventDocumentCreateRequest);
    return response.json();
  }

  async deleteEventDocument(documentId: number): Promise<number> {
    const response = await this.http.delete(`/api/boris/ssqData/eventDocument/delete/${documentId}`);
    return response.status;
  }

  async postEventDocumentFiles(eventDocumentFileCreateRequest: IEventDocumentFileCreateRequest): Promise<number> {
    const response = await this.http.post(`/api/boris/ssqData/eventDocument/file/create`, eventDocumentFileCreateRequest);
    return response.status;
  }

  async getSoftware(getSwRequest: ISwSrchParam, page: number): Promise<ISwResponse> {
    const response = await axios.get(`/api/guest/ssqData/software?title=${getSwRequest.title}&swType=${getSwRequest.swType}&page=${page}`);
    return response.data;
  }

  async postSoftware(swCreateRequest: ISwCreateRequest, uploadProgress?: Function): Promise<number> {
    const requestVersionList = [];
    const LARGE_FILE_SIZE_10MB = 1024 * 10000;
    let uploadedFileNum = 0;
    for (const version of swCreateRequest.versions) {
      let tmpVersion: ISwVersion = {
        swVerId: version.swVerId,
        versionNm: version.versionNm,
        file: null,
        meta: null,
        data: null,
        revisionNumber: version.revisionNumber,
        pinStat: version.pinStat,
        lastModifierId: version.lastModifierId,
        description: version.description
      };
      if (version.file?.name && !version.file.id) {
        const datekey = new Date(Date.now()).valueOf();
        uploadedFileNum += 1;
        uploadProgress(version.file.name, uploadedFileNum);
        let tmp = await this.postFileInBlob("software/" + datekey, [version.file]);
        tmpVersion = {
          ...tmpVersion, file: {
            ...emptyFile,
            fileKey: tmp[0].fileKey,
            fileName: version.file.name,
            fileLang: DocLanguageType.ETC,
            fileSize: version.file.size,
            blobUrlForLargeFile: tmp[0].blobUrlForLargeFile
          }
        };
      } else if (version.file?.name) {
        tmpVersion = {
          ...tmpVersion, file: {
            ...emptyFile,
            id: version.file.id,
            fileKey: version.file.key,
            fileName: version.file.name,
            fileLang: DocLanguageType.ETC,
            fileSize: version.file.size,
            blobUrlForLargeFile: version.file.size > LARGE_FILE_SIZE_10MB ? version.file.dlUrl : ''
          }
        };
      }
      if (version.meta?.name && !version.meta.id) {
        const datekey = new Date(Date.now()).valueOf();
        uploadedFileNum += 1;
        uploadProgress(version.meta.name, uploadedFileNum);
        let tmp = await this.postFileInBlob("software/" + datekey, [version.meta]);
        tmpVersion = {
          ...tmpVersion, meta: {
            ...emptyFile,
            fileKey: tmp[0].fileKey,
            fileName: version.meta.name,
            fileLang: DocLanguageType.ETC,
            fileSize: version.meta.size,
            blobUrlForLargeFile: tmp[0].blobUrlForLargeFile
          }
        };
      } else if (version.meta?.name) {
        tmpVersion = {
          ...tmpVersion, meta: {
            ...emptyFile,
            id: version.meta.id,
            fileKey: version.meta.key,
            fileName: version.meta.name,
            fileLang: DocLanguageType.ETC,
            fileSize: version.meta.size,
            blobUrlForLargeFile: version.meta.size > LARGE_FILE_SIZE_10MB ? version.meta.dlUrl : ''
          }
        };
      }
      if (version.data?.name && !version.data.id) {
        const datekey = new Date(Date.now()).valueOf();
        uploadedFileNum += 1;
        uploadProgress(version.data.name, uploadedFileNum);
        let tmp = await this.postFileInBlob("software/" + datekey, [version.data]);
        tmpVersion = {
          ...tmpVersion, data: {
            ...emptyFile,
            fileKey: tmp[0].fileKey,
            fileName: version.data.name,
            fileLang: DocLanguageType.ETC,
            fileSize: version.data.size,
            blobUrlForLargeFile: tmp[0].blobUrlForLargeFile
          }
        };
      } else if (version.data?.name) {
        tmpVersion = {
          ...tmpVersion, data: {
            ...emptyFile,
            id: version.data.id,
            fileKey: version.data.key,
            fileName: version.data.name,
            fileLang: DocLanguageType.ETC,
            fileSize: version.data.size,
            blobUrlForLargeFile: version.data.size > LARGE_FILE_SIZE_10MB ? version.data.dlUrl : ''
          }
        };
      }
      requestVersionList.push(tmpVersion);
    }

    return await axios.post('/api/boris/ssqData/software', {...swCreateRequest, versions: requestVersionList});
  }

  async getSoftwareDetail(id: number): Promise<ISwDetailWithVersion> {
    const response = await axios.get(`/api/boris/ssqData/software/detail?id=${id}`);
    return response.data;
  }

  async deleteSoftware(swId: number): Promise<number> {
    const response = await axios.delete(`/api/boris/ssqData/software/delete/${swId}`);
    return response.data;
  }

  async isDistinctSwNm(swId: number, swNm: string): Promise<boolean> {
    const response = await axios.get(`/api/boris/ssqData/software/distinct/${swId}/${swNm}`);
    return Promise.resolve(response.data);
  }
}
import {resi18nutil} from "language/resi18nutil";
import {resi18n_all_en, resi18n_all_enus, resi18n_all_ko} from "language/resi18n_all";

const resi18n_product_page = {
  I18N_TITLE_MAIN: ["메인", "Main", undefined],
  I18N_TITLE_PRODUCT: ["제품", "Product", undefined],
  I18N_TITLE_PRODUCT_LINE: ["제품 / 제품군 선택", "Products organized by functionality.", undefined],
  I18N_TAB_DOWNLOAD: ["다운로드", "Download", undefined],
  I18N_TAB_PRODUCT_TECHNICAL: ["Knowledge Base", "Knowledge Base", undefined],
  I18N_TAB_TECHNICAL: ["Knowledge Base", "Knowledge Base", undefined],
  I18N_TAB_SOFTWARE: ["소프트웨어", "Software", 'Software'],
  I18N_TAB_TRAINING: ["교육", "Training", undefined],
  I18N_TAB_QNA: ["Q&A", "Q&A", undefined],
  I18N_TAB_SAMPLE_STORE: ["샘플 라이브러리", "Sample Library", undefined],
  I18N_TAB_SW_SERVICE: ['SW 솔루션', "SW Solution", undefined],
  I18N_TAB_TROUBLE_SHOOTING: ['Troubleshooting', "Troubleshooting", undefined],
  I18N_TAB_MANUAL: ["사용 설명서", "User Manuals", 'User Manuals'],
  I18N_TAB_CATALOG: ["카탈로그", "Catalogs", 'Catalogs'],
  I18N_TAB_CERTIFICATION: ["인증/성적서", "Certificates", undefined],
  I18N_TAB_CAD_DATA: ["CAD/배선도", "CAD/Wiring", undefined],
  I18N_TAB_DATASHEET: ["데이터시트", "Datasheets", "Datasheets"],
  I18N_TAB_OS: ["OS/펌웨어", "OS/Firmware", undefined],
  I18N_DOC_COL_NAME: ["자료명", "Name", undefined],
  I18N_DOC_COL_EDUCATION_NAME: ["교육명", "Training", undefined],
  I18N_DOC_COL_DESCRIPTION: ["설명", "Description", undefined],
  I18N_DOC_COL_EDUCATION_VERSION: ["챕터", "Chapter", undefined],
  I18N_DOC_COL_VERSION: ["버전 ", "Version ", undefined],
  I18N_DOC_COL_UPDATE_TIME: ["업데이트", "Update", undefined],
  I18N_DOC_COL_WRITER: ["글쓴이", "Writer", undefined],
  I18N_DOC_COL_LANG: ["언어", "Language", undefined],
  I18N_DOC_COL_VIEWS: ["조회수", "Views", undefined],
  I18N_DOC_VIEW_MORE: ["더보기", "View More", undefined],
  I18N_DOC_COL_BOOKMARK: ["북마크", "Bookmark", undefined],
  I18N_DOC_COL_SHARE: ["공유", "Share", undefined],
  I18N_DOC_HOMEPAGE_BUTTON: ["상세 정보 보기", "More Details", undefined],
  I18N_DOC_RELATED_CATEGORY: ["연관 카테고리", "Related Category", undefined],
  I18N_DOC_FEEDBACK: ["이 자료가 도움이 되었나요?", "Was this helpful?", undefined],
  I18N_DOC_FEEDBACK_GOOD: ["도움돼요", "Helpful", undefined],
  I18N_DOC_FEEDBACK_BAD: ["부족해요", "Not Helpful", undefined],
  I18N_DOC_FEEDBACK_PLACEHOLDER: ["피드백을 남겨주세요.", "Tell us more...", undefined],
  I18N_DOC_FEEDBACK_BUTTON: ["전송", "Send", undefined],
  I18N_DOC_FEEDBACK_ALERT: ["피드백이 전송되었습니다.", "Feedback is submitted.", undefined],
  I18N_DOC_DOWNLOAD_COUNT: ["다운로드수", "Download Count", undefined],
  I18N_CATEGORY_PREVIEW_MODEL: ["모델", "Model", undefined],
  I18N_FILE_LANG_KO: ["한국어", "Ko", undefined],
  I18N_FILE_LANG_EN: ["영어", "En", undefined],
  I18N_FILE_LANG_ETC: ["기타", "Etc.", undefined],
  I18N_FILE_LANG_ALL: ["공용", "All", undefined],
  I18N_FILTER_FILE_LANG_KO: ["한", "Ko", undefined],
  I18N_FILTER_FILE_LANG_EN: ["영", "En", undefined],
  I18N_FILTER_FILE_LANG_ETC: ["기타", "Etc.", undefined],
  I18N_FILTER_FILE_LANG_ALL: ["공용", "All", undefined],
  I18N_TAB_APPLICATION_NOTE: ["Application Note", "Application Notes", "Application Notes"],
  I18N_TAB_TECHNICAL_GUIDE: ["Technical Guide", "Technical Guides", "Technical Guides"],
  I18N_TAB_SAMPLE: ["LS 샘플", "LS Sample", undefined],
  I18N_TAB_EDUCATION_NOTE: ["교육 자료", "Training Manual", undefined],
  I18N_TAB_EDUCATION_VIDEO: ["교육 동영상", "Training Video", undefined],
  I18N_SOLUTION_CLICK_COUNT: ["조회수", "View", undefined],
  I18N_SOLUTION_RELATED: ["적용 제품", "Related products", undefined],
  I18N_SOLUTION_EMPLOYEE: ["임직원", "Employee", undefined],
  I18N_SOLUTION_EMPLOYEE_ONLY: ["임직원 전용", "Employee Only", undefined],
  I18N_SOLUTION_PARTNER: ["파트너 전용", "Partner Only", undefined],
  I18N_FEEDBACK_CONFIRM: ["피드백을 전송하시겠습니까?", "Do you want to send feedback?", undefined],
  I18N_NO_DOCUMENT: ["검색된 자료가 없습니다.", "No result found.", undefined],
  I18N_NO_DOCUMENT_NOTICE: ["다른 카테고리를 선택해 주세요.", "Select other categories.", undefined],
  I18N_NO_DOCUMENT_RESET: ["필터 초기화", "Reset filters", undefined],
  I18N_PRODUCT_FILTER: ["필터", "Filter", undefined],

  I18N_TAB_SQUARE: ["커뮤니티", "Community", undefined],

  I18N_DOCS_COMMUNITY_QNA: ["Q&A", "Q&A", undefined],
  I18N_DOCS_COMMUNITY_BLOG: ["Knowledge Base", "Knowledge Base", undefined],
  I18N_DOCS_COMMUNITY_BOARD: ["게시판", "Bulletin Board", undefined],

  I18N_PRODUCT_FILTER_ALL: ["전체", "All", undefined],
  I18N_PRODUCT_FILTER_TAG: ["태그", "Tag", undefined],
  I18N_PRODUCT_ALERT_TAG_INVALID: ["삭제된 태그 또는 잘못된 태그입니다.", "This is a deleted or invalid tag.", undefined],
  I18N_PRODUCT_FILTER_ALERT_MANY_TAG: ["3개이하의 태그를 입력해 주세요.", "Please enter no more than 3 tags.", undefined],
  I18N_PRODUCT_FILTER_RECENT: ["작성일순", "Created", undefined],
  I18N_PRODUCT_FILTER_UPDATED: ["최신순", "Updated", undefined],
  I18N_PRODUCT_FILTER_POPULAR: ["인기순", "Popularity", undefined],
  I18N_PRODUCT_FILTER_PERIOD: ["기간", "Period", undefined],
  I18N_PRODUCT_FILTER_ONE_WEEK: ["1주", "1 Week", undefined],
  I18N_PRODUCT_FILTER_ONE_MONTH: ["1개월", "1 Month", undefined],
  I18N_PRODUCT_FILTER_SIX_MONTH: ["6개월", "6 Months", undefined],
  I18N_PRODUCT_FILTER_ONE_YEAR: ["1년", "1 Year", undefined],
  I18N_PRODUCT_FILTER_RESET: ["초기화", "Reset", undefined],
  I18N_PRODUCT_PLACEHOLDER: ["키워드 검색", "Search Keywords", undefined],
  I18N_PRODUCT_DOC_REPORT: ["신고", "Report", undefined],
  I18N_PRODUCT_DOC_MODIFIED: ["수정", "Modify", undefined],
  I18N_PRODUCT_DOC_DELETE: ["삭제", "Delete", undefined],
  I18N_PRODUCT_DOC_ERROR_DELETE: ["삭제에 실패하였습니다. 다시 시도해주세요.", "Fail to delete. Try again.", undefined],
  I18N_PRODUCT_DOC_CONFIRM_DELETE_SUCCESS: ["포스트가 삭제되었습니다.", "The post has been deleted.", undefined],
  I18N_PRODUCT_DOC_CONFIRM_DELETE: ["삭제 하시겠습니까?", "Do you want to delete?", undefined],
  I18N_PRODUCT_DOC_BOOKMARK: ["북마크 했습니다.", "Successfully Bookmarked.", undefined],
  I18N_PRODUCT_MYPAGE: ["바로가기", "Bookmark", undefined],
  I18N_PRODUCT_DOC_BOOKMARK_FAILED: ["북마크를 취소했습니다.", "Bookmark deleted.", undefined],
  I18N_PRODUCT_SELECTOR: ['제품 선정 가이드', 'Product Selector', undefined],
  I81N_PRODUCT_SELECTOR_TITLE: ["선정", undefined, undefined],
  I18N_PRODUCT_SELECTOR_GEARBOX: ['감속기', 'GearBox', undefined],
  I18N_PRODUCT_SELECTOR_LINEAR_MOTOR: ['리니어 모터', 'Linear Motor', undefined],
  I18N_PRODCUT_SELECTOR_ADDED_TAB: ['1개 이상의 제품을 담아 주세요.', 'Please add more than one Product.', undefined],
  I18N_PRODCUT_UNKNOWN_USER: ['탈퇴한 회원입니다', 'Closed Account.', undefined],
  I18N_PRODCUT_SELECTOR_COMPARE_TAB: ['1개 이상의 제품을 선택해 주세요.', 'Please select more than one Product.', undefined],
  I18N_PRODCUT_SELECTOR_COMPARE_TAB_HMI: ['1개 이상의 HMI 제품을 선택해 주세요.', 'Please select more than one HMI.', undefined],
  I18N_PRODCUT_SELECTOR_COMPARE_TAB_VFD: ['1개 이상의 LV Drive 제품을 선택해 주세요.', 'Please select more than one LV Drive.', undefined],
  I18N_PRODUCT_SELECTOR_SHARE: ['Link를 클립보드에 복사하였습니다.', 'Link copied to clipboard.', undefined],
  I18N_PRODUCT_SELECTOR_ADD: ['‘담기’ 탭에 추가되었습니다.', 'It has been added to the ‘Added’ tab.', undefined],
  I18N_PRODUCT_SELECTOR_ADD_CLEAR: ['‘담기’ 탭에서 삭제되었습니다.', 'It has been deleted.', undefined],
  I18N_PRODUCT_SELECTOR_COMPARE: ['‘비교’ 탭에 추가되었습니다.', 'It has been added to the ‘Compare’ tab.', undefined],
  I18N_PRODUCT_SELECTOR_COMPARE_CLEAR: ['‘비교’ 탭에서 삭제되었습니다.', 'It has been deleted.', undefined],
  I18N_PRODUCT_SELECTOR_RESET: ['검색 옵션이 초기화 되었습니다.', 'All filters have been cleared.', undefined],
};


const [ko, en, enus] = resi18nutil.getres(resi18n_product_page);

export const resi18n_product_page_ko = {...resi18n_all_ko, ...ko};
export const resi18n_product_page_en = {...resi18n_all_en, ...en};
export const resi18n_product_page_enus = {...resi18n_all_enus, ...enus};
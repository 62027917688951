export const fault = "#F30000";
export const normal = "#44C257";
export const warning = "#FFC700";
export const disconnected = "#8D8D8D";

export const accent01 = "#0028B6";
export const accent02 = "#4E629F";
export const accent03 = "#E2EBFB";
export const bg02 = "#F2F5FB";
export const bg04 = "#F8FAFB";
export const bg05 = "#F6F7FC";

export const text02 = "#0A1E5A";
export const text03 = "#374E92";
export const text04 = "#95A1C6";
export const text07 = "#7B8389";
export const component01 = "#97A4C8";
export const component02 = "#F2F4FB";
export const component03 = "#E6EAEF";
export const component04 = "#AFBAC7";
export const component05 = "#ABB0BF";
export const component07 = "#577EFF";

export const lnb01 = "#2E4890";
export const lnb02 = "#1F377C";
export const lnb03 = "#95A1C6";
export const snb02 = "#F9FAFC";
export const snb03 = "#F2F4F9";
export const snb04 = "#EEF0F6";

export const divider01 = "#D5D8E5";
export const divider06 = "#2E4890";
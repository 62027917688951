// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import {loginutil} from "util/loginutil";
import {UserProfileImage} from "header/userprofile/UserProfileImage";
import {SsqImages} from "images/SsqImages";
import {LoadingIcon} from "style/CommonStyledComponents";
import {IfLoginInfo} from "models/IfLoginInfo";

type Props = {
  onClick;
  isLoadingUserPopup: boolean;
  alertNewAlarm: boolean;
  loginInfo: IfLoginInfo;
  isMobile: boolean
};
export const UserProfileBtn = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [key, setKey] = useState(1);


  useEffect(() => {
    if (props.isLoadingUserPopup) {
      setIsLoading(true);
      setKey(key + 1);
    }
  }, [props.isLoadingUserPopup]);
  return (
    <button onClick={props.onClick} style={{position: 'relative'}}>
      {<div key={key}
        style={{display: ((props.isLoadingUserPopup || isLoading) && !props.isMobile) ? 'none' : 'initial'}}>{loginutil.islogin(props.loginInfo) ?
        props.alertNewAlarm ? <div className="alarm-active">
            <span className={"circle"}></span>
            <UserProfileImage headerStyle={'header'} loginInfo={props.loginInfo} onLoad={() => setIsLoading(false)}/>
          </div> :
          <UserProfileImage headerStyle={'header'} loginInfo={props.loginInfo} onLoad={() => {
            setIsLoading(false);
          }}/> :
        <img alt={"user_icon.svg"} src={SsqImages.header.icon.userHeader()} onLoad={() => setIsLoading(false)}/>}
      </div>}
      {((props.isLoadingUserPopup || isLoading) && !props.isMobile) && <LoadingIcon>
        <img src={SsqImages.common.loading()}/>
      </LoadingIcon>}
    </button>
  );
};
import {Http} from "../fetch/Http";
import HttpStatus from "../definitions/HttpStatus";
import TagManager from "react-gtm-module";

export interface SocialSignupInfo {
  email: string;
  socialId: string;
  loginType: string;
  isExistSsqAccountNotSNS?: boolean;
}

export const emptySocialSignupInfo: SocialSignupInfo = {
  email: "",
  socialId: "",
  loginType: "",
  isExistSsqAccountNotSNS: false
};


export interface NaverUserInfo {
  email: string,
  id: string
}

export interface LoginService {
  login(username: string, password: string, loginType: string): Promise<boolean>;

  logout(): Promise<boolean>;

  logoutAdmin(): Promise<boolean>;

  getNaverUserInfo(accessToken: string): Promise<NaverUserInfo>;

}

export class NetworkLoginService implements LoginService {
  http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  async login(username: string, password: string, loginType: string): Promise<boolean> {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("loginType", loginType);

    const result = await this.http.postFormData("/api/member/login", formData);
    const loggedIn = result.status === HttpStatus.OK;

    return Promise.resolve(loggedIn);
  }

  async logout(): Promise<boolean> {
    let logoutUrl = "/api/member/logout";

    const result = await this.http.post(logoutUrl);

    let loggedOut = result.status === HttpStatus.OK;

    return Promise.resolve(loggedOut);
  }

  async logoutAdmin(): Promise<boolean> {
    let logoutUrl = "/api/boris/logout";

    const result = await this.http.post(logoutUrl);

    let loggedOut = result.status === HttpStatus.OK;

    return Promise.resolve(loggedOut);
  }

  async getNaverUserInfo(accessToken: string): Promise<NaverUserInfo> {
    const response = await this.http.get("/api/guest/getNaverUserInfo?accessToken=" + accessToken);
    return response.json();
  }
}
import React, {Component} from 'react';
import {RouteComponentProps, withRouter} from "react-router-dom";
import {ErrorpageWrap} from "./style/CommonStyledComponents";
import {LanguageService} from "./language/LanguageService";
import img_unsupported_url from "images/common/unsupported_url.svg";

interface Props extends RouteComponentProps<any> {
  languageService: LanguageService;
}

class NotFoundPage extends Component<Props, any> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  getTranslation(key: string): string {
    this.props.languageService.setNamespace("I18N_NAMESPACE_HEADER");
    return this.props.languageService.getTranslationByKey(key) || "";
  }


  render() {
    return (
      <ErrorpageWrap>
        <div className={"Errorpage"}>
          <div className={"container"}>
            <div className={"error-content"}>
              <img src={img_unsupported_url}/>
              <p className={"error-tit"}>{this.getTranslation("I18N_UNSUPPORTED_URL_TIT")}</p>
              <p className={"error-txt"}>{this.getTranslation("I18N_UNSUPPORTED_URL_TXT")}</p>
            </div>
          </div>
        </div>
      </ErrorpageWrap>
    );
  }
}

export default withRouter(NotFoundPage);
import {LANG_EN, LANG_EN_US} from "language/langutil";

export const cookieutil = {

  existValueGoogleConsentCookie: () => {
    if (document.cookie.includes('cookie-agreed-categories')) {
      const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith('cookie-agreed-categories='))
        .split('=')[1];
      if (cookieValue == null || cookieValue == undefined) {
        return false;
      }
      return true;
    }
    return false;
  },

  isAcceptFunctionConsentCookie: (lang: string) => {
    if ([LANG_EN, LANG_EN_US].includes(lang)) {
      const consentCookie = LANG_EN == lang ? 'cookie-agreed-categories=' : 'cookie-agreed-categories-us=';

      try {
        const cookie = document.cookie.split('; ')
          .find(row => row.startsWith(consentCookie))
          .split('=')[1];
        if (cookie) {
          const parsedCookie = JSON.parse(decodeURIComponent(cookie));
          if (parsedCookie.functionality === 'denied') return false;
        }
      } catch (e) {
        return true;
      }
    }
    return true;
  },

  countrySpecificCookieVerification: (lang: string) => {
    if (lang == LANG_EN_US)
      if (document.cookie.includes('cookie-agreed-categories-us=')) return true;
    if (lang == LANG_EN)
      if (document.cookie.includes('cookie-agreed-categories=')) return true;

    return false;
  },

  getGoogleTagId: (locationUrl: string) => {
    let tagId = 'GTM-PZJQFLP5';
    if (locationUrl.includes('sol.ls-electric.com')) tagId = 'GTM-TMPV2TM';
    else if (locationUrl.includes('bff-dev.apps.ssq.lsokd.com')) tagId = 'GTM-K6LN3DQ';
    return tagId;
  },

  getCookieValue: (key: string): string => {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.indexOf(key + '=') === 0) {
        return decodeURIComponent(cookie.substring(key.length + 1));
      }
    }

    return null;
  }

};
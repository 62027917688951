export const SiteLanguageType = Object.freeze({
  ALL: "all",
  KO_KR: "ko-KR",
  EN: "en",
  EN_US: "en-US",
});

export const getSiteLanguageTypeLangKey = (value: string) => {
  switch (value) {
    case SiteLanguageType.ALL:
      return 'I18N_FILTER_COMMON';
    case SiteLanguageType.KO_KR:
      return 'I18N_DISPLAY_SHORT_KOREAN';
    case SiteLanguageType.EN:
      return 'I18N_DISPLAY_SHORT_ENGLISH';
    case SiteLanguageType.EN_US:
      return 'I18N_DISPLAY_SHORT_ENGLISH_US';
    default:
      return '';
  }
};

export const getSiteLanguageFullNameTypeLangKey = (value: string) => {
  switch (value) {
    case SiteLanguageType.ALL:
      return 'I18N_FILTER_COMMON';
    case SiteLanguageType.KO_KR:
      return 'I18N_FILTER_KOREA';
    case SiteLanguageType.EN:
      return 'I18N_FILTER_GLOBAL';
    case SiteLanguageType.EN_US:
      return 'I18N_FILTER_USA';
    default:
      return '';
  }
};

export const myDocFilterLanguageSiteFilterLangKey = (value: string) => {
  switch (value) {
    case SiteLanguageType.ALL:
      return 'I18N_COMMUNITY_FILTER_COUNTRY';
    case SiteLanguageType.KO_KR:
      return 'I18N_COMMUNITY_FILTER_COUNTRY_KOREA';
    case SiteLanguageType.EN:
      return 'I18N_COMMUNITY_FILTER_COUNTRY_GLOBAL';
    case SiteLanguageType.EN_US:
      return 'I18N_COMMUNITY_FILTER_COUNTRY_USA';
    default:
      return '';
  }
};


// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useParams} from "react-router";

import axios from 'axios';
import {langutil} from "language/langutil";

import svcs from "services";
import NotFoundPage from "NotFoundPage";
import {IUrlLinkEntity} from "models/IUrlLinkEntity";

type Props = {};
export const UrlLinkPage = (props: Props) => {
  const [stat, setstat] = useState<"notfound" | "error" | "">("");
  const params = useParams<{ country, lang, urlGroup, urlId }>();
  //const history = useHistory();
  useEffect(() => {

    const {country, lang, urlGroup, urlId} = params;
    const url = `/api/guest/urllink/get/${langutil.getLangCode(country, lang)}/${urlGroup}/${urlId}`;
    axios.get(url).then(res => {
      const entity: IUrlLinkEntity = res.data;
      //history.push(`${entity.longUrl}`);
      if (entity && entity.longUrl) {
        document.location.href = entity.longUrl;
      } else {
        // notfound page
        setstat("notfound");
        console.log("not notfound url ", url, " res ", entity);
      }
    }).catch(e => {
      console.error("E", e);
      setstat("error");
    });

  }, []);
  return (
    <div>
      {stat === 'notfound' && <NotFoundPage languageService={svcs.svcLang}/>}
      {stat === 'error' && <NotFoundPage languageService={svcs.svcLang}/>}
    </div>
  );
};
// @flow
import * as React from 'react';
import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import styled from "styled-components";


type Props = {};


const AssetPage = ({}: Props) => {
  const history = useHistory();

  useEffect(() => {
    history.replace("/asset/monitor");
  }, []);
  return (
    <AssetBody>
    </AssetBody>
  );
};
export default AssetPage;

const AssetBody = styled.div`

`;


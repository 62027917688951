export const MemberType = Object.freeze({
  SELECT: "SELECT",
  MACHINE_MAKER: "MACHINE_MAKER",
  SMALL_WHOLESALE: "SMALL_WHOLESALE",
  SET_MAKER: "SET_MAKER",
  ELECTRIC_COMPANY: "ELECTRIC_COMPANY",
  DEALERSHIP: "DEALERSHIP",
  PANEL_MAKER: "PANEL_MAKER",
  STUDENT: "STUDENT",
  ETC: "ETC"
});

export const getMemberTypeLangKey = (value: string) => {
  switch (value) {
    case MemberType.SELECT:
      return 'I18N_SIGNUP_MEMBER_TYPE_SELECT';
    case MemberType.MACHINE_MAKER:
      return 'I18N_SIGNUP_MEMBER_TYPE_MACHINE_MAKER';
    case MemberType.SMALL_WHOLESALE:
      return 'I18N_SIGNUP_MEMBER_TYPE_SMALL_WHOLESALE';
    case MemberType.SET_MAKER:
      return 'I18N_SIGNUP_MEMBER_TYPE_SET_MAKER';
    case MemberType.ELECTRIC_COMPANY:
      return 'I18N_SIGNUP_MEMBER_TYPE_ELECTRIC_COMPANY';
    case MemberType.DEALERSHIP:
      return 'I18N_SIGNUP_MEMBER_TYPE_DEALERSHIP';
    case MemberType.PANEL_MAKER:
      return 'I18N_SIGNUP_MEMBER_TYPE_PANEL_MAKER';
    case MemberType.STUDENT:
      return 'I18N_SIGNUP_MEMBER_TYPE_STUDENT';
    case MemberType.ETC:
      return 'I18N_SIGNUP_MEMBER_TYPE_ETC';
    default:
      return '';
  }
};

export const getMemberTypeLangKeyInUserManagement = (value: string) => {
  switch (value) {
    case MemberType.SELECT:
      return 'I18N_USER_MEMBER_TYPE_SELECT';
    case MemberType.MACHINE_MAKER:
      return 'I18N_USER_MEMBER_TYPE_MACHINE_MAKER';
    case MemberType.SMALL_WHOLESALE:
      return 'I18N_USER_MEMBER_TYPE_SMALL_WHOLESALE';
    case MemberType.SET_MAKER:
      return 'I18N_USER_MEMBER_TYPE_SET_MAKER';
    case MemberType.ELECTRIC_COMPANY:
      return 'I18N_USER_MEMBER_TYPE_ELECTRIC_COMPANY';
    case MemberType.DEALERSHIP:
      return 'I18N_USER_MEMBER_TYPE_DEALERSHIP';
    case MemberType.PANEL_MAKER:
      return 'I18N_USER_MEMBER_TYPE_PANEL_MAKER';
    case MemberType.STUDENT:
      return 'I18N_USER_MEMBER_TYPE_STUDENT';
    case MemberType.ETC:
      return 'I18N_USER_MEMBER_TYPE_ETC';
    default:
      return '';
  }
};


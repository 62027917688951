import {resi18nutil} from "language/resi18nutil";
import {resi18n_all_en, resi18n_all_enus, resi18n_all_ko} from "language/resi18n_all";

const resi18n_solution_page = {
  I18N_TITLE_MAIN: ["메인", "Main", undefined],
  I18N_TITLE_SOLUTION: ["산업", "Solution", undefined],
  I18N_CATEGORY_INDUSTRY: ["산업", "Industry", undefined],
  I18N_CATEGORY_APPLICATION_LOAD: ["적용 부하", "Application", undefined],
  I18N_DOC_VIEW_MORE: ["더 보기", "View More", undefined],
  I18N_SOLUTION_CLICK_COUNT: ["조회수", "Views", undefined],
  I18N_TAB_APPLICATION_NOTE: ["Application Note", "Application Note", undefined],
  I18N_TAB_TECHNICAL_GUIDE: ["Technical Guide", "Technical Guide", undefined],
  I18N_TAB_SAMPLE: ["Sample", "Sample", undefined],
  I18N_SOLUTION_EMPLOYEE: ["임직원", "Employee", undefined],
  I18N_SOLUTION_PARTNER: ["파트너 전용", "Partner Only", undefined],
  I18N_SOLUTION_RELATED: ["적용 제품", "Related products", undefined],
  I18N_SOLUTION_DOWNLOAD: ["다운로드", "Download", undefined],
  I18N_DOC_DOWNLOAD_COUNT: ["다운로드수", "Download Count", undefined],
  I18N_FILE_LANG_KO: ["한국어", "Ko", undefined],
  I18N_FILE_LANG_EN: ["영어", "En", undefined],
  I18N_FILE_LANG_ETC: ["기타", "Etc.", undefined],
  I18N_FILE_LANG_ALL: ["공용", "All", undefined],
  I18N_PRODUCT_CATEGORY: ["제품 카테고리", "Product Category", undefined],
  I18N_INDUSTRY_CATEGORY: ["산업 카테고리", "Industry Category", undefined],
  I18N_APPLICATION_LOAD_CATEGORY: ["적용 부하 카테고리", "Application & Load Category", undefined],
  I18N_NO_DOCUMENT_SOLUTION: ["검색된 자료가 없습니다.", "No result found.", undefined],
  I18N_NO_DOCUMENT_NOTICE_SOLUTION: ["다른 카테고리를 선택해 주세요.", "Select other categories.", undefined],
  I18N_SOLUTION_FEEDBACK: ["이 자료가 도움이 되었나요?", "Was this helpful?", undefined],
  I18N_SOLUTION_FEEDBACK_GOOD: ["네", "Helpful", undefined],
  I18N_SOLUTION_FEEDBACK_BAD: ["아니오", "Not Helpful", undefined],
  I18N_SOLUTION_FEEDBACK_PLACEHOLDER: ["피드백을 남겨주세요.", "Tell us more...", undefined],
  I18N_SOLUTION_FEEDBACK_BUTTON: ["전송", "Send", undefined],
  I18N_SOLUTION_FEEDBACK_ALERT: ["피드백이 전송되었습니다.", "Feedback is submitted.", undefined],
  I18N_TAB_MANUAL: ["사용 설명서", null, undefined],
  I18N_TAB_CATALOG: ["카탈로그", null, undefined],
  I18N_TAB_SOFTWARE: ["소프트웨어", null, undefined],
  I18N_TAB_CERTIFICATION: ["인증/성적서", null, undefined],
  I18N_TAB_CAD_DATA: ["CAD/배선도", null, undefined],
  I18N_TAB_DATASHEET: ["Data Sheet", null, undefined],
  I18N_TAB_OS: ["OS/펌웨어", null, undefined],
  I18N_DOC_HOMEPAGE_BUTTON: ["상세 정보 보기", "More Details", undefined],
};

const [ko, en, enus] = resi18nutil.getres(resi18n_solution_page);

export const resi18n_solution_page_ko = {...resi18n_all_ko, ...ko};
export const resi18n_solution_page_en = {...resi18n_all_en, ...en};
export const resi18n_solution_page_enus = {...resi18n_all_enus, ...enus};
import {UserRole} from "definitions/UserRole";
import axios from "axios";


export const roleutil = {
  getUserRoles: (role: UserRole, checked: boolean, userRoles: string): string => {
    let roles = (userRoles || "").split(",").filter(ele => !!ele);
    if (checked) {
      // 롤추가.
      const o = roles.find(ele => ele === role);
      if (!o) roles.push(role);
    } else {
      // 록삭제.
      roles = roles.filter(ele => ele !== role);
    }
    roles.sort();
    return roles.join(",");
  },
  hasRole: (role: UserRole, userRoles: string): boolean => {
    if ((userRoles || "").indexOf(role) >= 0) {
      return true;
    }
    return false;
  },
  hasExposeRole: (accountRole: string, exposeLanguage: string): boolean => {
    let exposeLanguageKey = "";
    if (exposeLanguage === "ko-KR") exposeLanguageKey = "PRTN_KR";
    else if (exposeLanguage === "en") exposeLanguageKey = "PRTN_WW";
    else if (exposeLanguage === "en-US") exposeLanguageKey = "PRTN_US";
    else return false;

    if (![null, undefined, ""].includes(accountRole)) {
      const accountRoleList = accountRole.split(",");
      if (accountRoleList.includes(exposeLanguageKey)) return true;
    }

    return false;
  },

  //로그인한 게정이 파트너계정에 있는 ID인지 판단
  hasPartnerCheck: (userId: string, exposeLang: string): Promise<Boolean> => {
    return axios.get('/api/member/partnerUser/checkLang', {
      params: {userId: userId, lang: exposeLang}
    }).then((res) => {
      if (res.data == true) {
        return true;
      } else {
        return false;
      }
    });
  },

  hasPartnerChecks: (userId: string, exposeLang: string): Promise<boolean> => {
    return axios.get('/api/member/partnerUser/checkLang', {
      params: {userId, lang: exposeLang}
    }).then((response) => {
      return response.data === true; // true 또는 false를 반환
    }).catch((error) => {
      console.error("Error:", error);
      return false; // 오류 발생 시 false 반환
    });
  },

  //타인의 프로필 접속 시 같은 파트너사인지 판단
  hasProfilePartnerCheck: (profileId, loginId): Promise<boolean> => {
    return axios.get(`/api/member/partner/user/checkPartnerId`, {
      params: {
        loginId: loginId,
        targetId: profileId
      }
    }).then((res) => {
      return res.data === true;
    }).catch((error) => {
      console.error("Error:", error);
      return false;
    });
  },

  //파트너 상세화면
  hasPartnerQnaCheck: (userId, exposeLang, partnerId): Promise<boolean> => {
    return axios.get('/api/member/partnerUser/partnerId/checkLang', {
      params: {userId, lang: exposeLang, partnerId: partnerId}
    }).then((res) => {
      return res.data == true;
    }).catch((error) => {
      console.error("Error:", error);
      return false; // 오류 발생 시 false 반환
    });
  }


};
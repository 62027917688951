const HttpStatus = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  FOUND: 500,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
  NOT_ACCEPTABLE: 406
};

export default Object.freeze(HttpStatus);


// @flow
import * as React from 'react';
import {Route} from "react-router-dom";
import {LoadingLayer} from "ctls/modal/LoadingLayer";

const AssetBody = React.lazy(() => import("./AssetBody"));

export const AssetLayout = (props: { exact?: boolean, path: string | string[], children, guestAccess?: boolean }) => {
  return <React.Fragment>
    <Route path={props.path}
      exact={!!props.exact}
      render={() => {
        return <React.Suspense fallback={<LoadingLayer visible={true}/>}>
          <AssetBody guestAccess={props.guestAccess}>
            {React.Children.map(props.children, child => React.cloneElement(child))}
          </AssetBody>
        </React.Suspense>;
      }}/>
  </React.Fragment>;
};

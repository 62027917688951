import {css} from "styled-components";

export const fontFamilyBold = css`
  font-family: "Pretendard Bold", sans-serif;
  font-style: normal;
`;

export const fontFamilyBoldImportant = css`
  font-family: "Pretendard Bold", sans-serif !important;
  font-style: normal;
`;

export const fontFamilyMedium = css`
  font-family: "Pretendard Medium", sans-serif;
  font-style: normal;
`;

export const fontFamilyRegular = css`
  font-family: "Pretendard Regular", sans-serif;
  font-style: normal;
`;

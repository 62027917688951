export const resi18nutil = {
  getres: (res) => {
    // key :[ko, en]
    let ko = {};
    let en = {};
    let enus = {};
    Object.keys(res).forEach(key => {
      ko[key] = res[key][0];
      en[key] = res[key][1];
      enus[key] = res[key][2];
    });
    return [ko, en, enus];
  },
};
export const FeedbackType = Object.freeze({
  GOOD: "GOOD",
  BAD: "BAD",
  RPT_COMMENT: "RPT_COMMENT", // 코멘트 신고.
  RPT_DOC: "RPT_DOC", // 문서 신고.
});

export const getFeedbackTypeLangKey = (value: string) => {
  switch (value) {
    case FeedbackType.GOOD:
      return 'I18N_DOC_FEEDBACK_GOOD_STRING';
    case FeedbackType.BAD:
      return 'I18N_DOC_FEEDBACK_BAD_STRING';
    case FeedbackType.RPT_COMMENT:
      return 'I18N_DOC_FEEDBACK_RPT_COMMENT_STRING';
    case FeedbackType.RPT_DOC:
      return 'I18N_DOC_FEEDBACK_RPT_DOC_STRING';

    default:
      return '';
  }
};
export const getFeedbackTypeLangKey2 = (value: string) => {
  switch (value) {
    case FeedbackType.GOOD:
      return 'I18N_DOC_FEEDBACK_GOOD_STRING2';
    case FeedbackType.BAD:
      return 'I18N_DOC_FEEDBACK_BAD_STRING2';

    default:
      return getFeedbackTypeLangKey(value);
  }
};
import {emptyCategory, ICategory} from "./CategoryRepository";
import {CategoryType} from "../../definitions/CategoryType";

export interface IModelForSearch {
  modelName: string;
  category: string;
  categoryId: number;
}

export interface ICategoryForSearch {
  categoryName: string;
  categoryId: number;
  parentsCategory: string;
}


export default class CategoryStore {
  allCategories: ICategory[] = [];
  allCategoriesById: { [key: string]: ICategory } = {};
  categoriesForSearch: ICategoryForSearch[] = [];
  industryCategoriesForSearch: ICategoryForSearch[] = [];
  applicationLoadCategoriesForSearch: ICategoryForSearch[] = [];

  constructor() {
  }

  async setAllCategories(allCategories: ICategory[]) {
    this.allCategories = allCategories;
    await this.buildCategoriesForSearch();
  }

  async buildCategoriesForSearch() {
    if (this.allCategories) {
      this.categoriesForSearch = [];
      this.industryCategoriesForSearch = [];
      this.applicationLoadCategoriesForSearch = [];
      this.allCategories.forEach(category => {
        this.allCategoriesById[category.id] = category;
        if (category.typeName === CategoryType.PRODUCT.toString()) {
          this.categoriesForSearch.push({
            categoryName: category.name,
            categoryId: category.id,
            parentsCategory: this.getParentsCategoryStringRecursive(category)
          });
        } else if (category.typeName === CategoryType.INDUSTRY.toString()) {
          this.industryCategoriesForSearch.push({
            categoryName: category.name,
            categoryId: category.id,
            parentsCategory: ""
          });
        } else if (category.typeName === CategoryType.APPLICATION_LOAD.toString()) {
          this.applicationLoadCategoriesForSearch.push({
            categoryName: category.name,
            categoryId: category.id,
            parentsCategory: ""
          });
        }
      });
    }
  }

  getRootCategoryById(id: number): ICategory {

    const map = this.allCategoriesById;
    while (true) {
      const o = map[id];
      if (o) {
        if (o.level === 1 || o.level === 88 || o.level === 99) return o;
        if (o.parent) {
          id = o.parent;
          continue;
        }
        return null;
      }
      return null;
    }

    // parent 가
    return null;
  }

  getCategorySearchItemById(id: number): ICategoryForSearch {
    const emptyCategoryForSearch: ICategoryForSearch = {categoryId: 0, categoryName: "", parentsCategory: ""};

    if (id === null) return emptyCategoryForSearch;

    const filtered = this.categoriesForSearch.filter((category) => category.categoryId === Number(id));
    return filtered.pop() || emptyCategoryForSearch;
  }

  getCategorySearchItemByIdAndType(id: number, categoryType: string): ICategoryForSearch {
    const emptyCategoryForSearch: ICategoryForSearch = {categoryId: 0, categoryName: "", parentsCategory: ""};

    if (id === null) return emptyCategoryForSearch;

    let filtered: ICategoryForSearch[] = [];

    if (categoryType === CategoryType.PRODUCT.toString()) {
      filtered = this.categoriesForSearch.filter((category) => category.categoryId === Number(id));
    } else if (categoryType === CategoryType.INDUSTRY.toString()) {
      filtered = this.industryCategoriesForSearch.filter((category) => category.categoryId === Number(id));
    } else if (categoryType === CategoryType.APPLICATION_LOAD.toString()) {
      filtered = this.applicationLoadCategoriesForSearch.filter((category) => category.categoryId === Number(id));
    }

    return filtered.pop() || emptyCategoryForSearch;
  }

  getCategoryById(id: number | string | null): ICategory {
    if (id === null) return emptyCategory;

    const filtered = this.allCategories.filter((category) => category.id === Number(id));
    return filtered.pop() || emptyCategory;
  }

  getParentsCategoryStringRecursive(category: ICategory): string {
    if (category.parent) {
      return this.getParentsCategoryStringRecursive(this.getCategoryById(category.parent)) + " / " + category.name;
    } else {
      return category.name;
    }
  }

  getParentsCategoriesWithoutMyself(category: ICategory): ICategory[] {
    if (category.parent) {
      return this.getParentsCategoriesRecursive(this.getCategoryById(category.parent));
    } else {
      return [];
    }
  }

  getParentsCategoriesRecursive(category: ICategory): ICategory[] {
    if (category.parent) {
      return this.getParentsCategoriesRecursive(this.getCategoryById(category.parent)).concat(category);
    } else {
      return [category];
    }
  }
}

import {resi18n_all_en, resi18n_all_enus, resi18n_all_ko} from "language/resi18n_all";
import {resi18nutil} from "language/resi18nutil";

const resi18n_all_search_page = {
  I18N_ALL_SEARCH_TITLE_MAIN: ["메인", "Main", undefined],
  I18N_ALL_SEARCH_TITLE_ALL: ["통합 검색", "Search", undefined],
  I18N_ALL_SEARCH_EMPLOYEE: ["임직원", "Employee", undefined],
  I18N_ALL_SEARCH_EMPLOYEE_ONLY: ["임직원 전용", "Employee Only", undefined],
  I18N_ALL_SEARCH_PARTNER: ["파트너 전용", "Partner Only", undefined],
  I18N_ALL_SEARCH_RND: ["R&D 전용", "R&D Only", undefined],
  I18N_ALL_SEARCH_SEARCH: ["검색하기", "Unified Search", undefined],
  I18N_ALL_SEARCH_SEARCH_PLACEHOLDER: ["제품명 / 모델명 / Technical Guide", "Product / Model / Technical Article", undefined],
  I18N_ALL_SEARCH_INCLUDE_CHECKBOX: ["문서 내 검색 포함", "Show result in document", undefined],
  I18N_ALL_SEARCH_TAB_ALL: ["전체", "All", undefined],
  I18N_ALL_SEARCH_TAB_CATEGORY: ["제품", "Product", undefined],
  I18N_ALL_SEARCH_TAB_POST: ["문서", "Document", undefined],
  I18N_ALL_SEARCH_TAB_FILE: ["파일", "File", undefined],
  I18N_ALL_SEARCH_TAB_CATEGORY_VIEW_MORE: ["제품 더보기", "Product View More", undefined],
  I18N_ALL_SEARCH_TAB_POST_VIEW_MORE: ["문서 더보기", "Document View More", undefined],
  I18N_ALL_SEARCH_TAB_FILE_VIEW_MORE: ["파일 더보기", "File View More", undefined],
  I18N_ALL_SEARCH_TAB_VIEW_MORE: ["더 보기", "View More", undefined],
  I18N_ALL_SEARCH_MODEL: ["모델", "Model", undefined],
  I18N_ALL_SEARCH_CLICK_COUNT: ["조회수", "Click Count", undefined],
  I18N_ALL_SEARCH_RELATED_CATEGORY: ["연관 제품 카테고리", "Related Category", undefined],
  I18N_ALL_SEARCH_RELATED_DOWNLOAD_COUNT: ["다운로드수", "Download Count", undefined],
  I18N_ALL_SEARCH_RELATED_VIEW_DETAIL: ["자세히 보기", "View Detail", undefined],

  I18N_SEARCH_DOC_TYPE: ["문서 종류", "Document Type", undefined],
  I18N_TAB_MANUAL: ["사용 설명서", "User Manuals", undefined],
  I18N_TAB_CATALOG: ["카탈로그", "Catalogs", undefined],
  I18N_TAB_SOFTWARE: ["소프트웨어", "Software", undefined],
  I18N_TAB_CERTIFICATION: ["인증/성적서", "Certificates", undefined],
  I18N_TAB_CAD_DATA: ["CAD/배선도", "CAD/Wiring", undefined],
  I18N_TAB_DATASHEET: ["데이터시트", "Datasheets", undefined],
  I18N_TAB_OS: ["OS/펌웨어", "OS/Firmware", undefined],
  I18N_TAB_APPLICATION_NOTE: ["Application Note", "Application Note", undefined],
  I18N_TAB_TECHNICAL_GUIDE: ["Technical Guide", "Technical Guide", undefined],
  I18N_TAB_SAMPLE: ["Sample", "Sample", undefined],
  I18N_TAB_EDUCATION_NOTE: ["교육 자료", "Training Manual", undefined],
  I18N_TAB_EDUCATION_VIDEO: ["교육 동영상", "Training Video", undefined],
  I18N_TAB_TECH_GENERAL: ["일반 포스트", "Post", undefined],
  I18N_TAB_QNA: ["Q&A", "Q&A", undefined],
  I18N_TAB_TIP: ["자유", "General", undefined],
  I18N_TAB_NOTICE: ["공지", "Notice", undefined],
  I18N_TAB_EVENT: ["이벤트", "Event", undefined],
  I18N_TAB_JOB_OFFER: ["구인", "Job Offer", undefined],
  I18N_TAB_JOB_SEARCH: ["구직", "Job Search", undefined],
  I18N_TAB_SW_SERVICE: ['SW 솔루션', 'Software Solution', undefined],
  I18N_TAB_TROUBLE_SHOOTING: ['Troubleshooting', "Troubleshooting", undefined],
  I18N_TAB_RND_NOTI: ["Notice(R&D)", "Notice(R&D)", undefined],
  I18N_TAB_RND_QNA: ["Q&A(R&D)", "Q&A(R&D)", undefined],
  I18N_TAB_RND_TALK: ["Colorful Talk(R&D)", "Colorful Talk(R&D)", undefined],


  I18N_TAB_PRODUCT: ["제품", "PRODUCT", undefined],
  I18N_TAB_INDUSTRY: ["산업", "INDUSTRY", undefined],
  I18N_TAB_APPLICATION_LOAD: ["적용 부하", "APPLICATION/LOAD", undefined],
  I18N_TAB_COMMUNITY: ["커뮤니티", "Community", undefined],
  I18N_TAB_SERVICE: ["서비스", "SERVICE", undefined],
  I18N_FILTER_COMMON: ["공용", "ALL", undefined],
  I18N_FILTER_KOREAN: ["한국어", "KO", undefined],
  I18N_FILTER_ENGLISH: ["영어", "EN", undefined],
  I18N_FILTER_ETC: ["기타", "ETC", undefined],
  I18N_NO_SEARCH_RESULT: ["검색 결과가 없습니다.", "No result found.", undefined],
  I18N_NO_SEARCH_RESULT_NOTICE: ["다른 키워드를 입력해 주세요.", "Please input another keywords.", undefined],

  I18N_SEARCH_FILTER_ALL_RESULT: ["모든 결과", "All results", undefined],
  I18N_SEARCH_FILTER_VERBATIM: ["완전 일치", "Verbatim", undefined],
  I18N_SEARCH_FILTER_ALL: ["전체", "All", undefined],
  I18N_SEARCH_FILTER_ACCURACY: ["정확도순", "Relevance", undefined],
  I18N_SEARCH_FILTER_UPDATED: ["최신순", "Updated", undefined],
  I18N_SEARCH_FILTER_POPULAR: ["인기순", "Popularity", undefined],
  I18N_SEARCH_FILTER_DOCTYPE: ["포스트 종류", "Post type", undefined],
  I18N_SEARCH_FILTER_FINISH_STATE: ["완료 상태", "Status", undefined],
  I18N_SEARCH_FILTER_QUESTION_STATE: ["질문 상태", "Status", undefined],
  I18N_SEARCH_FILTER_FINISHED: ["완료", "Complete", undefined],
  I18N_SEARCH_FILTER_NOT_FINISHED: ["진행중", "Process", undefined],
  I18N_SEARCH_FILTER_PRODUCT: ["제품", "Product", undefined],
  I18N_SEARCH_FILTER_PERIOD: ["기간", "Period", undefined],
  I18N_SEARCH_FILTER_ONE_WEEK: ["1주", "1 Week", undefined],
  I18N_SEARCH_FILTER_ONE_MONTH: ["1개월", "1 Month", undefined],
  I18N_SEARCH_FILTER_SIX_MONTH: ["6개월", "6 Months", undefined],
  I18N_SEARCH_FILTER_ONE_YEAR: ["1년", "1 Year", undefined],
  I18N_SEARCH_FILTER_TAG: ["태그", "TAG", undefined],
  I18N_SEARCH_ALERT_TAG_INVALID: ["삭제된 태그 또는 잘못된 태그입니다.", "This is a deleted or invalid tag.", undefined],
  I18N_SEARCH_FILTER_RESET: ["초기화", "Clear", undefined],

  I18N_SEARCH_FILTER_RANGE: ["검색 범위", "Search Range", undefined],
  I18N_SEARCH_FILTER_LS_RELATED: ["LS 관련", "LS Related", undefined],
  I18N_SEARCH_FILTER_LS_OFFICIAL: ["LS 공식", "LS Official", undefined],

  I18N_SEARCH_FILTER_ALERT_MANY_TAG: ["3개이하의 태그를 입력해 주세요.", "Please enter no more than 3 tags.", undefined],

  I18N_SEARCH_RESET: ["검색 옵션 초기화", "Clear Filter", undefined],
};

const [ko, en, enus] = resi18nutil.getres(resi18n_all_search_page);

export const resi18n_all_search_page_ko = {...resi18n_all_ko, ...ko};
export const resi18n_all_search_page_en = {...resi18n_all_en, ...en};
export const resi18n_all_search_page_enus = {...resi18n_all_enus, ...enus};

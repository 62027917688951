import {Http} from "../fetch/Http";

export interface IVerifySignupEmailRequest {
  email: string;
  lang?: string;
}

export interface IResetPasswordEmailRequest {
  email: string;
  lang?: string;
}


export interface IDocumentApprovalReqEmailRequest {
  type?: 'DEL' | 'APPR' | '';
  approvalUserEmail: string;
  docTitle: string;
  docType: string;
  approvalMessage: string;
  modifierName: string;
  modifierNickName: string;
  modifierEmail: string;
  documentUrl: string;
  lang?: string;
}

export interface IDocumentRejectEmailRequest {
  type?: 'DEL' | 'APPR' | '';
  approvalRequestUserEmail: string;
  approvalRequestUserNickName: string;
  approvalRequestUserName: string;
  docTitle: string;
  docType: string;
  rejectMessage: string;
  rejectorEmail: string;
  rejectorName: string;
  rejectorNickName: string;
  documentUrl: string;
  lang?: string;
}

export interface IDocumentApproveEmailRequest {
  type?: 'DEL' | 'APPR' | '';
  approvalRequestUserEmail: string;
  approvalRequestUserNickName: string;
  approvalRequestUserName: string;
  docTitle: string;
  approvalEmail: string;
  approvalName: string;
  approvalNickName: string;
  documentUrl: string;
  lang?: string;

}

export interface NotificationService {
  postSendVerifySignupEmail(verifySignupEmailRequest: IVerifySignupEmailRequest): any;

  postSendResetPasswordEmail(resetPasswordEmailRequest: IResetPasswordEmailRequest): any;

  postSendDocumentApprovalReqEmail(documentApprovalReqEmailRequest: IDocumentApprovalReqEmailRequest): any;

  postSendDocumentRejectEmail(documentRejectEmailRequest: IDocumentRejectEmailRequest): any;

  postSendDocumentApproveEmail(documentApproveEmailRequest: IDocumentApproveEmailRequest): any;
}

export class NetworkNotificationService implements NotificationService {
  http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  async postSendVerifySignupEmail(verifySignupEmailRequest: IVerifySignupEmailRequest): Promise<any> {
    const response = await this.http.post(`/api/guest/sendVerifySignupEmail`, verifySignupEmailRequest);
    return response.status;
  }

  async postSendResetPasswordEmail(resetPasswordEmailRequest: IResetPasswordEmailRequest): Promise<any> {
    const response = await this.http.post(`/api/guest/sendResetPasswordEmail`, resetPasswordEmailRequest);
    return response.status;
  }

  async postSendDocumentApprovalReqEmail(documentApprovalReqEmailRequest: IDocumentApprovalReqEmailRequest): Promise<any> {
    const response = await this.http.post(`/api/boris/sendDocumentApprovalReqEmail`, documentApprovalReqEmailRequest);
    return response.status;
  }

  async postGuestSendDocApprovalReqEmail(documentApprovalReqEmailRequest: IDocumentApprovalReqEmailRequest): Promise<any> {
    const response = await this.http.post(`/api/guest/sendDocumentApprovalReqEmail`, documentApprovalReqEmailRequest);
    return response.status;
  }

  async postSendDocumentRejectEmail(documentApprovalEmailRequest: IDocumentRejectEmailRequest): Promise<any> {
    const response = await this.http.post(`/api/member/sendDocumentRejectEmail`, documentApprovalEmailRequest);
    return response.status;
  }

  async postSendDocumentApproveEmail(documentApproveEmailRequest: IDocumentApproveEmailRequest): Promise<any> {
    const response = await this.http.post(`/api/member/sendDocumentApproveEmail`, documentApproveEmailRequest);
    return response.status;
  }
}
import {getstore} from "../store/ssqstore";
import {setExistSsqAccountNotSnsStore} from "../store/reducers/rootReducer";
import {resources} from "language/LanguageResources";


let loginfos = [];

export function rmssqlog(logid, logfn) {
  loginfos = loginfos.filter((ele) => ele.logfn !== logfn);
}

export function addssqlog(logid, logfn) {
  loginfos.push({logid, logfn});
}

type Cmd = {
  help: string;
  cmd: string;
  docmd: (cmd: string, p1?, p2?, data?) => void;
}

let headercmd = null;

export function set_headercmd(cmd) {
  headercmd = cmd;
}

const cmd_headercmd: Cmd = {
  help: 'headercmd',
  cmd: 'headercmd',
  docmd: (cmd, p1?, p2?, data?) => {
    if (headercmd == null) {
      console.log("header cmd is null");
      return;
    }
    headercmd(cmd, p1, p2, data);
  }
};

const cmd_log: Cmd = {
  help: 'log',
  cmd: 'log',
  docmd: (cmd, p1?, p2?, data?) => {
    loginfos.forEach((ele) => {
      if (ele.logid === p1) {
        ele.logfn(cmd, p1, p2, data);
      }
    });

  }
};

const cmd_help: Cmd = {
  help: 'show help string',
  cmd: 'help',
  docmd: (cmd, p1?, p2?, data?) => {
    cmds.forEach(ele => {
      console.log('', ele.cmd, ' : ', ele.help);
      if (ele.cmd === 'log') {
        loginfos.forEach((ele) => {
          console.log('  ', ele.logid);
        });
      }
    });

  }
};
const cmd_store: Cmd = {
  help: `test store 
  root
     isExistSsqAccountNotSnsStore : true, false
  main
     showPublic : true, false 
`,
  cmd: 'store',
  docmd: (cmd, p1?, p2?, data?) => {
    const store = getstore();
    // store.dispatch({ type: ACTION_TYPES.MAIN.ROBOT_CONTROL, payload: 'CONTROL_CONNECTED' });
    // store.getState()
    console.log("sotre ", store);
    console.log("sotre.state ", store.getState());
    if (p1 === 'root') {
      if (p2 === 'isExistSsqAccountNotSnsStore') {
        store.dispatch(setExistSsqAccountNotSnsStore(data));
      }
    } else if (p1 === 'main') {

    }
  }
};


function get_res_ns_obj(res, ns) {
  let set = new Set();
  let en = res['en'][ns];
  let ko = res['ko'][ns];
  Object.keys(en).forEach(key => {
    set.add(key);
  });
  Object.keys(ko).forEach(key => {
    set.add(key);
  });
  let obj = {};
  Array.from(set).forEach((key: string) => {
    obj[key] = [ko[key], en[key]];
  });
  return obj;

}

const cmd_res: Cmd = {
  help: `resource parse 
 
`,
  cmd: 'res',
  docmd: (cmd, p1?, p2?, data?) => {
    console.log("do cmd ", cmd);
    console.log(resources);
    let ns = ['I18N_NAMESPACE_HEADER', 'I18N_NAMESPACE_FOOTER', 'I18N_NAMESPACE_MAIN_PAGE',
      'I18N_NAMESPACE_PRODUCT_PAGE',
      "I18N_NAMESPACE_SOLUTION_PAGE", "I18N_NAMESPACE_COMMUNITY_PAGE", "I18N_NAMESPACE_SERVICE_PAGE",
      "I18N_NAMESPACE_ADMIN_PAGE", "I18N_NAMESPACE_ALL_SEARCH_PAGE", "I18N_NAMESPACE_MY_PAGE"
    ];

    ns.forEach((ns) => {
      console.log("NS", ns);
      console.log(get_res_ns_obj(resources, ns));
    });


  }
};

const cmds = [cmd_help, cmd_store, cmd_headercmd, cmd_log, cmd_res];

function cmd(cmd, p1?, p2?, data?) {
  cmds.forEach(ele => {
    if (ele.cmd == cmd) {
      ele.docmd(cmd, p1, p2, data);
    }
  });

}

export const cmder = {
  cmd: cmd
};